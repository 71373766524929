<div class="main-sidebar pull-left sidebar-collapsed-mobile" [class.sidebar-collapsed]="sidebarCollapsed" [class.sidebar-hidden]="sidebarHidden || uri.includes('my-downloads')" data-html2canvas-ignore>
  
  <!-- Sidebar Toggle Button -->
  <div class="sidebar-toggle hidden-xs" [class.sidebar-collapsed]="sidebarCollapsed" (click)="toggleSidebar($event)">
    <span *ngIf="!isEmbedded" class="sidebar-toggle-arrow"></span>
    <span *ngIf="isEmbedded && sidebarCollapsed" class="glyphicon glyphicon-menu-hamburger white"></span>
    <span *ngIf="isEmbedded && !sidebarCollapsed" class="glyphicon glyphicon-remove white"></span>
  </div>

  <!-- Sidebar content -->
  <div class="sidebar" [class.sidebar-collapsed]="sidebarCollapsed" [class.top-padding]="hasClientDropdown || showAdminNav || uri.includes('data-warehouse')">
    <!-- admin nav -->
    <nav class="client-admin-nav" *ngIf="role === 'superadmin' && uri.includes('admin')">
      <insighter-admin-sidebar-content></insighter-admin-sidebar-content>
    </nav>

    <!-- client admin nav -->
    <nav class="client-admin-nav" *ngIf="shouldShowClientAdmin() && uri.includes('settings')">
      <insighter-settings-sidebar-content [clients]="clients"></insighter-settings-sidebar-content>
    </nav>

    <!-- dashboard nav -->
    <nav *ngIf="uri.includes('dashboard') || uri === '/'">
      <insighter-dashboards-sidebar-content [clients]="clients"></insighter-dashboards-sidebar-content>
    </nav>

    <!-- media automation admin nav -->
    <nav class="client-admin-nav" *ngIf="shouldShowMediaAutomation() && uri.includes('media-automation')">
      <insighter-media-automation-sidebar-content></insighter-media-automation-sidebar-content>
    </nav>

    <nav class="client-admin-nav" *ngIf="shouldShowMediaAutomation() && uri.includes('media-client')">
      <insighter-media-automation-client-sidebar-content [clients]="clients"></insighter-media-automation-client-sidebar-content>
    </nav>

    <!-- data-warehouse -->
    <nav class="client-admin-nav" *ngIf="shouldShowDataWarehouse() && uri.includes('data-warehouse')">
      <insighter-data-warehouse-sidebar-content></insighter-data-warehouse-sidebar-content>
    </nav>

    <!-- data-warehouse -->
    <nav class="client-admin-nav" *ngIf="showClientDropdown() && uri.includes('custom-dash')">
      <insighter-custom-dashboards-sidebar-content [clients]="clients"></insighter-custom-dashboards-sidebar-content>
    </nav>
  </div>

  <div id="dragbar" [class.sidebar-collapsed]="sidebarCollapsed"></div>
</div>
