/**
 * Logger Service
 *
 * @description :: Provides console.log capabilities with the ability to
 *              ensure no logging is performed if the environment is running
 *               in production. Wraps the console module's methods & checks
 *              the env before returning the reference otherwise it returns
 *               a noop function
 *
 *              Example usage:
 *                import { $log } from './services/logger.service';
 *
 *                $log.debug('hello from the new logger');
 */

import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';


@Injectable()
export class $log {

  private static enabled: Boolean = true;
  static noop = () => {
  };

  static get debug() {
    if (!environment.production)  {
      return console.debug.bind(console);
    }

    return this.noop;
  }

  static get error() {
    if (!environment.production) {
      return console.error.bind(console);
    }

    return this.noop;
  }

  static get log() {
    if (!environment.production) {
      return console.log.bind(console);
    }

    return this.noop;
  }

  static get info() {
    if (!environment.production) {
      return console.info.bind(console);
    }

    return this.noop;
  }

  static get warn() {
    if (!environment.production) {
      return console.warn.bind(console);
    }

    return this.noop;
  }

}
