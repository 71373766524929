/**
 * Singular Pipe
 *
 * @description :: This is a simple pipe that will attempt to take a plural word
 *                 and convert it to the singular form. The logic is simple and only
 *                 works by removing the "s" - more complex logic can be added later.
 *
 * @example :: in your template simply use the pipe like so: {{ title | singular }}
 */
import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
  name: 'singular'
})
export class SingularPipe implements PipeTransform {

  transform(value: string, args?: any): string {
    if (value.charAt(value.length - 1) !== 's') {
      // string is already singular - return it
      return value;
    }

    if (!value || value.charAt(value.length - 1) === 's') {
      return value.substring(0, value.length - 1);
    }

    return value;
  }

}
