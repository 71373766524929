
import {distinctUntilChanged, debounceTime, takeWhile} from 'rxjs/operators';
import { Component, OnInit, OnDestroy, ViewChild, Input } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "../../services/auth.service";
import { ClientsService } from "../../services/client.service";
import { ModalDirective } from "ngx-bootstrap/modal";
import { EmitterService } from "../../services/emitter.service";
import { AppService } from "../../services/app.service";
import { MyDownloadsService } from "../../services/mydownloads.service";

declare let $: any;

@Component({
  selector: "insighter-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"]
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Input() clients: any;
  clientId: number;

  @ViewChild("userProfile") public userProfile: ModalDirective;
  isEmbedded: boolean;
  public isAdmin: boolean;
  public role: string;
  public clientLinks: any[];
  public isMobile: boolean;

  public hasMediaAutomationPrivileges: boolean;
  public hasDataWarehousePrivileges: boolean;
  public hasClientAdminPrivileges: boolean;

  public services: Array<string> = [];

  // Utilized to manage observable subscriptions
  private isAlive: boolean = true;

  public dynamicHeaderLeft: string = null;
  public dynamicHeaderLeftWidth: string = null;
  public dynamicHeaderLeftHeight: string = null;

  public dynamicHeaderRight: string = null;
  public dynamicHeaderRightWidth: string = null;
  public dynamicHeaderRightHeight: string = null;

  public dynamicHeaderColor: string = null;

  public navigationBarBg: string = null;
  public navigationBarFont: string = null;
  public navigationBarFontSize: string = null;
  public navigationBarTextColor: string = null;

  public mediaAutoLink: string = '/ma/media-client/playbooks/create';
  public mediaAutoLinkDisplay: string = 'Media Automation Client';
  public mediaAutoPrivileges: Array<string> = [];

  public clientAdminLink: string = '/settings';
  public clientAdminDisplay: string = 'Settings';
  public clientAdminPrivileges: Array<string> = [];

  public dataWarehouseLink: string = '/data-warehouse/client-status';
  public dataWarehousePrivileges: Array<string> = [];

  public isDashboardEditMode = false;

  public dataWarehouseLabel = 'Data Warehouse';
  public customDashboardsLabel = 'Custom Dashboards';
  public clientAdminViewLabel = 'Client Admin View';

  constructor(
    private MyDownloadsService: MyDownloadsService,
    private ClientsService: ClientsService,
    private AuthService: AuthService,
    private AppService: AppService,
    private router: Router
  ) {
    this.AppService.isMobile.pipe(
      takeWhile(() => this.isAlive),
      debounceTime(100),
      distinctUntilChanged())
      .subscribe(state => {
        this.isMobile = state;

        if (this.isMobile) {
          this.dataWarehouseLabel = 'DW';
          this.customDashboardsLabel = 'C Dash';
          this.clientAdminViewLabel = '';
        }
      });
  }

  ngOnInit() {
    this.clientId = this.ClientsService.getClientId()
    this.setDynamicClientDisplayAttributes();
    
    // Check to see if the app is embedded. If it is, hide the header.
    this.isEmbedded = this.AppService.appIsEmbedded;

    this.role = this.AuthService.getRole();
    this.isAdmin = this.AuthService.isAdmin();
    this.hasMediaAutomationPrivileges = this.AuthService.hasMediaAutomationPrivileges();
    this.mediaAutoPrivileges = this.AuthService.getMediaAutoPrivileges();
    this.hasDataWarehousePrivileges = this.AuthService.hasDataWarehousePrivileges();
    this.dataWarehousePrivileges = this.AuthService.getDataWarehousePrivileges();
    this.hasClientAdminPrivileges = this.AuthService.hasClientAdminPrivileges();
    this.clientAdminPrivileges = this.AuthService.getClientAdminPrivileges();

    this.checkAdmin();

    EmitterService.get("clients-refreshed").pipe(
      takeWhile(() => this.isAlive))
      .subscribe(clients => {
        this.clients = clients;
        this.setDynamicClientDisplayAttributes();
      });

    EmitterService.get("client.changed").pipe(
      takeWhile(() => this.isAlive))
      .subscribe(clientId => {
        this.clientId = clientId;
        this.setDynamicClientDisplayAttributes();
      });

    // Update client links when the current client is changed within the portal
    EmitterService.get("client.links.changed").pipe(
      takeWhile(() => this.isAlive))
      .subscribe(links => (this.clientLinks = links || null));

    EmitterService.get("client.services.changed").pipe(
      takeWhile(() => this.isAlive))
      .subscribe(services => {
        if (services) {
          this.services = services.map(i => {
            return i.name;
          });
        } else {
          this.services = [];
        }
      });

      EmitterService.get("navigate.clientAdmin").pipe(
        takeWhile(() => this.isAlive))
        .subscribe(() => {
          this.navigateToDefaultClientAdminLink();
        });
    
  }

  ngOnDestroy() {
    this.isAlive = false;
  }


  getClient() {
    if (this.clients && this.clients.length) {
      if (this.clientId) {
        const currentClient = this.clients.find(client => {
          return client.id === this.clientId
        })
        if (currentClient) {
          return currentClient
        }
      } 
      return this.clients[0];
    }
    return null;
  }


  setDynamicClientDisplayAttributes() {
    const client = this.getClient();

    if (client && client.displayAttributes) {

        if (client.displayAttributes.logos) {
          if (client.displayAttributes.logos.navTopLeft) {
            this.dynamicHeaderLeft = client.displayAttributes.logos.navTopLeft;
          } else {
            this.dynamicHeaderLeft = null;
          }

          if (client.displayAttributes.logos.navTopLeftWidth) {            
            this.dynamicHeaderLeftWidth = client.displayAttributes.logos.navTopLeftWidth + 'px';
          } else {
            this.dynamicHeaderLeftWidth = null;
          }

          if (client.displayAttributes.logos.navTopLeftHeight) {            
            this.dynamicHeaderLeftHeight = client.displayAttributes.logos.navTopLeftHeight + 'px';
          } else {
            this.dynamicHeaderLeftHeight = null;
          }

          if (client.displayAttributes.logos.navTopRight) {
            this.dynamicHeaderRight = client.displayAttributes.logos.navTopRight;
          } else {
            this.dynamicHeaderRight = null;
          }

          if (client.displayAttributes.logos.navTopRightWidth) {            
            this.dynamicHeaderRightWidth = client.displayAttributes.logos.navTopRightWidth + 'px';
          } else {
            this.dynamicHeaderRightWidth = null;
          }

          if (client.displayAttributes.logos.navTopRightHeight) {            
            this.dynamicHeaderRightHeight = client.displayAttributes.logos.navTopRightHeight + 'px';
          } else {
            this.dynamicHeaderRightHeight = null;
          }
        }

        if (client.displayAttributes.navBg) {
          this.dynamicHeaderColor = client.displayAttributes.navBg
        } else {
          this.dynamicHeaderColor = null;
        }

        if (client.displayAttributes.pageZoom) {
          
          if (navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > -1) {
            /* Microsoft Internet Explorer detected in. */
            $('.main-sidebar').css({
              zoom: client.displayAttributes.pageZoom + '%',
              height: 100 + (100 - parseInt(client.displayAttributes.pageZoom, 10)) + '%'
            });

            $('.body').css({
              zoom: client.displayAttributes.pageZoom + '%'
            });
          } else {
            $('body').css({
              zoom: client.displayAttributes.pageZoom + '%'
            })

          }

          
        } else {
          

          if (navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > -1) {
            /* Microsoft Internet Explorer detected in. */
            $('.main-sidebar').css({
              zoom: '',
              height: 'calc(100% - 55px)'
            });

            $('.body').css({
              zoom: ''
            });
          } else {
            $('body').css({
              zoom: ''
            })

          }

        }

        if (client.displayAttributes.pageTitle) {
          document.title = client.displayAttributes.pageTitle;
        } else {
          document.title = 'Insighter - Ansira Edge℠';
        }

        if (client.displayAttributes.navigationBarBg) {
          this.navigationBarBg = client.displayAttributes.navigationBarBg
        } else {
          this.navigationBarBg = null;
        }

        if (client.displayAttributes.navigationBarFont) {
          this.navigationBarFont = client.displayAttributes.navigationBarFont
        } else {
          this.navigationBarFont = null;
        }

        if (client.displayAttributes.navigationBarFontSize) {
          this.navigationBarFontSize = client.displayAttributes.navigationBarFontSize
        } else {
          this.navigationBarFontSize = null;
        }

        if (client.displayAttributes.navigationBarTextColor) {
          this.navigationBarTextColor = client.displayAttributes.navigationBarTextColor
        } else {
          this.navigationBarTextColor = null;
        }

        let extraStyle = '';
        let extraHtml = '';

        if (client.displayAttributes.sideBarTextColor) {
          extraStyle += `
              .client-panel {
                color: ${client.displayAttributes.sideBarTextColor} !important;
              }

              .client-panel select {
                background:
                  linear-gradient(45deg, transparent 50%, ${client.displayAttributes.sideBarTextColor} 50%),
                  linear-gradient(135deg, ${client.displayAttributes.sideBarTextColor} 50%, transparent 50%),
                  linear-gradient(to right, transparent, transparent) !important;
                background-position:
                  calc(100% - 21px) calc(1em + 2px),
                  calc(100% - 16px) calc(1em + 2px),
                  100% 0 !important;
                background-size:
                  5px 5px,
                  5px 5px,
                  2.5em 2.5em !important;
                background-repeat: no-repeat !important;
              }

              .subfolder .dashboards li a {
                color: ${client.displayAttributes.sideBarTextColor} !important;
              }

              .subfolder-title {
                color: ${client.displayAttributes.sideBarTextColor} !important;
              }

              .admin-nav .nav-section .title {
                color: ${client.displayAttributes.sideBarTextColor} !important;
              }

              .admin-nav .nav-section .sub li a {
                color: ${client.displayAttributes.sideBarTextColor} !important;
              }

              .sidebar-toggle .sidebar-toggle-arrow:before {
                border-color: ${client.displayAttributes.sideBarTextColor} transparent transparent ${client.displayAttributes.sideBarTextColor} !important;
              }
          `;
        }

        if (client.displayAttributes.sideBarBgSelected) {
          extraStyle += `
              .subfolder .dashboards li a:hover, .subfolder .dashboards li a.active-dashboard {
                background-color: ${client.displayAttributes.sideBarBgSelected} !important
              }
          `;
        }

        if (client.displayAttributes.sideBarTextSelected) {
          extraStyle += `
              .subfolder .dashboards li a:hover, .subfolder .dashboards li a.active-dashboard {
                color: ${client.displayAttributes.sideBarTextSelected} !important
              }
          `;
        
        }

        if (client.displayAttributes.sideBarBg) {
          const borderColor = client.displayAttributes.sideBarTextColor ? client.displayAttributes.sideBarTextColor : "black";

          extraStyle += `
            .sidebar {
              background-color: ${client.displayAttributes.sideBarBg} !important;
            }

            .sidebar-toggle {
              background-color: ${client.displayAttributes.sideBarBg} !important;
              border-left-color: ${client.displayAttributes.sideBarBg}  !important;
              border-right-color: ${borderColor} !important;
            }

            .client-panel {
              background-color: ${client.displayAttributes.sideBarBg} !important;
            }

            .subfolder-title {
              background-color: ${client.displayAttributes.sideBarBg} !important;
              border-color: ${borderColor} !important;
            }

            .admin-nav .nav-section {
              border-color: ${borderColor} !important;
            }
          `;
          
        }

        if (client.displayAttributes.sideBarFont) {
          extraStyle += `
            .sidebar {
              font-family: ${client.displayAttributes.sideBarFont} !important;
            }
        `;
        }

        if (client.displayAttributes.sideBarFontSize) {

          extraStyle += `
              .client-panel {
                font-size: ${client.displayAttributes.sideBarFontSize} !important
              }

              .subfolder .dashboards li a {
                font-size: ${client.displayAttributes.sideBarFontSize} !important
              }

              .subfolder-title {
                font-size: ${client.displayAttributes.sideBarFontSize} !important;
              }

              .admin-nav .nav-section .title {
                font-size: ${client.displayAttributes.sideBarFontSize} !important;
              }

              .admin-nav .nav-section .sub li a {
                font-size: ${client.displayAttributes.sideBarFontSize} !important;
              }
          `;
        }

        if (client.displayAttributes.customFonts) {

          client.displayAttributes.customFonts.forEach(e => {
            extraHtml += `<link href="${e}" rel="stylesheet" type="text/css" />`;
            extraStyle += `@import url(${e});`;
          })

        }

        if (extraStyle) {
          $('#extraStyles').html(extraHtml + `<style>${extraStyle}</style>`);
        } else {
          $('#extraStyles').html('');
        }
        
    } else {
      this.dynamicHeaderLeft = null;
      this.dynamicHeaderRight = null;
      this.dynamicHeaderColor = null;
      this.navigationBarBg = null;
      this.navigationBarFont = null;
      this.navigationBarFontSize = null;
      this.navigationBarTextColor = null;

      $('body').css({
        zoom: ''
      })

      $('#extraStyles').html('');
    }


  }

  /**
   * Check Admin - if user is CP Admin or Super Admin this
   *  will apply the `is-admin` styling to the body
   *
   * @returns {void}
   */
  checkAdmin() {
    let body = document.getElementById("body");

    if (this.isAdmin) {
      body.classList.add("is-admin");
    }
  }

  /**
   * toggles the sidebar on mobile
   *
   * @returns {void}
   */
  toggleSidebar() {
    EmitterService.get("sidebar.toggle").emit();
    return false;
  }

  /**
   * Toggles the admin view when toggle switch is clicked
   *  by routing the appropriate route based on toggle state
   *
   * @param {boolean} switchIsOn
   * @returns {void}
   */
  toggleAdminView(switchIsOn) {
    if (switchIsOn) {
      this.router.navigate(["/settings"]);
    } else {
      this.router.navigate(["/admin"]);
    }
  }

  /**
   * Returns true if the user is on an active admin route
   *
   * @returns {boolean}
   */
  public isAdminRoute() {
    return (
      this.router.url.includes("/admin") ||
      this.router.url.includes("/settings")
    );
  }

  public isMediaAutomationRoute() {
    return (
      this.router.url.includes("/media-automation") ||
      this.router.url.includes("/media-client")
    );
  }

  public shouldShowEditDashboardMode() {
    return (
      this.role === 'superAdmin' && this.router.url.includes("/dashboards")
    );
  }

  toggleEditMode(switchIsOn) {
    this.isDashboardEditMode = switchIsOn;
    EmitterService.get("dashboard.isEditMode").emit(this.isDashboardEditMode);
  }

  toggleMediaAutomationAdminView(switchIsOn) {
    if (switchIsOn) {
      this.router.navigate(["/ma/media-client/playbooks/create"]);
    } else {
      this.router.navigate(["/ma/media-automation/playbooks/create"]);
    }
  }

  public shouldShowDataWarehouse() {

    if (this.dataWarehousePrivileges.includes('data_warehouse_all')) {
      this.dataWarehouseLink = '/data-warehouse/client-status';
    } else if (this.dataWarehousePrivileges.includes('data_warehouse_crud')) {
      this.dataWarehouseLink = '/data-warehouse/tasks/crud';
    }

    return this.hasDataWarehousePrivileges;
  }

  public shouldShowMediaAutomation() {

    if (this.router.url.includes("settings") && this.hasMediaAutomationPrivileges) {
      this.mediaAutoLinkDisplay = 'Media Automation Client';

      if (this.mediaAutoPrivileges.includes('media_automation_all') || this.mediaAutoPrivileges.includes('media_automation_playbooks')) {
        this.mediaAutoLink = '/ma/media-automation/playbooks/create';
      } else if (this.mediaAutoPrivileges.includes('media_automation_gateway')) {
        this.mediaAutoLink = '/ma/media-client/gateway/create';
      } else {
        this.mediaAutoLink = '/ma/media-automation/playbooks/create';
      }

    } else {
      
      this.mediaAutoLinkDisplay = 'Media Automation';

      if (this.mediaAutoPrivileges.includes('media_automation_all') || this.mediaAutoPrivileges.includes('media_automation_playbooks')) {
        this.mediaAutoLink = '/ma/media-automation/playbooks/create';
      } else if (this.mediaAutoPrivileges.includes('media_automation_bulk_processing')) {
        this.mediaAutoLink = '/ma/media-automation/bulkupload/create';
      } else if (this.mediaAutoPrivileges.includes('media_automation_jobs_log')) {
        this.mediaAutoLink = '/ma/media-automation/jobs/audience-update';
      } else if (this.mediaAutoPrivileges.includes('media_automation_gateway')) {
        this.mediaAutoLink = '/ma/media-automation/gateway/create';
      } else {
        this.mediaAutoLink = '/ma/media-automation/playbooks/create';
      }
    }

    return this.hasMediaAutomationPrivileges;
  }

  public shouldShowClientAdmin() {

    if (this.role === 'superAdmin' && !this.router.url.includes('media-client')) {
      this.clientAdminLink = '/admin';
      this.clientAdminDisplay = 'Admin';
    } else if (this.role === 'admin' || (this.role === 'superAdmin' && this.router.url.includes('media-client')) || this.hasClientAdminPrivileges) {

      if (this.role === 'admin' || this.role === 'superAdmin' || this.clientAdminPrivileges.includes('client_admin_all') || this.clientAdminPrivileges.includes('client_admin_permissions')) {
        this.clientAdminLink = '/settings';
      } else if (this.clientAdminPrivileges.includes('client_admin_partitions')) {
        this.clientAdminLink = '/settings/metadata';
      } else if (this.clientAdminPrivileges.includes('client_admin_budgets')) {
        this.clientAdminLink = '/settings/marketing-calendar-promotion';
      } else if (this.clientAdminPrivileges.includes('client_admin_media_automation')) {
        this.clientAdminLink = '/settings/media-auto/properties';
      } else {
        this.clientAdminLink = '/settings';
      }
      this.clientAdminDisplay = 'Settings';
    } 

    return this.hasClientAdminPrivileges;
  }

  public navigateToDefaultClientAdminLink() {

    let defaultLink = '';
    if (this.clientAdminLink === '/admin') {
      defaultLink = '/settings';
    } else {
      defaultLink = this.clientAdminLink;
    }

    this.router.navigate([defaultLink]);
  }

  public shouldShowCustomDashboards() {

    return (
      this.role === 'superAdmin' && this.hasService("Custom Dashboards")
    );
  }

  public shouldShowMyDownloads() {
    const clientHasPowerBIDashboards = this.hasService("Power BI - My Downloads");
    const clientHasInsighterMyDownloads = this.hasService("Insighter - My Downloads");
    const myDownloadsEnabled = clientHasPowerBIDashboards || clientHasInsighterMyDownloads;

    this.AppService.setMyDownloadsEnabled(myDownloadsEnabled);
    this.AppService.setInsighterMyDownloadsEnabled(clientHasInsighterMyDownloads);

    return myDownloadsEnabled;
  }

  hasService(name) {
    let hasService = false;

    let client = this.getClient();

    if (client && client.services) {
      let serviceNames = client.services.map(i => i.name);
      if (serviceNames.indexOf(name) !== -1) {
        hasService = true;
      }
    }

    return hasService;
  }

  hasReadyForDownload() {
    return this.MyDownloadsService.getReadyForDownload().length > 0;
  }

}
