/**
 * Task Service
 *
 * @description :: Communicates with API tasks endpoints
 */


import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { $log } from './logger.service';

@Injectable()
export class TaskService {

  private TASK_URL = '/tasks';

  constructor(
    private ApiService: ApiService,
  ) { }

  /**
   * Sync Sisense records via the API
   *
   * @returns {Promise<boolean>} returns promise from the http call
   */
  public syncSisense(): Observable<any> {
    return this.ApiService
      .get(`${this.TASK_URL}/sync-sisense`).pipe(
      map(() => {
          $log.info('Sync process initiated.');
        },
        error => {
          $log.error(error);
        }
      ));
  }

  /**
   * Sync Sisense records via the API
   *
   * @returns {Promise<boolean>} returns promise from the http call
   */
  public flushCache(): Observable<any> {
    console.log('task: flushCache');
    return this.ApiService
      .get(`${this.TASK_URL}/flush-cache`).pipe(
      map(() => {
          $log.info('Cached flushed.');
        },
        error => {
          $log.error(error);
        }
      ));
  }

  public syncDistributorClients(clientId?: number): Observable<any> {
    let localStorageUser = localStorage.getItem('currentUser');
    let localEmail = '';

    if (localStorageUser) {
      try {
        let currentUser = JSON.parse(localStorageUser);
        localEmail = currentUser.user;
      } catch (e) {
        console.error(e);
      }
    }

    let url = `${this.TASK_URL}/sync-distributor-clients/${localEmail}`;

    if (clientId) {
      url += `/${clientId}`;
    }

    return this.ApiService
      .get(url).pipe(
      map(() => {
          $log.info('Sync process initiated.');
        },
        error => {
          $log.error(error);
        }
      ));
  }

  public syncRedshiftLocations(): Observable<any> {
    let localStorageUser = localStorage.getItem('currentUser');
    let localEmail = '';

    if (localStorageUser) {
      try {
        let currentUser = JSON.parse(localStorageUser);
        localEmail = currentUser.user;
      } catch (e) {
        console.error(e);
      }
    }

    return this.ApiService
      .get(`${this.TASK_URL}/sync-redshift-locations/${localEmail}`).pipe(
      map(() => {
          $log.info('Sync process initiated.');
        },
        error => {
          $log.error(error);
        }
      ));
  }

  public checkScheduledJobs(): Observable<any> {
    let localStorageUser = localStorage.getItem('currentUser');
    let localEmail = '';

    if (localStorageUser) {
      try {
        let currentUser = JSON.parse(localStorageUser);
        localEmail = currentUser.user;
      } catch (e) {
        console.error(e);
      }
    }

    let url = `${this.TASK_URL}/check-scheduled-jobs/${localEmail}`;

    return this.ApiService
      .get(url).pipe(
      map(() => {
          $log.info('Sync process initiated.');
        },
        error => {
          $log.error(error);
        }
      ));
  }

}
