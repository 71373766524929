/**
 * The Client model
 *
 * @description :: represents the client model & encapsulates any methods used
 *              to interact with the client object.
 */

export class Client {
    // checkbox toggle state
    toggle?: boolean;

    // radio button array for possible account types
    types: Array<string> = [
      'single',
      'franchise',
      'distributor'
    ];

    // computed properties
    folderName: string;
    folderType: string;

    distributorClient: any;

    hasCustomDistributorName?: boolean;
    /**
     * Client model object constructor
     *
     * @param {number} id   - client id/primary key
     * @param {string} name - the client's name
     * @param {any}    folder - client folder object
     * @param {Array<any>} folders - array of folders?
     * @param {number} defaultAccount - Account ID
     * @param {any} profileDefinition - def object
     * @param {Array<any>} links - list of links
     * @param {Array<any>} logo - client logo
     * @param {Array<any>} clientGroups - group client belongs too
     * @param {string} type - single or franchise account type
     * @param {string} s3Folder - bucket or folder name on s3
     */
    constructor(
      public id: number, public name: string,
      public folder: any, public folders: Array<any>,
      public defaultAccount: number,
      public profileDefinition?: any,
      public links?: Array<any>, public logo?: string,
      public clientGroups?: Array<any>, public type?: string,
      public s3Folder?: string,
      public distributorClientId?: string,
      public services?: Array<any>,
      public displayAttributes?: any,
      public subdomainSlug?: string,
      public hideDashboards?: boolean
    ) {
      // default to unchecked
      this.toggle = false;

      // computed props
      this.folderName = this.getFolderName();
      this.folderType = this.getFolderType();

      if (!this.services) {
        this.services = []
      }

      if (!this.displayAttributes) {
        this.displayAttributes = {
          navBg: null,
          dashFont: null,
          dashFontSize: null,
          dashTitleAlign: null,
          logos: {
            dashTopLeft: null,
            dashTopRight: null,
            navTopLeft: null,
            navTopRight: null
          },
          lockTitleAndFilters: null,
          filterFont: null,
          filterFontSize: null,
          customFonts: []
        }
      }
    }

    /**
     * getter for properties on the client nested
     *  object `folder` - gets the folder's name
     *
     * @return {string} returns the folder.name string
     */
    public getFolderName(): string {
      if (this.folder) {
        return this.folder.name;
      } else {
        return '';
      }
    }

    /**
     * getter for properties on the client nested
     *  object `folder` - gets the folder's type
     *
     * @return {string} returns the folder.instanceType string
     */
    public getFolderType(): string {
      if (this.folder) {
        return this.folder.instanceType;
      } else {
        return '';
      }
    }

  /**
   * Attempts to determine the highest order value used for
   *  existing edit forms by calculating the length, otherwise
   * if the form is new then the order starts at zero
   *
   * @param   {boolean} isNewForm - indicates whether the form is new or has data to edit
   * @returns {number} returns the highest number value found for ordering
   */
  public getOrdering(isNewForm): number {
    if (isNewForm) {
      return 0;
    } else {
      return this.profileDefinition.properties.length;
    }
  }
}
