<!-- begin confirmation modal -->
<div *ngIf="isVisible" [config]="{ show: true }" (onHidden)="onHidden()" bsModal
     #autoShownModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left">{{title}}</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="hideModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <ng-content></ng-content>
    </div>
  </div>
</div>
<!-- end confirmation modal -->
