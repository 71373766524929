/**
 * @description :: Angular 2 multiselect-dropdown component to provide dropdown with checkboxes and search filtering.
 *              Originally meant to be an extension of angular-2-multiselect-dropdown. This adds the ability to have
 *              apply & cancel buttons in the settings configuration as well as events to handle interaction with
 *              those elements; Adds min-height option to settings and a few various other bits like disabled state for
 *              apply button & some additional styles;
 */
import { NgModule, Component, OnInit, DoCheck, Input, Output, Pipe, forwardRef
        , EventEmitter, ElementRef, IterableDiffers, HostListener, PipeTransform } from '@angular/core';

import { CommonModule } from '@angular/common';
import { FormsModule, NG_VALUE_ACCESSOR, ControlValueAccessor, Validator, AbstractControl } from '@angular/forms';

const MULTISELECT_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => MultiselectDropdown),
  multi: true
};

export interface IMultiSelectOption {
  id: any;
  name: string;
  code: any;
  isLabel?: boolean;
  parentId?: any;
  params?: any;
}

export interface IMultiSelectSettings {
  pullRight?: boolean;
  enableSearch?: boolean;
  checkedStyle?: 'checkboxes' | 'glyphicon' | 'fontawesome';
  buttonClasses?: string;
  itemClasses?: string;
  selectionLimit?: number;
  closeOnSelect?: boolean;
  autoUnselect?: boolean;
  showCheckAll?: boolean;
  showUncheckAll?: boolean;
  dynamicTitleMaxItems?: number;
  maxHeight?: string;
  minHeight?: string;
  displayAllSelectedText?: boolean;
  showApplyCancelButtons?: boolean;
  showSort?: boolean;
  sortAsc?: boolean;
}

export interface IMultiSelectTexts {
  checkAll?: string;
  uncheckAll?: string;
  checked?: string;
  checkedPlural?: string;
  searchPlaceholder?: string;
  defaultTitle?: string;
  allSelected?: string;
  uncheckedTitle?: string;
  sortAscLabel?: string;
  sortDescLabel?: string;
}

@Pipe({
  name: 'searchFilter'
})
export class MultiSelectSearchFilter implements PipeTransform {
  transform(options: Array<IMultiSelectOption>, args: string): Array<IMultiSelectOption> {
    const matchPredicate = (option: IMultiSelectOption) => option.name.toLowerCase().indexOf((args || '').toLowerCase()) > -1,
      getChildren = (option: IMultiSelectOption) => options.filter(child => child.parentId === option.id),
      getParent = (option: IMultiSelectOption) => options.find(parent => option.parentId === parent.id);
    return options.filter((option: IMultiSelectOption) => {
      return matchPredicate(option) ||
        (typeof (option.parentId) === 'undefined' && getChildren(option).some(matchPredicate)) ||
        (typeof (option.parentId) !== 'undefined' && matchPredicate(getParent(option)));
    });
  }
}

@Component({
  selector: 'insighter-multiselect',
  providers: [MULTISELECT_VALUE_ACCESSOR],
  styleUrls: ['./multiselect.component.scss'],
  templateUrl: './multiselect.component.html',
})
export class MultiselectDropdown implements OnInit, DoCheck, ControlValueAccessor, Validator {
  model: Array<number>;
  appliedModel: Array<number>;  // keeps tracks of items that were applied
  title: string;
  differ: any;
  numSelected: number = 0;
  isVisible: boolean = false;
  searchFilterText: string = '';

  shouldSearchTextUncheckAll: boolean = true;

  defaultSettings: IMultiSelectSettings = {
    pullRight: false,
    enableSearch: false,
    checkedStyle: 'checkboxes',
    buttonClasses: 'btn btn-default btn-secondary',
    selectionLimit: 0,
    closeOnSelect: false,
    autoUnselect: false,
    showCheckAll: false,
    showUncheckAll: false,
    dynamicTitleMaxItems: 3,
    maxHeight: '300px',
    minHeight: '300px',
    showApplyCancelButtons: false
  };
  defaultTexts: IMultiSelectTexts = {
    checkAll: 'Check all',
    uncheckAll: 'Uncheck all',
    checked: 'checked',
    checkedPlural: 'checked',
    searchPlaceholder: 'Search...',
    defaultTitle: 'Select',
    allSelected: 'All selected',
  };

  @Input() font?: string;
  @Input() fontSize?: string;

  @Input() options: Array<IMultiSelectOption>;
  @Input() settings: IMultiSelectSettings;
  @Input() texts: IMultiSelectTexts;
  @Input() disabled: boolean = false;
  @Input() applyIsDisabled: boolean = false;

  // special events (indicates visibility of dropdown, & apply/cancel click state
  @Output() dropdownStatus: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onApply: EventEmitter<any> = new EventEmitter();
  @Output() onCancel: EventEmitter<any> = new EventEmitter();

  // original events
  @Output() selectionLimitReached = new EventEmitter();
  @Output() dropdownClosed = new EventEmitter();
  @Output() onAdded = new EventEmitter();
  @Output() onRemoved = new EventEmitter();

  // listens for clicks on/off to know when to toggle dropdown visibility
  @HostListener('document: click', ['$event.target'])
  onClick(target: HTMLElement) {
    if (this.isVisible) {
      let parentFound = false;

      while (target !== null && !parentFound) {
        if (target === this.element.nativeElement) {
          parentFound = true;
        }
        // assign parent element
        target = target.parentElement;
      }

      if (!parentFound) {
        this.toggleDropdown(true);
      }
    }
  }

  constructor(private element: ElementRef,
              private differs: IterableDiffers) {
    this.differ = differs.find([]).create(null);
  }

  getItemStyle(option: IMultiSelectOption): any {
    if (!option.isLabel) {
      return {'cursor': 'pointer'};
    }
  }

  ngOnInit() {
    // extend defaultSettings object with updated settings values
    this.settings = Object.assign(this.defaultSettings, this.settings);
    this.texts = Object.assign(this.defaultTexts, this.texts);
    this.title = this.texts.defaultTitle || '';
  }

  // these objects accept functions as values
  // so initialize them as no-ops
  onModelChange: Function = (_: any) => {
    // no-op
  };
  onModelTouched: Function = () => {
    // no-op
  };

  writeValue(value: any): void {
    if (value !== undefined) {
      this.model = value;

      // If `model` has values, but the `appliedModel` has none, we initialize it with the values from `model`
      if (!this.appliedModel && this.model && this.model.length > 0) {
        this.appliedModel = Object.assign([], this.model);
      }
    }
  }

  registerOnChange(fn: Function): void {
    this.onModelChange = fn;
  }

  registerOnTouched(fn: Function): void {
    this.onModelTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  ngDoCheck() {
    const changes = this.differ.diff(this.model);

    if (changes) {
      this.updateNumSelected();
      this.updateTitle();
    }
  }

  validate(c: AbstractControl): { [key: string]: any; } {
    return (this.model && this.model.length) ? null : {
      required: {
        valid: false,
      },
    };
  }

  registerOnValidatorChange(fn: () => void): void {
    throw new Error('Method not implemented.');
  }

  clearSearch(event: Event) {
    event.stopPropagation();
    this.searchFilterText = '';
    this.shouldSearchTextUncheckAll = true;
  }

  /**
   * Toggles the visibility state of the drop-down
   *
   * @param  {boolean} revertChanges - Indicate if unsaved changes should be saved
   * @returns {void}
   */
  toggleDropdown(revertChanges = true) {
    this.isVisible = !this.isVisible;
    if (!this.isVisible) {
      this.syncState(revertChanges);

      // emit even indicated dropdown is closed
      this.dropdownStatus.emit(false);

      // deprecated but leaving in for now as unsure what other
      // functionality may depend on it
      this.dropdownClosed.emit();
    } else {
      // emit event indicating dropdown is visible
      this.shouldSearchTextUncheckAll = true;
      this.dropdownStatus.emit(true);
    }
  }

  isSelected(option: IMultiSelectOption): boolean {
    return this.model && this.model.indexOf(option.id) > -1;
  }

  setSelected(event: Event, option: IMultiSelectOption) {
    if (!this.model) {
      this.model = [];
    }

    if (this.searchFilterText && this.shouldSearchTextUncheckAll) {
      this.shouldSearchTextUncheckAll = false;
      this.uncheckAll();
    }

    const index = this.model.indexOf(option.id);
    if (index > -1) {
      this.model.splice(index, 1);
      this.onRemoved.emit(option.id);
    } else {
      if (this.settings.selectionLimit === 0 || (this.settings.selectionLimit && this.model.length < this.settings.selectionLimit)) {
        this.model.push(option.id);
        this.onAdded.emit(option.id);
      } else {
        if (this.settings.autoUnselect) {
          this.model.push(option.id);
          this.onAdded.emit(option.id);
          const removedOption = this.model.shift();
          this.onRemoved.emit(removedOption);
        } else {
          this.selectionLimitReached.emit(this.model.length);
          return;
        }
      }
    }
    if (this.settings.closeOnSelect) {
      this.toggleDropdown();
    }
    this.onModelChange(this.model);
    this.onModelTouched();
  }

  /**
   * gets length of model and updates the number to show
   *  at the top of the filter e.g. 1 location or 2 locations
   *
   * @returns {void}
   */
  updateNumSelected(): void {
    // else apply dynamically based on 2-way model state
    this.numSelected = this.model && this.model.length || 0;
  }

  /**
   * Updates the title with the number of currently selected
   *  or checked items from the list and then determined
   * plurality
   *
   * @returns {void}
   */
  updateTitle(): void {
    if (this.numSelected === 0) {
      this.title = 'None selected';
    } else if (this.settings.dynamicTitleMaxItems && this.settings.dynamicTitleMaxItems >= this.numSelected) {
      this.title = this.options
        .filter((option: IMultiSelectOption) =>
          this.model && this.model.indexOf(option.id) > -1
        )
        .map((option: IMultiSelectOption) => option.name)
        .join(', ');
    } else if (this.settings.displayAllSelectedText && this.model.length === this.options.length) {
      this.title = this.texts.allSelected || '';
    } else {
      if (this.numSelected === 1) {
        const titleArray = this.options.filter((option: IMultiSelectOption) => this.model && this.model.indexOf(option.id) > -1 ).map((option: IMultiSelectOption) => option.name);
        this.title = titleArray[0];
      } else {
        this.title = `${this.numSelected} ${this.texts.checkedPlural} Selected`;
      }
    }
  }

  /**
   * inits model to empty array, iterates the
   *  options array using map to emit an event
   * and return just the `id` property (of each option
   *  element) to the model array. The options array
   * consist of properties defined in the array interface
   * IMultiSelectOptions. Finally, the the model array
   *  will contain an array of id's that correspond
   * to the checked elements in the in the list.
   *
   * @returns {void}
   */
  checkAll(): void {
    this.model = [];
    this.model = this.options
      .map((option: IMultiSelectOption) => {
        this.onAdded.emit(option.id);
        return option.id;
      });

    this.onModelChange(this.model);
    this.onModelTouched();
  }

  /**
   * Iterates through each model item & emits `onRemoved`
   *  event to inform any listeners & then resets the
   * the `model` to and empty array and passes the array
   *  to the `onModelChange` & `onModelTouched` to update.
   *
   * @returns {void}
   */
  uncheckAll(): void {
    this.model.forEach((id: number) => this.onRemoved.emit(id));
    this.model = [];
    this.onModelChange(this.model);
    this.onModelTouched();
  }

  sort(): void {
    this.settings.sortAsc = !this.settings.sortAsc;
  }

  /**
   * Prevents checkbox from being selected
   *
   * @param {Event} event - captures standard event information
   * @param {IMultiSelectOption} option - Takes names, id, etc..
   */
  preventCheckboxCheck(event: Event, option: IMultiSelectOption) {
    if (this.settings.selectionLimit &&
      this.model.length >= this.settings.selectionLimit &&
      this.model.indexOf(option.id) === -1
    ) {
      event.preventDefault();
    }
  }

  /**
   * :: Important method ::
   *
   *  it will update the title state to ensure that if
   * a user selects items from a list and then does not
   *  choose apply it will reset those selections (and the
   * button that shows the count) back to the state that
   *  they were previously. E.g. if you choose 4 items
   * in the dropdown and choose apply. Then go back and
   *  uncheck 2 of those items, but decide to cancel this
   * method ensures that that those 2 items are added back
   *  to the list to keep state in sync with what was done
   * during the apply.
   *
   * @param  {boolean} revertChanges - Indicate if unsaved changes should be saved
   * @returns {void}
   */
  syncState(revertChanges = false): void {
    if (revertChanges) {
      this.model = Object.assign([], this.appliedModel);
      return;
    }

    this.appliedModel = Object.assign([], this.model);
  }

  /**
   * Adds apply button functionality which can be
   *  useful in many cases versus real time updates
   * on each click of an element.
   *
   * @param   {Object<Event>} event -  standard event info
   * @returns {void}
   */
  apply(event: Event): void {
    this.onApply.emit(event);

    // set changers to watch applied model
    this.onModelChange(this.appliedModel);
    this.onModelTouched();

    // hide the dropdown
    this.toggleDropdown(false);
  }

  /**
   * Adds cancel button functionality; This will
   *  emit an event (although currently unused),
   * toggle the dropdown to hide it, and most importantly
   *  it will update the title state to ensure that if
   * a user selects items from a list and then does not
   *  choose apply it will reset those selections (and the
   * button that shows the count) back to zero :)
   *
   * @param   {Object<Event>} event - standard event info
   * @returns {void}
   */
  cancel(event: Event): void {
    // emit the cancel event & toggle the dropdown
    this.onCancel.emit(event);
    this.toggleDropdown(true);
  }
}

@NgModule({
  imports: [CommonModule, FormsModule],
  exports: [MultiselectDropdown, MultiSelectSearchFilter],
  declarations: [MultiselectDropdown, MultiSelectSearchFilter],
})
export class MultiselectDropdownModule {
}
