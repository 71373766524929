<div class="login-container">
  <img src="/assets/img/insighter.png" alt="Ansira Reporting Logo" />
  <p class="sign-in">
    Enter your email address to reset your password.
  </p>

  <form #resetForm="ngForm" (ngSubmit)="requestPasswordReset(email)">
    <div class="form-group">
      <input
        type="email"
        id="name"
        class="form-control"
        placeholder="E-mail Address"
        name="emailField"
        [(ngModel)]="email"
        required
        minlength="5"
        pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
        #emailField="ngModel"
      />
    </div>

    <div *ngIf="resetForm.submitted">
      <div [hidden]="!formError" class="alert alert-danger">
        {{ formError }}
      </div>
      <div [hidden]="!formSuccess" class="alert alert-success">
        {{ formSuccess }}
      </div>
    </div>

    <div class="form-group">
      <button type="submit" class="button" [disabled]="!resetForm.form.valid">
        Reset Password
      </button>
    </div>

    <a
      routerLink="/login"
      style="display: block; text-align: left;"
      class="form-toggle"
      ><i class="fa fa-arrow-circle-left"></i> back</a
    >
  </form>

  <div class="footer">
    © 2020 Ansira, All Rights Reserved.
  </div>
</div>
