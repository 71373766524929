<div [config]="{ show: false }" bsModal #uploadCSVModal="bs-modal"
     class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left">{{title}}</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="uploadCSVModal.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body">
        <p class="invalid" *ngIf="uploadErrorMessage">{{uploadErrorMessage}}</p>
        <p>Please select a csv file to import</p>

        <div class="input-group">
          <label class="input-group-btn">
            <span class="btn btn-primary btn-gradient-dark">
                Browse… <input type="file" (change)="onFileSelect($event)" style="display: none;" accept=".csv">
            </span>
          </label>
          <input id="fileInput" name="fileInput" type="text" class="form-control" readonly>
        </div>
      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-default" data-dismiss="modal" (click)="uploadCSVModal.hide()">Cancel</button>
        <button type="button" class="btn btn-default btn-gradient-dark" (click)="uploadCSV()">Upload</button>
      </div>
    </div>
  </div>
</div>