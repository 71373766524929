import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  ViewChild,
  Output,
  EventEmitter
} from "@angular/core";
import { ModalDirective } from "ngx-bootstrap/modal";

@Component({
  selector: "insighter-upload-csv-modal",
  templateUrl: "./upload-csv-modal.component.html"
})
export class UploadCsvComponent implements OnInit, OnDestroy {
  @Input() public title?: string = "Upload CSV";
  @Input() public uploadErrorMessage: string = "";

  @Output() upload: EventEmitter<Object> = new EventEmitter();

  // form data object that holds csv file info
  public csvData: FormData = new FormData();

  private isAlive: boolean = true;

  @ViewChild("uploadCSVModal") public uploadCSVModal: ModalDirective;

  constructor() {}

  ngOnInit() {}

  ngOnDestroy(): void {
    this.isAlive = false;
  }

  public onFileSelect(event: any): void {
    let fileList: FileList = event.target.files;

    if (fileList.length > 0) {
      let file: File = fileList[0];

      this.csvData.append("file", file, file.name);

      // get the dom element & set the filename text on the input field
      let input: HTMLInputElement = <HTMLInputElement>(
        document.querySelector(".modal .modal-body #fileInput")
      );
      input.value = file.name;
    }
  }

  uploadCSV() {
    this.upload.emit({
      csvData: this.csvData
    });
  }

  show() {
    this.uploadCSVModal.show();
  }

  hide() {
    this.uploadCSVModal.hide();
  }
}
