/**
 * Default project configuration values
 * environment.base.ts
 *
 * This module defines the base configurations for the project.
 * Environment specific configurations inherit these settings
 * and are capable of overriding them.
 *
 */

export const config = {
  production: true,
  api: {
    url: "https://api.insighter.ansira.io"
  },
  sisense: {
    enabled: false,
    appUrl: "https://sisense03.ansira.io",
    logoutUrl: "https://sisense03.ansira.io/api/auth/logout"
  },
  dateFilter: {
    dateFormat: "MM/DD/YY",
    pruneFilters: ["explicit", "members", "multiSelection"]
  },
  metaDataFilter: {
    pruneFilters: ["all", "filter"]
  },
  dynamicFilter: {
    whitelist: [
      "orderlineID",
      "Market",
      "Device",
      "Calendar Month",
      "Calendar Year",
      "Source",
      "nissan_region",
      "nissan_district",
      "Order",
      "Dealer",
      "Field",
      "Region"
    ],
    pruneFilters: ["merged", "filter", "explicit", "all"]
  },
  navigation: {
    paginationLimit: 50
  },
  spinner: {
    blacklist: ["/login", "/reset-password"]
  },
  scripts: [],
  mediaAutomationUrl: 'https://d2naf0eay4jd8e.cloudfront.net',
  mediaAutomationApi: 'https://dsta3mym3g.execute-api.us-west-2.amazonaws.com/dev',
  bulkProcessingUrl: 'http://mediaautomationbulkprocessing-env.iwkuerq8mb.us-west-2.elasticbeanstalk.com',
  pdfUrl: 'https://2lr7nhhwk5.execute-api.us-west-2.amazonaws.com/dev',
  pdfLoginUrl: 'https://insighter.ansira.io',
  okta: {
    clientId: '0oaem129mCvQiVRUp1d6',
    baseUrl: 'https://dev.login.ansira.com',
    issuer: 'https://dev.login.ansira.com/oauth2/default',
    redirectUri: 'https://insighter.dev.ansira.io/login-new/callback',
    requestContext: 'https://dev.login.ansira.com/home/oidc_client/0oaem129mCvQiVRUp1d6/aln177a159h7Zf52X0g8'
  }
};
