<div class="dropdown">
      <div class="wrap">
          <button type="button" class="dropdown-toggle"
          [ngClass]="settings.buttonClasses"
          [style.font-family]="font"
          [style.font-size]="fontSize"
          (click)="toggleDropdown()" [disabled]="disabled">{{ title }}&nbsp;<span class="caret"></span></button>
      </div>
      
    
      <div *ngIf="isVisible" class="dropdown-filters-container">
        <div class="wrap">

        <ul class="dropdown-menu" [class.pull-right]="settings.pullRight" [class.dropdown-menu-right]="settings.pullRight"
              [style.max-height]="settings.maxHeight" [style.min-height]="settings.minHeight" style="display: block; height: auto; overflow-y: auto;">

            <li class="dropdown-item search" *ngIf="settings.enableSearch">
              <div class="input-group input-group-sm">
                <input type="text" data-search class="form-control" placeholder="{{ texts.searchPlaceholder }}"
                      aria-describedby="sizing-addon3" [(ngModel)]="searchFilterText" [ngModelOptions]="{standalone: true}">
                <span class="input-group-btn" *ngIf="searchFilterText.length > 0">
                  <button class="btn btn-default btn-secondary" type="button" (click)="clearSearch($event)"><i class="fa fa-times"></i></button>
                </span>
              </div>
            </li>

            <li class="dropdown-item check-control check-control-check" *ngIf="settings.showCheckAll">
              <a href="javascript:;" role="menuitem" tabindex="-1" (click)="checkAll()">
                <span style="width: 16px;"
                  [ngClass]="{'glyphicon glyphicon-ok': settings.checkedStyle !== 'fontawesome',
                  'fa fa-check': settings.checkedStyle === 'fontawesome'}"></span>
                {{ texts.checkAll }}
              </a>
            </li>
            <li class="dropdown-item check-control check-control-uncheck" *ngIf="settings.showUncheckAll">
              <a href="javascript:;" role="menuitem" tabindex="-1" (click)="uncheckAll()">
                <span style="width: 16px;"
                  [ngClass]="{'glyphicon glyphicon-remove': settings.checkedStyle !== 'fontawesome',
                  'fa fa-times': settings.checkedStyle === 'fontawesome'}"></span>
                {{ texts.uncheckAll }}
              </a>
            </li>
            <li class="dropdown-item check-control check-control-uncheck" *ngIf="settings.showSort">
              <a href="javascript:;" role="menuitem" tabindex="-1" (click)="sort()">
                <ng-container *ngIf="settings.sortAsc">
                  {{ texts.sortAscLabel }}
                </ng-container>
                <ng-container *ngIf="!settings.sortAsc">
                  {{ texts.sortDescLabel }}
                </ng-container>
              </a>
            </li>
            <ng-container *ngIf="!settings.showSort || settings.sortAsc">
              <li class="dropdown-item" [ngStyle]="getItemStyle(option)" *ngFor="let option of options | searchFilter:searchFilterText"
                (click)="!option.isLabel && setSelected($event, option)" [class.dropdown-header]="option.isLabel">
              <ng-template [ngIf]="option.isLabel">
                {{ option.name }}
              </ng-template>
              <a *ngIf="!option.isLabel" href="javascript:;" role="menuitem" tabindex="-1">
                <input *ngIf="settings.checkedStyle === 'checkboxes' && settings.selectionLimit !== 1" type="checkbox"
                  [checked]="isSelected(option)" (click)="preventCheckboxCheck($event, option)"/>
                <span *ngIf="settings.checkedStyle === 'glyphicon'" style="width: 16px;"
                      class="glyphicon" [class.glyphicon-ok]="isSelected(option)"></span>
                <span *ngIf="settings.checkedStyle === 'fontawesome' || settings.selectionLimit === 1" style="width: 16px;display: inline-block;">
                  <i *ngIf="isSelected(option)" class="fa fa-check" aria-hidden="true"></i>
                </span>
                <span [ngClass]="settings.itemClasses">
                  {{ option.name }}
                </span>
              </a>
            </li>
            </ng-container>
            <ng-container *ngIf="settings.showSort && !settings.sortAsc">
              <li class="dropdown-item" [ngStyle]="getItemStyle(option)" *ngFor="let option of options.slice().reverse() | searchFilter:searchFilterText"
                (click)="!option.isLabel && setSelected($event, option)" [class.dropdown-header]="option.isLabel">
              <ng-template [ngIf]="option.isLabel">
                {{ option.name }}
              </ng-template>
              <a *ngIf="!option.isLabel" href="javascript:;" role="menuitem" tabindex="-1">
                <input *ngIf="settings.checkedStyle === 'checkboxes' && settings.selectionLimit !== 1" type="checkbox"
                  [checked]="isSelected(option)" (click)="preventCheckboxCheck($event, option)"/>
                <span *ngIf="settings.checkedStyle === 'glyphicon'" style="width: 16px;"
                      class="glyphicon" [class.glyphicon-ok]="isSelected(option)"></span>
                <span *ngIf="settings.checkedStyle === 'fontawesome' || settings.selectionLimit === 1" style="width: 16px;display: inline-block;">
                  <i *ngIf="isSelected(option)" class="fa fa-check" aria-hidden="true"></i>
                </span>
                <span [ngClass]="settings.itemClasses">
                  {{ option.name }}
                </span>
              </a>
            </li>
            </ng-container>

            
        </ul>
        </div>
        <!-- add apply & cancel buttons -->
        <div *ngIf="settings.showApplyCancelButtons" class="dropdown-item filter-buttons">
          <button type="submit" id="applyButton" class="btn btn-default" [disabled]="applyIsDisabled" (click)="apply($event)">Apply</button>
          <button class="btn btn-default" (click)="cancel($event)">Cancel</button>
        </div>
    </div>
</div>
