
import {takeWhile} from 'rxjs/operators';
import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'insighter-toggle-switch',
  templateUrl: './toggle-switch.component.html',
  styleUrls: ['./toggle-switch.component.scss']
})
export class ToggleSwitchComponent implements OnInit, OnDestroy {
  // toggle button state
  public isChecked: boolean;

  // Manage state of observable subscriptions
  private isAlive: boolean = true;

  @Input() label: string;
  @Output() switchToggle: EventEmitter<any> = new EventEmitter();
  @Input() checkedRoute: string = ''
  @Input() checked?: boolean;

  constructor(private router: Router) { }

  ngOnInit() {
    if (this.checkedRoute) {
      if (this.router && this.router.url.includes(this.checkedRoute)) {
        this.isChecked = true;
      }
      // listen for route changes & update toggle state
      this.router.events.pipe(
        takeWhile(() => this.isAlive))
        .subscribe(route => {
          if (route instanceof NavigationEnd) {
            this.isChecked = route.url.includes(this.checkedRoute) ? true : null;
          }
        });
    } else if (this.checked) {
      this.isChecked = true;
    } 
    
  }

  ngOnDestroy() {
    // kill route observable once component is cleaned up
    this.isAlive = false;
  }

  /**
   * Emits toggle event to header component for state change
   *
   * @param   {boolean} checked
   * @returns {void}
   */
  toggleSwitch(checked: boolean): void {
    return this.switchToggle.emit(checked);
  }

}
