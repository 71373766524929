/**
 * Script Store
 *
 * @description :: Provides an array of Scripts that the Script Service may dynamically inject.
 *                 Script `src` value may be a relative local patch or external script URL
 */

import { environment } from '../../environments/environment';

interface Scripts {
  name: string;
  src: string;
}

export const ScriptStore: Scripts[] = [
  //{ name: 'hotJar', src: '../../assets/js/hotjar.js' },
  { name: 'sisense', src: `${environment.sisense.appUrl}/js/sisense.js?v=3` },
  { name: 'powerbi', src: '../../assets/js/powerbi.min.js' },
  { name: 'tableau', src: 'https://public.tableau.com/javascripts/api/tableau-2.7.0.min.js' },
];
