/**
  * Filters service
  *
  * @description adds layer of persistent storage to the various filters used on the dashboard. Allows the filter states
  *              to be persisted to local storage and reused across dashboards  to save the state of the users
  *              filter selections between dashboard navigation
  */

import { BrowserStore, checkStorageType } from '../stores/storage.store';
import { Injectable, OnDestroy } from '@angular/core';


@Injectable()
export class StorageService implements OnDestroy {
  // private props
  private isAlive: boolean = true;
  private isStorageAvailable: boolean = true;

  // public props
  public storageType: BrowserStore = BrowserStore.localStorage;  // default to local storage
  public store: any = window[this.storageType];  // reference to window[storageType] e.g. window['localStorage']

  constructor() {
    if (!checkStorageType(this.storageType)) {
      this.isStorageAvailable = false;
      throw new Error(`${this.storageType} unavailable!! Not supported by browser or no space available`);
    }
  }

  ngOnDestroy() {
    // cleanup subscriptions for observables using takeWhile
    this.isAlive = false;
  }

  /**
   * Setter for modifying the type of storage used by this service.
   * Attempts to set the storage type used by the service and verify
   * that the both the storage type is available in the browser and
   * the storage medium has free space.
   *
   * Uses the BrowserStore type & enum for input params to set the
   * the current storage. Refer to ./helpers/storage.ts for the
   * details on the BrowserStore object and its various storage types.
   *
   * @param   {BrowserStore} storageType - defaults to localStorage
   * @returns {void}
   */
  setStorageType(storageType: BrowserStore = BrowserStore.localStorage): void {
    // validate the given storage type
    if (!checkStorageType(storageType)) {
      this.isStorageAvailable = false;
      throw new Error(`${storageType} unavailable!! Not supported by browser or no space available`);
    }

    // set the storage type & set the store reference
    this.storageType = storageType;
    this.store = window[this.storageType];
  }

  /**
   * Fetches the type of storage that the filter service is
   * currently using.
   *
   * @returns {BrowserStore} returns the current storage type in use
   */
  getStorageType(): BrowserStore {
    return this.storageType;
  }

  /**
    * setter method that creates the initial filter key
    * and stores the values for calling filter.
    *
    * intended to be called when a filter is being initialized
    *
    * @param   {string} filterKey  unique identifier to differentiate filter stores
    * @param   {Array<string>} filterData  the data to persist
    * @returns {void}
    */
  set(filterKey: string, filterData: any): void {
    this.store.setItem(filterKey, JSON.stringify(filterData));
  }

  /**
    * getter method that fetches the string data, from the
    * browser store, for the given key & serializes the
    * array into native type.
    *
    * @param   {string} filterKey - key name to fetch
    * @returns {any} returns JSON
    */
  get(filterKey: string): any {
    return JSON.parse(this.store.getItem(filterKey));
  }


  /**
   * wrapper around browser store `setItem` without JSON
   * serialization
   *
   * @param   {string} key
   * @param   {any}    data
   * @returns {any}
   */
  setItem(key: string, data: any): any {
    return this.store.setItem(key, data);
  }

  /**
   * simple wrapper around storage getItem that does not
   * return a JSON based value
   *
   * @param {string} key
   * @returns {any}
   */
  getItem(key: string): any {
    return this.store.getItem(key);
  }

  /**
   * wrapper that attempts to remove the persisted data
   * that is associated to the given filter key.
   *
   * @param   {string} filterKey - key name of item to remove from storage
   * @returns {boolean}
   */
  remove(filterKey: string): void {
    this.store.removeItem(filterKey);
  }

  /**
   * wrapper for browser storage `clear()` method that attempts
   * to remove all items from the current specified type of
   * browser storage
   *
   * @returns {void}
   */
  removeAll(): void {
    this.store.clear();
  }
}
