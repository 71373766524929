/**
  * Storage helpers & utilities
  *
  * @description module with helpers for using various browser storage api's & checking if the
  *              storage api is supported by the current browser as well as the amount of free space
  *              available for the storage type. Additionally, it defines an enum `BrowserStore` that's
  *              used to set storage types
  *
  *              e.g.  private storageType: BrowserStore = BrowserStore.localStorage;
  */


/**
 * create enum of storage types
 */
export const BrowserStore = strEnum([
  'localStorage',
  'sessionStorage'
]);

/**
 * Create a BrowserStore type
 */
export type BrowserStore = keyof typeof BrowserStore;

/**
 * Utility function to create a K:V from a list of strings
 *
 * @param {Array<T extends string>} enumArr
 */
function strEnum<T extends string>(enumArr: Array<T>): {[K in T]: K} {
  return enumArr.reduce((res, key) => {
      res[key] = key;

      return res;
  }, Object.create(null));
}


  /**
  * Checks whether the given type of storage is both
  * supported and has available space.
  *
  * @param   {string} type  storage type e.g. 'localStorage'
  * @returns {boolean} true if storage is available
  *
  * @ref developer.mozilla.org/enUS/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API
  */
export function checkStorageType(type: string): boolean {
  const storage = window[type],
        x = '__storage_test__';

  try {
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  }
  catch(e) {
      return e instanceof DOMException && (
        // everything except Firefox
        e.code === 22 ||
        // Firefox
        e.code === 1014 ||
        // test name field too, because code might not be present
        // everything except Firefox
        e.name === 'QuotaExceededError' ||
        // Firefox
        e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
        // acknowledge QuotaExceededError only if there's something already stored
        storage.length !== 0;
    }
}
