/**
 * Event Emitter Service
 *
 * @description :: Provides an evented interface that can encapsulates
 *     communication between any number of objects using Mediator Pattern.
 *
 *     Example usage over the same emitter "app.change":
 *
 *       // component A emits
 *       EmitterService.get("app.change").emit("App has changed!");
 *
 *       // component B subscribes
 *       EmitterService.get("app.change").subscribe(
 *         (value) => { console.log(value); }  // outputs "App has changed!"
 *       )
 */

import { Injectable, EventEmitter } from '@angular/core';

@Injectable()
export class EmitterService {
  // keeps hash of all EventEmitter instances & their identifiers for ref
  private static _emitters: { [id: string]: EventEmitter<any> } = {};

  /**
   * getter/setter that checks if the given `id` has an EventEmitter
   *  instance associated with it or creates a new instance & stores
   * a reference to it in the `_emitters` object.
   *
   * @param {String} id - identifier used to reference specific EventEmitter
   * @returns {EventEmitter<any>} EventEmitter instance matching the id
   */
  static get(id: string): EventEmitter<any> {
    // register the emitter using the `id` if it does not yet exist
    if (!this._emitters[id]) {
      this._emitters[id] = new EventEmitter();
    }

    return this._emitters[id];
  }
}
