
import {takeWhile} from 'rxjs/operators';
import { Component, OnInit, Input, OnDestroy} from '@angular/core';
import { AuthService } from '../../../services/auth.service';
import { EmitterService } from '../../../services/emitter.service';

import { $log } from '../../../services/logger.service';

@Component({
  selector: 'insighter-user-widget',
  templateUrl: './user-widget.component.html',
  styleUrls: ['./user-widget.component.scss']
})
export class UserWidgetComponent implements OnInit, OnDestroy {

  public username: string;

  // Utilized to manage observable subscriptions
  private isAlive: boolean = true;

  @Input() hasClientLinks: boolean;
  @Input() navigationBarBg?: string;
  @Input() navigationBarFont?: string;
  @Input() navigationBarFontSize?: string;
  @Input() navigationBarTextColor?: string;

  constructor(private AuthService: AuthService) {
    this.username = '';
  }

  ngOnInit() {
    // listen for username event
    EmitterService
      .get('AuthService.username').pipe(
      takeWhile(() => this.isAlive))
      .subscribe(username => this.username = username);

    // alternative is to get email address from local storage.. maybe this
    // is better than using the event? I would rather not store any additional
    // data in localstorage than necessary so i'm leaning toward the event
    let localStorageUser = localStorage.getItem('currentUser');

    if (localStorageUser) {
      let currentUser = JSON.parse(localStorageUser);
      this.username = currentUser.firstName;
      if (currentUser.lastName) {
        this.username += ' ' + currentUser.lastName
      }
    }
  }

  ngOnDestroy() {
    // cleanup subscriptions
    this.isAlive = false;
  }

  /**
   * Click event handler that emits an event to a sibling
   * component to render the overlay modal for editing
   * user profile information.
   *
   * @returns {boolean} returns false to prevent event bubbling
   */
  showModal(): boolean {
    EmitterService.get('modal.show').emit(true);

    return false;
  }

  /**
   * Help email handler
   *
   * Called when user clicks the help button; Constructs & formats
   * a `mailto:` object that opens the default email application
   * on the users machine & allows them to contact us for help.
   *
   * @param   {Event} event - event from browser
   * @returns {boolean}
   */
  public showHelp(event: Event): boolean {
    const to = 'help-insighter@ansira.com';
    const subject = 'Insighter Help Desk Ticket';
    const body = `
    Please describe your problem in-full, including the following details, where applicable:\n\n\

    Company/Client\n\
    Browser being used\n\
    Issue (with screenshots, if possible)\n\
    Contact Information (email/phone)\n\

    We will get back to you with a response within the next 48 hours.
    `;

    // build the mailto string & update the location
    window.location.href = `mailto:${to}?subject=${subject}&body=${encodeURIComponent(body)}`;

    return false;
  }
}
