import { Component } from '@angular/core';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'insighter-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent {
  public email: string;
  public formSuccess: string;
  public formError: string;

  constructor(private AuthService: AuthService) { }

  /**
   * Request a password reset
   *
   * @param {string} email - user email
   */
  requestPasswordReset(email) {
    this.formSuccess = null;
    this.formError = null;

    this.AuthService.resetPassword(email)
      .then(() => {
        this.formSuccess = 'An e-mail has been dispatched with password reset instructions.';
      })
      .catch(() => {
        this.formError = 'No user was found with that e-mail address';
      });
  }

}
