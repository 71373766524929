/**
 * Erorr Log Service
 *
 * @description :: Send message to the server to log and possibly send email
 */


import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { StorageService } from './storage.service';


@Injectable()
export class ErrorLogService {

    private LOG_URL = '/log/error';

    constructor(private ApiService: ApiService,
        private StorageService: StorageService) {}

    logError(error) {
        let localStorageUser = JSON.parse(this.StorageService.getItem('currentUser'));
        let clientId = parseInt(this.StorageService.getItem('selectedClient'), 10);

        error.user = {
            firstName: localStorageUser.firstName,
            lastName: localStorageUser.lastName,
            id: localStorageUser.id,
            user: localStorageUser.user
        }

        error.clientId = clientId;

        return this.ApiService.post(this.LOG_URL, {
            error: error,
            options: {
                email: true
            }
        }).pipe(map(res => {
            return res.body;
        }))
    }

}
