<!-- show loading spinner -->
<div *ngIf="!isProgressBar" [hidden]="!showSpinner" class="spinner">
  <div class="progress-bar">
    <div class="rect1"></div>
    <div class="rect2"></div>
    <div class="rect3"></div>
    <div class="rect4"></div>
    <div class="rect5"></div>
  </div>
</div>

<!-- show progress bar indicator -->
<ng-progress id="myProgress" [spinner]="isProgressBar" [max]="100"
             [speed]="200" [direction]="'ltr+'" [min]="15" [spinnerPosition]="'right'"
             [color]="'red'" [trickleSpeed]="250" [thick]="false" [ease]="'linear'">
</ng-progress>
