
import {takeWhile} from 'rxjs/operators';
import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Router } from "@angular/router";
import { ClientsService } from '../../services/client.service';
import { AuthService } from '../../services/auth.service';
import { EmitterService } from '../../services/emitter.service';

@Component({
  selector: 'insighter-settings-sidebar-content',
  templateUrl: 'settings-sidebar-content.component.html',
  styleUrls: ['settings-sidebar-content.component.scss']
})
export class ClientAdminSidebarContentComponent implements OnInit, OnDestroy {

  public hasMediaAutomationPrivileges: boolean;
  public services: Array<string> = [];

  private isAlive: boolean = true;

  public isDistributorClient: boolean = false;

  public showLocations: boolean = true;

  @Input() clients: any;
  public metaDefinitions: object[];

  public hasSuperAdminPrivileges: boolean;
  public hasAllPrivileges: boolean;
  public hasPermissionsPrivileges: boolean;
  public hasPartitionsPrivileges: boolean;
  public hasBudgetsPrivileges: boolean;
  public hasAdminMediaPrivileges: boolean;



  constructor(
    private AuthService: AuthService,
    public ClientsService: ClientsService,
    private router: Router
  ) { }

  ngOnInit() {
    const clientId = this.ClientsService.getClientId()
    this.selectClient(clientId);
    this.hasMediaAutomationPrivileges = this.AuthService.hasMediaAutomationPrivileges();

    this.hasSuperAdminPrivileges = this.AuthService.hasPrivilege('super_admin');
    this.hasAllPrivileges = this.AuthService.hasPrivilege('client_admin_all') || this.AuthService.hasAdminPrivileges();
    this.hasPermissionsPrivileges = this.AuthService.hasPrivilege('client_admin_permissions');
    this.hasPartitionsPrivileges = this.AuthService.hasPrivilege('client_admin_partitions');
    this.hasBudgetsPrivileges = this.AuthService.hasPrivilege('client_admin_budgets');
    this.hasAdminMediaPrivileges = this.AuthService.hasPrivilege('client_admin_media_automation');

    EmitterService.get('client.services.changed').pipe(
      takeWhile(() => this.isAlive))
      .subscribe(services => {
        if (services) {
          this.services = services.map(i => {
            return i.name;
          })
        } else {
          this.services = [];
        }
        
      });
  }

  ngOnDestroy() {
    this.isAlive = false;
  }

  /**
   * Event handler for selecting a new client - updates the
   *  meta definitions
   *
   * @param client
   * @returns {void}
   */
  selectClient(client): void {
    this.buildClientMetaData(client);

    if (this.clients && this.clients.length) {
      const selectedClient = this.clients.find(cl => cl.id === client);

      if (selectedClient) {

        if (selectedClient.type === 'single') {
          this.showLocations = false;
          
          if (this.router.url.includes("metadata")) {
            EmitterService.get('navigate.clientAdmin').emit(true);
          }
        } else {
          this.showLocations = true;
        }

        if (selectedClient.distributorClientId) {
          this.isDistributorClient = true;
        } else {
          this.isDistributorClient = false;
        }
      } else {
        this.isDistributorClient = false;
        this.showLocations = true;
      }

    }
  }

  /**
   * fetches the client meta-definitions using the
   *  given client identifier
   *
   * @param client
   */
  buildClientMetaData(client): void {
    this.ClientsService.fetchClientMetaDefinitions(client)
      .subscribe(response => {
        this.metaDefinitions = response.filter(i => i.client === client);
    });
  }

}
