import { Component, OnInit, Input } from "@angular/core";
import { AuthService } from '../../services/auth.service';

@Component({
  selector: "insighter-media-automation-sidebar-content",
  templateUrl: "./media-automation-sidebar-content.component.html",
  styles: [
    `
      /* ensure first child in sidebar nav has no top border */
      .admin-nav .navigation > li:first-child {
        border-top: 0;
      }
    `
  ]
})
export class MediaAutomationSidebarContentComponent implements OnInit {

  public hasAllPrivileges: boolean;
  public hasBulkProcessingPrivilege: boolean;
  public hasJobLogPrivilege: boolean;
  public hasShowPlaybooksPrivilege: boolean;
  public hasShowGatewayPrivilege: boolean;
  

  constructor(
    private AuthService: AuthService
  ) {}

  ngOnInit() {
    this.hasAllPrivileges = this.AuthService.hasPrivilege('media_automation_all') || this.AuthService.hasPrivilege('media_automation_full_access');
    this.hasBulkProcessingPrivilege = this.AuthService.hasPrivilege('media_automation_bulk_processing');
    this.hasJobLogPrivilege = this.AuthService.hasPrivilege('media_automation_jobs_log');
    this.hasShowPlaybooksPrivilege = this.AuthService.hasPrivilege('media_automation_playbooks');
    this.hasShowGatewayPrivilege = this.AuthService.hasPrivilege('media_automation_gateway');
  }


  public shouldShowBulkProcessing() {
    return this.hasAllPrivileges || this.hasBulkProcessingPrivilege;
  }
 
  public shouldShowJobLog() {
    return this.hasAllPrivileges || this.hasJobLogPrivilege;
  }

  public shouldShowPlaybooks() {
    return this.hasAllPrivileges || this.hasShowPlaybooksPrivilege;
  }

  public shouldShowGateway() {
    return this.hasAllPrivileges || this.hasShowGatewayPrivilege;
  }
}
