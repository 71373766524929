<div class="login-container">
  <img src="/assets/img/insighter.png" alt="Ansira Reporting Logo" />

  <div *ngIf="errorMsg" class="alert alert-danger new-login-error">
    <p>All users must use the new login page:</p>
    <br />
    <a
        class="help-desk-link"
        [href]="newLink"
        >{{newLink}}</a>
    <br />
    <br />
    <ul>
      <li>Add this page to your bookmarks.</li>
      <li>You will be required to use you own email.</li>
      <li>Your password will be the same.</li>
      <li>You will be prompted to setup MFA with Okta.  You can select the option to recieve an SMS text.</li>
      <li>If you have issues with the new login, email <a href="mailto:kyle.woodard@ansira.com">kyle.woodard@ansira.com</a> with the details.</li>
    </ul>

    <br />

    <p>If you must login now using this page click <a (click)="overrideLogin()">here</a>.</p>
  </div>

  <p class="sign-in">
    Sign in to start your session
  </p>

  <form #loginForm="ngForm" (ngSubmit)="authenticateUser(email, password)">
    <div class="form-group">
      <input
        type="email"
        id="name"
        class="form-control"
        placeholder="E-mail Address"
        name="emailField"
        [(ngModel)]="email"
        required
        pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
        #emailField="ngModel"
      />
    </div>

    <div class="form-group">
      <input
        type="password"
        id="password"
        class="form-control"
        placeholder="Password"
        name="passwordField"
        [(ngModel)]="password"
        required
        #passwordField="ngModel"
      />
    </div>

    <div *ngIf="loginForm.submitted && formError" class="alert alert-danger">
      {{ formError }}
      <br />
      <a
        class="help-desk-link"
        target="_blank"
        href="https://docs.google.com/forms/d/e/1FAIpQLSf9OJHTNjYEHfwq7kiSLtdn-HxNAImKsST8kYEg6zyuM3OgDQ/viewform?usp=sf_link"
        >Contact Help Desk</a
      >
    </div>

    <div class="form-group">
      <button
        id="signin"
        type="submit"
        class="button"
        [disabled]="!loginForm.form.valid"
      >
        Sign In
      </button>
    </div>

    <p class="reset-password">
      <a routerLink="/reset-password">Forgot your password?</a>
    </p>
  </form>
  <div class="footer">
    © 2021 Ansira, All Rights Reserved.
  </div>
</div>
