/**
 * Sidebar Component
 *
 * @description :: renders the sidebar/main navigation view for the app
 */


import {takeWhile} from 'rxjs/operators';
import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, DoCheck, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { EmitterService } from '../../services/emitter.service';
import { AppService } from '../../services/app.service';


declare let $: any;

@Component({
  selector: 'insighter-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, OnDestroy, DoCheck, AfterViewInit {
  @Input() data: any;
  @Input() clients: any;
  @Output() toggle = new EventEmitter();
  public isEmbedded: boolean;
  public uri: string;

  // Utilized to manage observable subscriptions
  private isAlive: boolean = true;

  sidebarCollapsed = false;
  isDashboard = false;
  showAdminNav = false;
  hasClientDropdown = false;
  role: string;
  sidebarHidden = false;

  public hasMediaAutomationPrivileges: boolean;
  public hasDataWarehousePrivileges: boolean;
  public hasClientAdminPrivileges: boolean;

  public dragging: boolean = false;

  /**
   * Injects AuthService, Router, and ClientsService dependencies
   *
   * Additionally it assigns the value of the current user's
   *  permissions role as lowercase so that the sidebar can check
   * which content to display based on what permissions the user is
   *  allowed to view
   *
   * @param {AuthService} AuthService
   * @param {AppService} AppService
   * @param {Router} router
   */
  constructor(private AuthService: AuthService,
              private AppService: AppService,
              private router: Router
              ) {
    // get role of user
    this.role = AuthService.getRole().toLowerCase();
    this.hasDataWarehousePrivileges = this.AuthService.hasDataWarehousePrivileges();
    this.hasMediaAutomationPrivileges = this.AuthService.hasMediaAutomationPrivileges();
    this.hasClientAdminPrivileges = this.AuthService.hasClientAdminPrivileges();
  }

  ngOnInit() {
    // Collapse the sidebar by default if the app is embedded
    this.isEmbedded = this.AppService.appIsEmbedded;
    if (this.isEmbedded) {
      this.sidebarCollapsed = true;
    }

    this.showClientDropdown();

    // get length of client array
    if (this.clients && this.clients.length > 1) {
      this.hasClientDropdown = true;
    }

    // listen for dashboard.selected on mobile to collapse the sidebar when a dashboard is selected
    EmitterService.get('dashboard.selected').pipe(
      takeWhile(() => this.isAlive))
      .subscribe(() => {
        if (window.innerWidth < 768) {
          this.sidebarCollapsed = !this.sidebarCollapsed
        }
      });

    EmitterService.get('sidebar.toggle').pipe(
      takeWhile(() => this.isAlive))
      .subscribe(() => {
        this.sidebarCollapsed = !this.sidebarCollapsed;
      });

    EmitterService
      .get('sidebar.hidden').pipe(
      takeWhile(() => this.isAlive))
      .subscribe(state => {
        this.sidebarHidden = state;
      });
  }

  ngAfterViewInit() {
    $('#dragbar').mousedown((e) => {
      e.preventDefault();

      this.dragging = true;
      let main = $('.main-sidebar');
      let ghostbar = $('<div>',
                       {id: 'ghostbar',
                        css: {
                               height: main.outerHeight(),
                               top: main.offset().top,
                               left: main.offset().left
                              }
                       }).appendTo('body');
      
       $(document).mousemove((e) => {
        let percentage = (e.pageX / window.innerWidth) * 100;

        //constrain movement
        if (e.pageX > 20 && percentage <= 90) {
          ghostbar.css("left", e.pageX + 2);
        }
      });
    });

    $(document).mouseup((e) => {
      if (this.dragging) {
        let percentage = (e.pageX / window.innerWidth) * 100;
        let maxPercent = 90;
        //let mainPercentage = 100 - percentage;

        if (percentage > maxPercent) {
          percentage = maxPercent;
        }

        $('.main-sidebar').css("width", percentage + "%");
        //$('.sidebar').css("width", percentage + "%");

        let contentWrapperLeft: any = percentage;
        let inPixel = (percentage * window.innerWidth)

        if (inPixel <= 10) {
          contentWrapperLeft = '10px';
        } else {
          contentWrapperLeft = contentWrapperLeft + "%";
        }

        $('.content-wrapper').css({
          //width: mainPercentage + "%",
          'margin-left': contentWrapperLeft
        });

        let left = e.pageX - 10;
        let max = window.innerWidth * (maxPercent / 100);
        
        if (left < 0) {
          left = 0;
        }
        if (left > max) {
          left = max;
        }

        let sideBarPercent = (left / window.innerWidth) * 100;
        let dragBarPercent = ( (left + 20) / window.innerWidth) * 100;

        $('.sidebar-toggle').css({
          left: sideBarPercent + '%'
        });

        $('#dragbar').css({
          left: dragBarPercent + '%'
        });

        $('#ghostbar').remove();
        $(document).unbind('mousemove');
        this.dragging = false;
        EmitterService.get('sidebar.toggle.resize').emit(this.sidebarCollapsed);
        // EmitterService.get("sidebar.collapsed").emit(true);
      }
   });

  }

  ngOnDestroy() {
    this.isAlive = false;
  }

  ngDoCheck() {
    // Update property that view markup utilizes to make decisions about what to display
    this.uri = this.router.url;
  }

  /**
   * checks localstorage user object to see if user is an admin
   *  and updates the class isAdmin property to true/false to
   * display or hide the markup for admin navigation
   *
   * @returns none
   */
  checkAdmin() {
    let isAdmin = this.AuthService.isAdmin();
    let currentRoute = this.router.url;

    // make use of es6 string includes method to check for required route
    if (isAdmin && currentRoute.includes('admin')) {
      this.showAdminNav = true;
    } else if (currentRoute.includes('dashboard')) {
      this.isDashboard = true;
    }
  }


  /**
   * Toggle Sidebar: controls sidebar collapse and expand functionality
   *
   * @param {EventEmitter} e - the $event data
   * @returns none
   */
  toggleSidebar(e) {
    // prevent link from firing
    e.preventDefault();

    this.sidebarCollapsed = !this.sidebarCollapsed;
    this.toggle.emit(this.sidebarCollapsed);

    if (!this.isEmbedded) {
      //Since we are resizing the content window, send an event
      EmitterService.get('sidebar.toggle.resize').emit(this.sidebarCollapsed);
    }
    return false;
  }

  /**
   * determine whether or not the client dropdown should be displayed, based on role
   * @return {boolean}
   */
  showClientDropdown() {
    return this.AuthService.isAdmin();
  }

  public shouldShowDataWarehouse() {
    return this.hasDataWarehousePrivileges;
  }

  public shouldShowMediaAutomation() {
    return this.hasMediaAutomationPrivileges;
  }

  public shouldShowClientAdmin() {
    return this.hasClientAdminPrivileges;
  }

}
