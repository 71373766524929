/**
 * merge.ts
 *
 * @description Provides helper methods for environment modules
 */


/**
 * Returns whether an item is an object
 *
 * @param {Any} item - Any value type
 */
const isObject = item => {
  return (item && typeof item === 'object' && !Array.isArray(item) && item !== null);
};

/**
 * Recursive version of Object.assign()
 *
 * @param {Object} target - Object to merge properties into
 * @param {Object} source - Object to merge properties from
 */
export const merge = (target, source) => {
  let output = Object.assign({}, target);
  if (isObject(target) && isObject(source)) {
    Object.keys(source).forEach(key => {
      if (isObject(source[key])) {
        if (!(key in target)) {
          Object.assign(output, {[key]: source[key]});
        } else {
          output[key] = merge(target[key], source[key]);
        }
      } else {
        Object.assign(output, {[key]: source[key]});
      }
    });
  }
  return output;
};
