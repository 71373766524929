import { Injectable } from "@angular/core";
import { Client } from "../models/client";
import { EmitterService } from "./emitter.service";

@Injectable()
export class ClientRedirectService {
  constructor() {}

  getRedirectUrl(subDomain, currentLocation) {
    let result = currentLocation;
    let fixedSubDomain = subDomain;

    if (fixedSubDomain === null || fixedSubDomain === undefined) {
      fixedSubDomain = "";
    }

    let resultDomain = "://" + fixedSubDomain;

    if (subDomain) {
      resultDomain += ".";
    }

    resultDomain += "insighter";

    if (/(:\/\/insighter)/.test(result)) {
      //add with no existing subdomain
      result = result.replace(/(:\/\/insighter)/, resultDomain);
    } else if (/(:\/\/\w*\.insighter)/.test(result)) {
      //replace existing subdomain
      result = result.replace(/(:\/\/\w*\.insighter)/, resultDomain);
    }

    return result;
  }

  shouldRedirect(client) {
    if (!client) {
      return false;
    }

    let currentUrl = window.location.href;
    let subdomain = client.subdomainSlug;

    let result = this.getRedirectUrl(subdomain, currentUrl);

    return currentUrl !== result;
  }

  doRedirect(client) {
    let currentUrl = window.location.href;
    let subdomain = client.subdomainSlug;

    let result = this.getRedirectUrl(subdomain, currentUrl);
    let loginUrl = this.getLoginUrl(result);
    console.log(result);

    if (currentUrl !== result) {
      console.log("should redirect");
      
      this.createIframe(loginUrl).then(() => {

        console.log('redirecting to ', result)
        window.location.assign(result);
      })
    }
  }

  getLoginUrl(redirectUrl) {
    const pathArray = redirectUrl.split("/");

    return pathArray[0] + "//" + pathArray[2] + "/assets/storage.html";
  }

  createIframe(url) {
    return new Promise((resolve, reject) => {
      console.log("creating iframe");
      const existing = document.getElementById("cross-domain-iframe");
      const iframeContainer = document.getElementById(
        "cross-domain-iframe-container"
      );
      const frameId = "cross-domain-iframe";
      const iframe = document.createElement("iframe");
      iframe.id = frameId;
      iframe.src = url;
      iframe.onload = () => {
        console.log("iframe loaded");
        const newFrame: any = document.getElementById("cross-domain-iframe");
        if (newFrame) {
          const iframeWindow: any = newFrame.contentWindow || newFrame;
          console.log(iframeWindow);

          let obj = {
            name: "Jack"
          };
          console.log("posting message");

          const currentUser = localStorage.getItem("currentUser");
          const selectedClient = localStorage.getItem("selectedClient");

          if (currentUser) {
            iframeWindow.postMessage(
              JSON.stringify({
                type: "storage",
                key: "currentUser",
                data: JSON.parse(currentUser)
              }),
              "*"
            );
          }

          if (selectedClient) {
            iframeWindow.postMessage(
              JSON.stringify({
                type: "storage",
                key: "selectedClient",
                data: parseInt(selectedClient, 10)
              }),
              "*"
            );
          }

          //TOOD wait for them to set?
          resolve()
        }
      };

      if (existing) {
        iframeContainer.removeChild(existing);
      }

      iframeContainer.appendChild(iframe);
    });
  }
}
