import { Injectable } from "@angular/core";

declare let gtag: Function;

@Injectable()
export class GoogleAnalyticsService {
  send(type: string, name: string, payload: any) {

    if (!('UserId' in payload)) {
      const user = JSON.parse(localStorage.getItem("currentUser"));
      if (user) {
        payload.UserId = user.user;
      }
    }

    gtag(type, name, payload);
  }
}
