import { Component, OnInit, Input } from "@angular/core";
import { AuthService } from '../../services/auth.service';

@Component({
  selector: "insighter-data-warehouse-sidebar-content",
  templateUrl: "./data-warehouse-sidebar-content.component.html",
  styles: [
    `
      /* ensure first child in sidebar nav has no top border */
      .admin-nav .navigation > li:first-child {
        border-top: 0;
      }
    `
  ]
})
export class DataWarehouseSidebarContentComponent implements OnInit {

  public hasAllPrivileges: boolean;
  public hasCrudPrivileges: boolean;

  constructor(
    private AuthService: AuthService
  ) {}

  ngOnInit() {
  
    this.hasAllPrivileges = this.AuthService.hasPrivilege('data_warehouse_all');
    this.hasCrudPrivileges = this.AuthService.hasPrivilege('data_warehouse_crud');
  }
}
