/**
 * Login Page Component
 *
 * @description :: renders the login page and provides methods for authenticating users
 */

import { Component, OnDestroy } from "@angular/core";
import { Router, NavigationStart } from "@angular/router";
import { AuthService } from "../services/auth.service";
import { EmitterService } from "../services/emitter.service";
import { StorageService } from "../services/storage.service";
import { $log } from "../services/logger.service";

@Component({
  selector: "insighter-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"]
})
export class LoginComponent implements OnDestroy {
  public errorMsg: string;
  public override: boolean = false;

  public newLink: string;
  public email;
  public password;
  public formError;

  /**
   * Injects AuthService & Router as a dependency
   *
   * @param {AuthService} AuthService - authentication service dependency
   * @param {StorageService} StorageService - storage service helper (local or session store)
   * @param {Router} Router - router dependency
   */
  constructor(
    private AuthService: AuthService,
    private StorageService: StorageService,
    private Router: Router
  ) {
    // enable blocking loading indicator only for login
    EmitterService.get("spinner.enableLoadingIndicator").emit(true);

    this.newLink = window.location.origin.replace('http:', 'https:') + '/login-new';
  }

  ngOnDestroy() {
    // disable loading indicator and use only progress bar for all other pages
    EmitterService.get("spinner.enableLoadingIndicator").emit(false);
  }

  /**
   * Sends login form data to AuthService, then loads
   *  the Sisense script using the script service & then
   * redirects to the dashboards route.
   *
   * This method also broadcasts an event - indicating successful login -
   *  to any listeners & specifically the app component so that it can
   * initialize fetching the clients data. This is because at the login
   *  view route, the app component ngOnInit() has already been invoked
   * but no authentication has happened so fetchClients() never triggers.
   *  The event is not needed during app refresh as app component
   * checks in onInit if authenticated and fetches clients.
   *
   * @param {string} email - User email address
   * @param {string} password - User's password
   * @returns {Promise<any>}
   */
  authenticateUser(email, password): any {
    this.formError = null;
    this.errorMsg = null;

    if (email.endsWith('@ansira.com')) {
      window.location.href = this.newLink;
      return false;
    }

    if (!this.override) {
      this.errorMsg = 'true'
      return false;
    }

    return new Promise((resolve, reject) => {
      this.AuthService.authenticateUser(email, password).subscribe(
        data => {
          if (!data) {
            this.formError = "Invalid e-mail and/or password.";
            reject();
          }

          // resolve promise & navigate to dashboards
          resolve(this.Router.navigate(["/dashboards"]));
        },
        error => {
          console.log(error);
          let errMsg = "";

          // construct error message for user in the UI
          switch (error.status) {
            case 401:
              errMsg = "Incorrect username and/or password. ";
              break;
            case 502:
              errMsg = "The server is currently unreachable! ";
              break;
            case 0: // for some reason a 502 occasionally gets set as 0
              errMsg = "The server is currently unreachable! ";
              break;
            default:
              // Unknown error
              errMsg = "Unknown error!";
              $log.error(error);
              break;
          }

          // construct complete error msg
          this.formError = `${error.statusText} ${errMsg}`;
          reject();
        }
      );
    });
  }

  overrideLogin() {
    this.override = true;
    this.authenticateUser(this.email, this.password);
    return false;
  }

  
}
