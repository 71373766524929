import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app.routes';

// route helpers
import { ClientsResolver } from './route-helpers/clients.resolver';

// Services
import { AuthService } from './services/auth.service';
import { ApiService } from './services/api.service';
import { AuthGuardService } from './services/auth-guard.service';
import { FoldersService} from './services/folders.service';
import { DashboardsService } from './services/dashboards.service';
import { UsersService } from './services/users.service';
import { ClientsService } from './services/client.service';
import { EmitterService } from './services/emitter.service';
import { ScriptService } from './services/script.service';
import { $log } from './services/logger.service';
import { AppService } from './services/app.service';
import { TaskService } from './services/task.service';
import { TableService } from './services/table.service';
import { StorageService } from './services/storage.service';
import { ErrorLogService } from './services/error-log.service';
import { ClientResolverService } from './services/client-resolver.service';
import { ClientRedirectService } from './services/clientredirect.service';
import { GoogleAnalyticsService } from './services/google-analytics.service';
import { PermissionLogService } from './services/permission-log.service';
import { MyDownloadsService } from './services/mydownloads.service';
import { EventLogService } from './services/event-log.service';

// Components
import { AppComponent } from './app.component';
import { OutletComponent } from './outlet.component';
import { LoginComponent } from './login/login.component';
import { LoginExternalComponent } from './login/login-external.component';
import { LoginPDFComponent } from './login/login-pdf.component';
import { SpinnerComponent } from './shared-components/spinner/spinner.component';
import { HeaderComponent } from './shared-components/header/header.component';
import { UserWidgetComponent } from './shared-components/header/user-widget/user-widget.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { UserProfileComponent } from './shared-components/user-profile/user-profile.component';
import { FooterComponent } from './shared-components/footer/footer.component';
import { LogoutComponent } from './logout/logout.component';
import { ToggleSwitchComponent } from './shared-components/header/toggle-switch/toggle-switch.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ForgotPasswordValidateComponent } from './forgot-password/forgot-password-validate.component';

//sidebar
import { SidebarComponent } from './shared-components/sidebar/sidebar.component';
import { AdminSidebarContentComponent } from './admin/admin-sidebar-content/admin-sidebar-content.component';
import { DashboardsSidebarContentComponent } from './dashboards/dashboards-sidebar-content/dashboards-sidebar-content.component'
import { DashboardFolderComponent } from './dashboards//dashboards-sidebar-content/dashboard-folder/dashboard-folder.component';
import { ClientAdminSidebarContentComponent } from './settings/settings-sidebar-content/settings-sidebar-content.component';
import { DynamicSectionComponent } from "./settings/settings-sidebar-content/dynamic-section/dynamic-section.component";
import { MediaAutomationSidebarContentComponent } from 
  './media-automation/media-automation-sidebar-content/media-automation-sidebar-content.component';
import { MediaAutomationClientSidebarContentComponent } from 
  './media-automation/media-automation-client-sidebar-content/media-automation-client-sidebar-content.component';
import { DataWarehouseSidebarContentComponent } from './data-warehouse/data-warehouse-sidebar-content/data-warehouse-sidebar-content.component';
import { CustomDashbaordsSidebarContentComponent } from "./custom-dashboards/custom-dashboards-sidebar-content/custom-dashboards-sidebar-content.component";

import { SharedModule } from './shared.module';
import { Router } from '@angular/router';
import {environment} from '../environments/environment';

//Okta
import {
  OKTA_CONFIG,
  OktaAuthModule
} from '@okta/okta-angular';

import { LoginOtkaComponentIDPNoCookies } from './login-okta/login-okta-idp-no-cookies.component';
import { LoginOtkaCallbackComponent } from './login-okta/login-okta-callback.component';

const oktaConfig = Object.assign({
  onAuthRequired: (oktaAuth, injector) => {
    const router = injector.get(Router);
    // Redirect the user to your custom login page
    router.navigate(['/login-new']);
  }
}, environment.okta);

// const IS_IE = navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > -1;
// if (IS_IE) {
//   console.log('IS IE11 - turning off PKCE');
//   oktaConfig['pkce'] = false;
// }

@NgModule({
  declarations: [
    OutletComponent,
    AppComponent,
    SpinnerComponent,
    SidebarComponent,
    LoginComponent,
    PageNotFoundComponent,
    HeaderComponent,
    FooterComponent,
    DashboardsSidebarContentComponent,
    DashboardFolderComponent,
    UserWidgetComponent,
    UserProfileComponent,
    LogoutComponent,
    ClientAdminSidebarContentComponent,
    DynamicSectionComponent,
    ToggleSwitchComponent,
    AdminSidebarContentComponent,
    LoginExternalComponent,
    LoginPDFComponent,
    ForgotPasswordComponent,
    ForgotPasswordValidateComponent,
    MediaAutomationSidebarContentComponent,
    MediaAutomationClientSidebarContentComponent,
    DataWarehouseSidebarContentComponent,
    CustomDashbaordsSidebarContentComponent,
    LoginOtkaComponentIDPNoCookies,
    LoginOtkaCallbackComponent
  ],
  imports: [
    BrowserModule,
    SharedModule,
    HttpClientModule,
    AppRoutingModule,
    OktaAuthModule,
  ],
  providers: [
    AuthService,
    ApiService,
    AuthGuardService,
    ClientsResolver,
    FoldersService,
    DashboardsService,
    UsersService,
    EmitterService,
    $log,
    ClientsService,
    ScriptService,
    AppService,
    TaskService,
    TableService,
    StorageService,
    ErrorLogService,
    ClientResolverService, 
    ClientRedirectService,
    GoogleAnalyticsService, 
    PermissionLogService,
    MyDownloadsService,
    EventLogService,
    { provide: OKTA_CONFIG, useValue: oktaConfig }
  ],
  bootstrap: [OutletComponent, SpinnerComponent]
})
export class AppModule { }
