import { Component, OnInit, Input, Output, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  selector: 'insighter-modal',
  templateUrl: './modal.component.html'
})
export class ModalComponent implements OnInit {

  @ViewChild('autoShownModal') public autoShownModal: ModalDirective;     // view child of ng2-bootstrap modal

  @Input() public title: string = '';
  @Input() public confirmLabel: string = '';

  public isVisible: boolean = false;                                      // visibility indicator

  constructor() { }

  ngOnInit() { }

  public showModal(title?: string): void {
    // shortcut to set the title
    if (title) {
      this.title = title;
    }

    this.isVisible = true;
  }

  public hideModal(): void {
    this.autoShownModal.hide();
  }

  public onHidden(): void {
    this.isVisible = false;
  }
}
