/**
 * Users Service
 *
 * @description :: used to fetch and manage users from the api
 */


import {map} from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { EmitterService } from './emitter.service';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { User } from '../models/user';

@Injectable()
export class UsersService {

  // api folders endpoint
  private USERS_URL = '/users';
  private BULK_USERS_URL = '/bulk/users';
  private userId: number;

  // caches the total pagination count from header X-Pagination-Count
  private totalCount: number;

  constructor(
    private ApiService: ApiService
  ) {
    // listen for event for user id on successful authentication because
    // local storage is too slow and first time user logs in the app.component
    // call to this service's fetchUserClients() will fail w/o current user id
    EmitterService
      .get('auth.user.id')
      .subscribe(id => {
        this.userId = id;
      });

    // if for some reason the event is missed try local storage
    if (!this.userId) {
      this.userId = JSON.parse(
        localStorage.getItem('currentUser') || String(false)
      ).id;
    }
  }

  /**
   * GET request to users endpoint on the API. Takes
   *  pagination params `perPage` & `pageNumber` and
   * sets reasonable defaults on them.
   *
   * @param   {number} perPage      (Optional) number of results to show per page
   * @param   {number} pageNumber   (Optional) request a specific page number
   * @returns {Observable<User[]>}   returns subscribed observable with
   *                                 an array of data in response
   */
  fetchUsers(perPage?: number, pageNumber?: number, sort?: Object, filter?: Object): Observable<any[]> {
    let url = `${this.USERS_URL}/`;

    if (perPage && pageNumber) {
      url = `${this.USERS_URL}/?page=${pageNumber}&perPage=${perPage}`
    }

    if (sort) {
      url += `&sort=${encodeURIComponent(JSON.stringify(sort))}`
    }

    if (filter && Object.keys(filter).length) {
      url += `&filter=${encodeURIComponent(JSON.stringify(filter))}`
    }

    return this.ApiService
      .get(url).pipe(
      map(res => {
        // store the total count of users
        this.totalCount = Number(res.headers.get('x-pagination-count'));

        return this.castToModel(res.body);
      }));
  }

  /**
   * GET request to users endpoint on the API. Similar to
   *  fetchUsers(), but only returns users that belong to the
   * particular client, given by the `clientId`.
   *
   * @param   {number} clientId     client id
   * @param   {number} perPage      (optional) number of results to show per page
   * @param   {number} pageNumber   (optional) request a specific page number
   * @returns {Observable<User[]>}   returns subscribed observable with
   *                                 an array of data in response
   */
  fetchUsersByClient(clientId: number, perPage?: number, pageNumber?: number, sort?: Object, filter?: Object): Observable<User[]> {
    let url = `/clients/${clientId}/users/`;

    if (perPage && pageNumber) {
      url = `/clients/${clientId}/users/?page=${pageNumber}&perPage=${perPage}`
    }

    if (sort) {
      url += `&sort=${encodeURIComponent(JSON.stringify(sort))}`
    }

    if (filter && Object.keys(filter).length) {
      url += `&filter=${encodeURIComponent(JSON.stringify(filter))}`
    }

    return this.ApiService
      .get(url).pipe(
      map(res => {
        // store the total count of users
        this.totalCount = Number(res.headers.get('x-pagination-count'));

        return this.castToModel(res.body);
      }));
  }

  toggleDashboardForUsers(clientId, dashboardId, payload) {
    let url = `/dashboard-users/${clientId}/${dashboardId}`;

    return this.ApiService
      .post(url, payload).pipe(
      map(res => {
        return res.body;
      }));
  }

  toggleWidgetsForUser(userId, dashboardId, payload) {
    let url = `/dashboard-users-widgets/${userId}/${dashboardId}`;

    return this.ApiService
      .post(url, payload).pipe(
      map(res => {
        return res.body;
      }));
  }

  toggleUsersForWidget(clientId, dashboardId, widgetId, payload) {
    let url = `/dashboard-widget-users/${clientId}/${dashboardId}/${widgetId}`;

    return this.ApiService
      .post(url, payload).pipe(
      map(res => {
        return res.body;
      }));
  }

  getWidgetsForUser(userId, dashboardId) {
    let url = `/dashboard-users-widgets/${userId}/${dashboardId}`;

    return this.ApiService
      .get(url).pipe(
      map(res => {
        return res.body;
      }));
  }

  getUserLevelsForUser(userId, clientId) {
    let url = `/dashboard-users-levels/${clientId}/${userId}`;

    return this.ApiService
      .get(url).pipe(
      map(res => {
        return res.body;
      }));
  }
  
  /**
   * GET request to users/:id/clients endpoint on the API. Used to
   *  return the client(s) that the currently authenticated user is
   * allowed to view & interact.
   *
   * @note perPage here is now using environment default of 50 as I have
   *       no way of knowing how many clients may exist and one of the
   *       tools that displays these clients is a select dropdown meaning we
   *       can not limit them with pagination as selects + pagination = fail.
   *       For views that fetch clients for tables, we will pass the perPage
   *       param manually using something reasonable like 10.
   *
   * @param   {number} perPage      number of results to show per page
   * @param   {number} pageNumber   request a specific page number
   * @returns {observable<any[]>}   returns subscribed observable with
   *                                 an array of data in response
   */
  fetchUserClients(perPage: number = environment.navigation.paginationLimit,
                   pageNumber: number = 1): Observable<any> {
    return this.ApiService
      .get(`${this.USERS_URL}/${this.userId}/clients/?page=${pageNumber}&perPage=${perPage}`).pipe(
      map(res => {
        // store the total count of users
        this.totalCount = Number(res.headers.get('x-pagination-count'));

        return res.body;
      }));
  }


  /**
   * GET request to users detail endpoint to retrieve info
   *  for one specific user.
   *
   * @param    {Number} userId the id or primary key of the user requesting for
   * @returns  {Observable<User>} returns subscribed observable with response
   */
  fetchUser(userId): Observable<User> {
    return this.ApiService
      .get(`${this.USERS_URL}/${userId}`).pipe(
      map(res => {
        return this.castToModel(res.body);
      }));
  }

  /**
   * PUT request to users detail endpoint to update the
   *   properties for the given user.
   *
   * @param   {number} userId - user id of the user to change
   * @param   {Object} model   - represents the user data { id: 1, isActive: false }
   * @returns {Observable<User>} returns subscribable observable with response
   */
  updateUser(userId, model, clientId?: number): Observable<User> {
    // look for certain fields to strip
    if (model) {
      //delete model.id;
      delete model.fullName;
      delete model.toggle;
      delete model.isNew;
    }

    console.log(model, 'UsersService.updateUser');

    // delete password if empty or request will fail
    if (model.password === '' || model.password === null) {
      delete model.password;
    }

    let url = `${this.USERS_URL}/${userId}`;

    if (clientId) {
      url += `/${clientId}`;
    }

    return this.ApiService
      .put(url, model).pipe(
      map(res => {
        return this.castToModel(res.body);
      }));
  }

  /**
   * POST request to root users endpoint to create a
   *  new user with the given data. Note, this takes
   * care to strip out the `id` field & any other
   *  fields that should not exist in the request data
   * for creating a new user.
   *
   * @param   {User} model instance of the User model
   * @returns {Observable<User>} returns subscribable observable with response
   */
  createUser(model: User): Observable<User>  {
    // look for certain fields to strip
    if (model) {
      delete model.id;
      delete model.fullName;
      delete model.toggle;
    }

    return this.ApiService
      .post(this.USERS_URL, model).pipe(
      map(res => {
        return this.castToModel(res.body);
      }));
  }

  /**
   * PUT request to bulk/users endpoint to change multiple
   *  users at once using bulk actions.
   *
   * @param   {Object} data the data to send in request
   * @returns {observable<any>} returns subscribable observable with response
   */
  bulkUpdateUsers(data): Observable<any>  {
    return this.ApiService
      .put(`${this.BULK_USERS_URL}`, data).pipe(
      map(res => {
        return res.body;
      }));
  }


  /**
   * DELETE request to bulk/users endpoint to delete
   *  multiple users at once
   *
   * @param   {Object}  data  the data to send in request
   * @returns {observable<any>} returns subscribable observable with response
   */
  bulkDeleteUsers(data): Observable<any> {
    return this.ApiService
      .delete(`${this.BULK_USERS_URL}`, data).pipe(
      map(res => {
        return res.body;
      }));
  }

  /**
   * Updates the users password
   *
   * @param {number} userId - user id of current user to update
   * @param {Object} data - data to send containing password to change
   * @returns {Observable<User>}
   */
  updatePassword(userId, data): Observable<User> {
    return this.ApiService
      .put(`${this.USERS_URL}/${userId}`, data).pipe(
      map(res => {
        return this.castToModel(res.body);
      }));
  }

  /**
   * Endpoint is used to get the associated metadata for given user
   *
   * @param userId - user id of current user to associate metadata with
   * @returns {Observable<any>}
   */
  fetchMetadataAssociations(userId): Observable<any> {
    return this.ApiService
      .get(`${this.USERS_URL}/${userId}/meta-data`).pipe(
      map(res => {
        return res.body;
      }));
  }

  updateUserMetaDataForClient(userId, model, clientId): Observable<User> {
    const metaData = JSON.stringify(model.metaData);

    return this.ApiService
      .put(`${this.USERS_URL}/${userId}/meta-data/${clientId}`, metaData).pipe(
      map(res => {
        return this.castToModel(res.body);
      }));
  }

  updateUserPowerBIRolesForClient(userId, roles, clientId): Observable<User> {
    const powerBIRoles = JSON.stringify(roles);

    return this.ApiService
      .put(`${this.USERS_URL}/${userId}/powerbiroles/${clientId}`, powerBIRoles).pipe(
      map(res => {
        return this.castToModel(res.body);
      }));
  }

  /**
   * Endpoint is used to update the associated dashboards for given user
   *
   * @param   {number} userId - user id of current user to associate metadata with
   * @param   {User}   model  - the data to send to server
   * @returns {Observable<User>}
   */
  updateDashboardAssociations(userId: number, model: User, clientId: number): Observable<User> {
    // remove computed props from the model
    if (model) {
      delete model.toggle;
      delete model.fullName;
      delete model.isNew;
      delete model.password;
      delete model.roleName;
      delete model.status;
    }

    let data = JSON.stringify(model);

    return this.ApiService
      .put(`${this.USERS_URL}/${userId}/${clientId}`, data).pipe(
      map(res => {
        return this.castToModel(res.body);
      }));
  }

  /**
   * uploads user image to the api, which is then stored on s3
   * @param  {any}             image - object from uploader
   * @param  {number}          id - id of user
   * @return {Observable<any>} returns a post request to the api
   */
  uploadImage(image: any, id: number): Observable<any> {
    let data = new FormData();
    data.append('file', image.file);

    return this.ApiService
      .post(`${this.USERS_URL}/${id}/upload`, data).pipe(
      map(res => {
        return res.body;
      }, error => {
        return error;
      }));
  }

  /**
   * Getter function used to retrieve total count of users. Count
   *  data comes from the X-Pagination-Count header
   *
   * @returns {number} returns total count
   */
  getTotalCount(): number {
    return this.totalCount;
  }

  /**
   * Casts a response object to User model instances
   *
   * @param   {Array<User>|User} data
   * @returns {Array<User>|User}
   */
  castToModel(data: any) {
    if (Array.isArray(data)) {
      data = data.map(obj => {
        return new User(
          obj.id, obj.email, obj.lastName, obj.firstName,
          obj.image, obj.profile, obj.account, obj.roles, obj.restrictedDashboards,
          obj.clients, obj.isActive, false, obj.metaData, obj.updatedAt, 
          obj.isDistributorUser, obj.distributorUserId, obj.userLevels,
          obj.dashboardEnabled, obj.pageRestrictions, obj.widgetEnabled,
          obj.powerBIRoles
        )
      });
    } else {
      data = new User(
        data.id, data.email, data.lastName, data.firstName,
        data.image, data.profile, data.account, data.roles, data.restrictedDashboards,
        data.clients, data.isActive, false, data.metaData, data.updatedAt,
        data.isDistributorUser, data.distributorUserId, data.userLevels,
        data.dashboardEnabled, data.pageRestrictions, data.widgetEnabled,
        data.powerBIRoles
      )
    }

    return data;
  }
}
