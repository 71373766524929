<div class="subfolder" [class.collapsed]="isCollapsed" [class.parent-collapsed]="parentCollapsed" [class.child]="isChild">
  <a href="" class="subfolder-title" (click)="toggleFolder($event)" [class.child]="isChild">
    <i class="fa folder-icon" [class.fa-folder]="isCollapsed" [class.fa-folder-open]="!isCollapsed"></i>
    <span class="title-text">{{folder.name}}</span>
    <i class="fa folder-open-indicator" [class.fa-caret-down]="!isCollapsed" [class.fa-caret-right]="isCollapsed"></i>
  </a>
  <div *ngIf="loading" class="dashboard-loader"><img src="assets/img/dashboard-sidebar-loader.gif" alt=""></div>

  <ul class="dashboards" *ngIf="!loading && userRole === 'superAdmin'" [sortablejs]="folder.dashboards" [sortablejsOptions]="options" [class.empty]="!folder.dashboards || !folder.dashboards.length">
    <li *ngIf="!folder.dashboards || !folder.dashboards.length">
      <a>Folder is Empty</a>
    </li>
    <li *ngFor="let dashboard of folder.dashboards">
      <a routerLinkActiveOptions="{exact: true}" routerLinkActive="active-dashboard" routerLink="/dashboards/{{dashboard.id}}">{{dashboard.name}}</a>
    </li>
  </ul>
  <ul class="dashboards" *ngIf="!loading && userRole !== 'superAdmin'">
    <li *ngIf="!folder.dashboards || !folder.dashboards.length">
      <a>Folder is Empty</a>
    </li>
    <li *ngFor="let dashboard of folder.dashboards">
      <a routerLinkActiveOptions="{exact: true}" routerLinkActive="active-dashboard" routerLink="/dashboards/{{dashboard.id}}">{{dashboard.name}}</a>
    </li>
  </ul>

  <div class="folder-container" *ngIf="folder.children && folder.children.length && userRole === 'superAdmin'" [sortablejs]="folder.children" [sortablejsOptions]="options">
    <div *ngFor="let child of folder.children">
      <insighter-dashboard-folder [folder]="child" [parentCollapsed]="isCollapsed" isChild="true"></insighter-dashboard-folder>
    </div>
  </div>
  
  <div class="folder-container" *ngIf="folder.children && folder.children.length && userRole !== 'superAdmin'">
    <div *ngFor="let child of folder.children">
      <!-- render the insighter-dashboard-folder component, passing in a folder -->
      <insighter-dashboard-folder [folder]="child" [parentCollapsed]="isCollapsed" isChild="true"></insighter-dashboard-folder>
    </div>
  </div>
</div>
