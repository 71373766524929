/**
 * Client Resolve route helper
 *
 * @description :: Ensure that Clients are fetched before routes are fully loaded; This is tied to the
 *                 root route '' path in app.routes & physically associated with AppComponent - since app-component
 *                 will always load (as its bootstrapped) but to associate resolve data with route the component
 *                 is required.
 */

import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ClientResolverService } from '../services/client-resolver.service';


@Injectable()
export class ClientsResolver implements Resolve<any> {
  public clients: Array<any> = [];

  constructor(private ClientResolverService: ClientResolverService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> {
    return new Promise((resolve, reject) => {
      this.ClientResolverService
        .fetchUserClients()
        .then(
          clients => {
            this.clients = clients;
            resolve(clients);
          },
          err => {
            reject(err);
          }
        )
    });

  }
}
