/*
    Delete bad bootstrap print rules
*/
export function modifyCss() {

  try {
    let styleSheets = document.styleSheets;

    outer: for (let i = 0; i < styleSheets.length; i++) {
      let sheet = styleSheets[i];

      if (!sheet.href || sheet.href.startsWith(window.location.origin)) {
        for (let j = 0; j < sheet["cssRules"].length; j++) {
          let rule = sheet["cssRules"][j];

          if (rule["cssRules"]) {
            for (let k = 0; k < rule["cssRules"].length; k++) {
              let subRule = rule["cssRules"][k];

              if (subRule.selectorText === "*, ::after, ::before") {
                rule.deleteRule(subRule);
                break outer;
              }
            }
          }
        }
      }
    }
  } catch (e) {
    console.warn('modify css failed')
    console.error(e);
  }
}
