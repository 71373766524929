// Used by PDF service as initial landing page - we don't want PDF using Okta with /login-new
import { Component } from '@angular/core';

@Component({
  selector: 'insighter-login-blank',
  template: '<div></div>'
})
export class LoginBlankComponent {
  constructor(
  ) {
    
  }
}
