<nav class="admin-nav">
  <ul class="navigation">
    <li class="nav-section">
      <a class="bold outer title">
        <i class="fa fa-building fa-lg" aria-hidden="true"></i>
        <span>Clients</span>
      </a>
      <ul class="navigation sub lt">
        <li>
          <a class="inner" href="#" routerLink="/admin/clients" routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}">
            <span>All Clients</span>
          </a>
        </li>
        <li>
          <a class="inner" href="#" routerLink="/admin/client/new" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
            <span>Create New Client</span>
          </a>
        </li>
        <li>
          <a class="inner" href="#" routerLink="/admin/dash" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
            <span>All Dashboards</span>
          </a>
        </li>
      </ul>
    </li>
    <li class="nav-section">
      <a class="bold outer title">
        <i class="fa fa-users fa-lg" aria-hidden="true"></i>
        <span>User Management</span>
      </a>
      <ul class="navigation sub lt">
        <li>
          <a href="#" class="inner" routerLink="/admin/users" routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}">
            <span>All Users</span>
          </a>
        </li>
        <li>
          <a href="#" class="inner" routerLink="/admin/user/new" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
            <span>Create New User</span>
          </a>
        </li>
      </ul>
    </li>
    <li class="nav-section">
      <a class="bold outer title">
        <i class="fa fa-shield fa-lg" aria-hidden="true"></i>
        <span>User Roles</span>
      </a>
      <ul class="navigation sub lt">
        <li>
          <a href="#" class="inner" routerLink="/admin/roles" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
            <span>All Roles</span>
          </a>
        </li>
        <li>
          <a href="#" class="inner" routerLink="/admin/role/new" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
            <span>Create New Role</span>
          </a>
        </li>
      </ul>
    </li>
    <li class="nav-section">
      <a class="bold outer title">
        <i class="fa fa-filter"></i>
        <span>Whitelist Filters</span>
      </a>
      <ul class="navigation sub lt">
        <li>
          <a href="#" class="inner" routerLink="/admin/whitelist" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
            <span>All Filters</span>
          </a>
        </li>
        <li>
            <a href="#" class="inner" routerLink="/admin/whitelist/new" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
              <span>Create New Filter</span>
            </a>
          </li>
      </ul>
    </li>
    <li class="nav-section">
      <a class="bold outer title">
        <i class="fa fa-tasks fa-lg" aria-hidden="true"></i>
        <span>Tasks</span>
      </a>
      <ul class="navigation sub lt">
        <li>
          <a href="#" class="inner" (click)="syncSisense()">
            <span>Sync Sisense Records</span>
          </a>
        </li>
        <li>
          <a href="#" class="inner" (click)="flushCache()">
            <span>Flush Session Cache</span>
          </a>
        </li>
        <li>
          <a href="#" class="inner" (click)="syncDistributorClients()">
            <span>Sync Distributor Clients</span>
          </a>
        </li>
        <li>
          <a href="#" class="inner" (click)="syncRedshiftLocations()">
            <span>Sync Redshift Locations</span>
          </a>
        </li>
      </ul>
    </li>
    <li class="nav-section">
      <a class="bold outer title">
        <i class="fa fa-calendar fa-lg" aria-hidden="true"></i>
        <span>Scheduled Jobs</span>
      </a>
      <ul class="navigation sub lt">
        <li>
          <a href="#" class="inner" routerLink="/admin/scheduled-jobs" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
            <span>All Scheduled Jobs</span>
          </a>
        </li>
        <li>
          <a href="#" class="inner" routerLink="/admin/scheduled-jobs/new" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
            <span>Create Scheduled Job</span>
          </a>
        </li>
        <li>
          <a href="#" class="inner" (click)="checkScheduledJobs()">
            <span>Check Scheduled Jobs</span>
          </a>
        </li>
      </ul>
    </li>
    <li class="nav-section">
      <a class="bold outer title">
        <i class="fa fa-calendar fa-history" aria-hidden="true"></i>
        <span>Logs</span>
      </a>
      <ul class="navigation sub lt">
        <li>
          <a href="#" class="inner" routerLink="/admin/event-logs" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
            <span>Event Logs</span>
          </a>
        </li>
      </ul>
    </li>
  </ul>
</nav>
