export class UserLevel {
  toggle?: boolean;

  constructor(
    public levels_clientid: string,
    public childorgdefname: string,
    public clientname: string
  ) {
    this.toggle = false;
  }
}
