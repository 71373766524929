import { Component, OnInit, Input } from "@angular/core";
import { AuthService } from '../../services/auth.service';

@Component({
  selector: "insighter-media-automation-client-sidebar-content",
  templateUrl: "./media-automation-client-sidebar-content.component.html",
  styles: [
    `
      /* ensure first child in sidebar nav has no top border */
      .admin-nav .navigation > li:first-child {
        border-top: 0;
      }
    `
  ]
})
export class MediaAutomationClientSidebarContentComponent implements OnInit {
  @Input() clients: any;

  public hasAllPrivileges: boolean;
  public hasShowPlaybooksPrivilege: boolean;
  public hasShowGatewayPrivilege: boolean;

  constructor(
    private AuthService: AuthService
  ) {}

  ngOnInit() {
    this.clients = this.filterClients(this.clients);

    this.hasAllPrivileges = this.AuthService.hasPrivilege('media_automation_all') || this.AuthService.hasPrivilege('media_automation_full_access');
    this.hasShowPlaybooksPrivilege = this.AuthService.hasPrivilege('media_automation_playbooks')
    this.hasShowGatewayPrivilege = this.AuthService.hasPrivilege('media_automation_gateway');
  }

  filterClients(clients) {
    return clients.filter(client => {
      if (client && client.services) {
        let serviceNames = client.services.map(i => i.name);
        return serviceNames.indexOf("Media Automation") !== -1;
      }
      return false;
    });
  }

  buildNavigation(clientId) {}

  public shouldShowPlaybooks() {
    return this.hasAllPrivileges || this.hasShowPlaybooksPrivilege;
  }

  public shouldShowGateway() {
    return this.hasAllPrivileges || this.hasShowGatewayPrivilege;
  }
}
