<!-- modal for generating new password -->
<div bsModal #userProfileModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
     aria-labelledby="myLargeModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left">My Profile</h4>
        <button type="button" class="close pull-right" (click)="userProfileModal.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body clearfix">
        <div class="profile">
          <h4>Profile</h4>
          <span class="profile-field"><span class="field-name">Name:</span> {{userProfile?.firstName}} {{userProfile?.lastName}}</span>
          <span class="profile-field"><span class="field-name">Email:</span> {{userProfile?.email}}</span>
          <span *ngFor="let prop of userProfile?.otherProps" class="profile-field"><span class="field-name">{{prop.label | titleCase }}:</span> {{prop.value}}</span>
        </div>
        <a class="change-password-toggle" (click)="toggleChangePassword($event)" [class.hidden]="canChangePassword">Change
          Password</a>
        <div class="change-password-section" [class.hidden]="!canChangePassword">
          <h4>Change Password</h4>
          <form #form="ngForm" (ngSubmit)="changePassword(password, confirmedPassword)">
            <div class="col-xs-12 col-md-6">
              <div *ngIf="!formSubmitted" class="form-inputs">
                <div class="form-group">
                  <label for="password">Enter a New Password</label>
                  <input type="password"
                         name="password"
                         class="form-control"
                         id="password"
                         placeholder="Enter your new password"
                         required minlength="5"
                         [(ngModel)]="password"
                  >
                </div>
                <div class="form-group">
                  <label for="repeatPassword">Repeat Password</label>
                  <input type="password"
                         name="repeatPassword"
                         class="form-control"
                         placeholder="Enter your new password again"
                         id="repeatPassword"
                         required minlength="5"
                         [(ngModel)]="confirmedPassword">
                </div>
              </div>
              <div class="response" id="passwordResetResponse"></div>
              <button *ngIf="!formSubmitted" type="submit" class="btn btn-gradient-light" [disabled]="!form.form.valid">Submit</button>
              <button *ngIf="!formSubmitted" class="btn btn-default" (click)="toggleChangePassword($event)">Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
