/**
 * AuthGuard Service
 *
 * @description :: ensures that unauthorized users are unable to access protected routes
 */

import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable()
export class AuthGuardService implements CanActivate {

  constructor(
    private Router: Router,
    private AuthService: AuthService
  ) { }

  /**
   * Checks localStorage for jwt before allowing component to activate
   */
  canActivate() {
    // User is not authenticated, take them to the login page
    if (!this.AuthService.isAuthenticated) {
      this.Router.navigate(['/login']);
      return false;
    }

    return true;
  }

}
