import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { HttpClientModule } from '@angular/common/http';

// 3rd party libs
import { ModalModule } from "ngx-bootstrap/modal";
import { ButtonsModule } from "ngx-bootstrap/buttons";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { AlertModule } from "ngx-bootstrap/alert";

import { NgProgressModule } from "ngx-progressbar";
import { NgProgressHttpModule } from 'ngx-progressbar/http';
import { SortablejsModule } from "ngx-sortablejs";

// Reusable components
import { ClientDropdownComponent } from "./shared-components/client-dropdown/client-dropdown.component";
import { MultiselectDropdownModule } from "./shared-components/multiselect/multiselect.component";
import { ModalComponent } from "./shared-components/modal/modal.component";
import { ConfirmationComponent } from "./shared-components/confirmation/confirmation.component";
import { UploadCsvComponent } from "./shared-components/modal/upload/upload-csv-modal.component";

import { DatePickerComponent } from './dashboards/dashboard/date-picker/date-picker.component';

// helpers
import { SafeStylePipe } from "./pipes/safe-style.pipe";
import { SafeHtmlPipe } from "./pipes/safe-html.pipe";
import { TitleCasePipe } from "./pipes/title-case.pipe";
import { SingularPipe } from "./pipes/singular.pipe";
import { PluralizePipe } from "./pipes/pluralize.pipe";

@NgModule({
  imports: [
    HttpClientModule,
    CommonModule,
    FormsModule,
    MultiselectDropdownModule,
    ModalModule.forRoot(),
    ButtonsModule.forRoot(),
    TooltipModule.forRoot(),
    AlertModule.forRoot(),
    NgProgressModule,
    NgProgressHttpModule.withConfig({
      id: 'myProgress',
      silentApis: ['https://api.powerbi.com']
    }),
    SortablejsModule.forRoot({ animation: 150 })
  ],
  declarations: [
    ClientDropdownComponent,
    SafeStylePipe,
    SafeHtmlPipe,
    TitleCasePipe,
    PluralizePipe,
    ModalComponent,
    ConfirmationComponent,
    UploadCsvComponent,
    SingularPipe,

    DatePickerComponent,
  ],
  providers: [],
  exports: [
    CommonModule,
    FormsModule,
    MultiselectDropdownModule,
    ModalModule,
    ButtonsModule,
    TooltipModule,
    AlertModule,
    NgProgressModule,
    SortablejsModule,
    ClientDropdownComponent,
    SafeStylePipe,
    SafeHtmlPipe,
    TitleCasePipe,
    PluralizePipe,
    ModalComponent,
    ConfirmationComponent,
    UploadCsvComponent,
    SingularPipe,
    DatePickerComponent
  ]
})
export class SharedModule {}
