import { map, filter, takeWhile } from "rxjs/operators";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { EmitterService } from "../../services/emitter.service";
import { Router, NavigationStart, NavigationEnd } from "@angular/router";
import { environment } from "../../../environments/environment";
import { NgProgress, NgProgressRef } from "ngx-progressbar";

@Component({
  selector: "insighter-spinner",
  templateUrl: "./spinner.component.html",
  styleUrls: ["./spinner.component.scss"],
})
export class SpinnerComponent implements OnDestroy, OnInit {
  private animLength: number = 1100; // minimum time spinner is visible
  private persist: boolean = false; // ensure spinner persists state change in rare cases
  private isAlive: boolean = true; // Utilized to manage observable subscriptions

  public showSpinner: boolean = false; // default spinner to visible
  public isProgressBar: boolean = true; // spinner type: progress bar or loading indicator; true means progress bar

  progressRef: NgProgressRef;

  /**
   * constructor - initializes spinner & listens for route change events
   *
   * @param  {Router} router  injects Router as dependency
   * @param  {NgProgressService} ProgressService
   */
  constructor(private router: Router, public progress: NgProgress) {
    // The spinner component does not use input params due to the nature of how it has to function
    // therefore if one wants to show the loading indicator (spinner) then emit this event with true/false
    EmitterService.get("spinner.enableLoadingIndicator")
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((show: boolean) => {
        this.isProgressBar = !show;
      });

    // listen for events from the router (while isAlive is true) to show/hide the spinner on change
    this.router.events
      .pipe(
        takeWhile(() => this.isAlive),
        filter(
          (navState: any) =>
            !environment.spinner.blacklist.includes(navState.url)
        ),
        filter(
          (navState: any) =>
            navState instanceof NavigationStart ||
            navState instanceof NavigationEnd
        ),
        map((navState: any) =>
          navState instanceof NavigationStart ? "start" : "end"
        )
      )
      .subscribe((state: string) => {
        // check for routing nav start or end events
        if (state === "start") {
          // show the spinner on navigation start
          this.showSpinner = true;
        } else if (state === "end") {
          // hide the spinner on navigation end. I've wrapped it in a
          // timeout & used a time property coz route states update to fast
          setTimeout(() => {
            if (!this.persist) {
              this.showSpinner = false;
            }
          }, this.animLength);
        }
      });
  }

  ngOnInit(): void {
    this.progressRef = this.progress.ref("myProgress");

    // listen for `toggle` events on `spinner` namespace & react accordingly
    // depending on the type of loading indicator (progress bar has its own service)
    EmitterService.get("spinner.toggle")
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((show: boolean) => {
        if (this.isProgressBar) {
          show ? this.progressRef.start() : this.progressRef.complete();
        } else {
          this.showSpinner = this.persist = show;
        }
      });
  }

  ngOnDestroy() {
    // cleanup - un-subscribe from any observables using takeWhile
    this.isAlive = false;
    this.progressRef.destroy();
  }
}
