/**
 * The User model
 *
 * @description :: represents the User model & encapsulates any methods used
 *              to interact with the user object.
 */

export class User {
  // user model properties
  public password: string;

  // view-model & computed fields
  public toggle?: boolean;
  public sendRegistrationEmail?: boolean;
  public status: string;
  public fullName: string;
  public roleName: string;

  /**
   * User model object constructor
   *
   * @param  {number}        id         user id/primary key
   * @param  {string}        email      Email address
   * @param  {string}        lastName   last name property
   * @param  {string}        firstName  first name property
   * @param  {string}        image      image property
   * @param  {Object}        profile    image containing dynamic profile attributes
   * @param  {Object}        account    related account model
   * @param  {Array<Object>} roles      related roles objects
   * @param  {Array<Object>} restrictedDashboards blacklisted dashboards
   * @param  {Array<Object>} clients    related clients object
   * @param  {boolean}       isActive   users isActive boolean status
   * @param  {boolean}       isNew      indicates is a new model
   * @param  {Array<number>} [metaData] user metadata associations
   * @param  {string}        updatedAt  time a user was last updated
   */
  constructor(public id: number, public email: string, public lastName: string, public firstName: string,
              public image: string, public profile: Object, public account: Object|number,
              public roles: Array<Object>, public restrictedDashboards: Array<Object>,
              public clients: Array<Object>, public isActive: boolean,
              public isNew: boolean = false, public metaData?: Array<number|string>, public updatedAt?: string,
              public isDistributorUser: boolean = false, public distributorUserId: string = null,
              public userLevels?: Array<any>,
              public dashboardEnabled?: boolean,
              public pageRestrictions?: any,
              public widgetEnabled?: any,
              public powerBIRoles?: any
            ) {
    // default to unchecked
    this.toggle = false;

    // set password to stars so default form values look correct
    this.password = null;

    // build computed properties if this is not a new user
    if (!isNew) {
      this.fullName = this.getFullName();
      this.status = this.getStatus();
      this.roleName = this.getRoleName();
    }
  }

  /**
   * Getter that returns a computed property for getting
   *  users fullname. It returns the fullname property;
   *
   * @return {string} returns computed property fullname as string
   */
  getFullName() {
    let fullName = this.firstName;

    if (this.lastName !== undefined && this.lastName !== null && this.lastName !== "") {
      fullName += ' ' + this.lastName;
    }

    return fullName;
  }

  /**
   * Getter that computes a status string based on the user's
   *  `isActive` property
   *
   * @return {string} returns a string of 'Active' or 'Inactive'
   */
  getStatus() {
    return this.isActive === true ? 'Active' : 'Inactive';
  }

  /**
   * Getter that returns the name of the first role in the
   *   roles array
   *
   * @returns {string} returns string of role[0].name
   */
  getRoleName() {
    if (this.roles && this.roles.length) {
      return this.roles.map(role => {
        return role["name"]
      }).join(", ")
    }

    return null;
  }
}
