/**
 * Event Log Service
 *
 * @description :: Store event log in the database event_log table
 */

import { map } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { StorageService } from "./storage.service";
import { Observable } from "rxjs";
import { EventLog  } from "../models/eventlog";
import { of } from "rxjs";
import { environment } from '../../environments/environment';


@Injectable()
export class EventLogService {
  private LOG_URL = "/event-log";
  private totalCount: number;

  constructor(
    private ApiService: ApiService,
    private StorageService: StorageService
  ) {}

  logEvent(event): Observable<any> {
    let payload: any = {};

    let localUser = this.StorageService.getItem("currentUser");
    if (localUser) {
      let localStorageUser = JSON.parse(localUser);
      payload.user = localStorageUser.id;
      payload.email = localStorageUser.user;
    }

    payload.event = event.event;

    if (environment.production) {
      return this.ApiService.post(this.LOG_URL, payload).pipe(
        map((res) => {
          return res.body;
        })
      );
    }

    return of({});
  }

  fetchPaged(
    perPage?: number,
    pageNumber?: number,
    sort?: Object,
    filter?: Object
  ): Observable<EventLog[]> {
    let url = `${this.LOG_URL}/`;

    if (perPage && pageNumber) {
      url = `${this.LOG_URL}/?page=${pageNumber}&perPage=${perPage}`;
    }

    if (sort) {
      url += `&sort=${encodeURIComponent(JSON.stringify(sort))}`;
    }

    if (filter && Object.keys(filter).length) {
      url += `&filter=${encodeURIComponent(JSON.stringify(filter))}`;
    }

    return this.ApiService.get(url).pipe(
      map((res) => {
        // store the total count of users
        this.totalCount = Number(res.headers.get("x-pagination-count"));

        return this.castToModel(res.body);
      })
    );
  }

  getTotalCount(): number {
    return this.totalCount;
  }

  castToModel(data: any) {
    if (Array.isArray(data)) {
      data = data.map(obj => {
        return new EventLog(
          obj.id,
          obj.user,
          obj.email,
          obj.event,
          obj.created_at
        );
      });
    } else {
      data = new EventLog(
        data.id,
        data.user,
        data.email,
        data.event,
        data.created_at
      );
    }

    return data;
  }

  downloadCSV(): Observable<any> {
    return this.ApiService
      .get(`${this.LOG_URL}/export`, {responseType: 'text'}).pipe(
      map(res => {
        const blob = new Blob([res.body], { type: 'text/csv' });

        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'events.csv';
        link.click();
      }));
  }
}
