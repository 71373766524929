import { Component, OnInit, Input } from '@angular/core';
import { EmitterService } from '../../../services/emitter.service';

@Component({
  selector: 'insighter-dynamic-section',
  templateUrl: './dynamic-section.component.html',
  styleUrls: ['./dynamic-section.component.scss']
})
export class DynamicSectionComponent implements OnInit {

  @Input() section: any;

  constructor() { }

  /**
   * Emits an event of the currently selected client's
   *  section name from the meta-definitions. This is
   * currently subscribed to by any of the settings view
   *  components that need the section name at time of
   * of a change in client dropdown selection;
   *
   * The local storage is set because when the metadata view
   *  is loaded initially it will not have time to catch the
   * event emitter's section.name event; However, subsequent
   *  changes to the section name (triggered by the dropdown)
   * will emit the event and update the section name in the
   *  metadata view;
   *
   *  @returns {void}
   */
  ngOnInit(): void {
    localStorage.setItem('sectionName', this.section.name);

    EmitterService
      .get('metadefinition.section.name')
      .emit(this.section.name);
  }
}
