/**
 * Dashboards Service
 *
 * @description :: used to fetch dashboards from the api
 */


import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';



@Injectable()
export class DashboardsService {

  // api dashboards endpoint
  private DASHBOARDS_URL = '/dashboards';
  private BULK_FOLDERS_URL = "/bulk/dashboards";

  constructor(private ApiService: ApiService) { }

  /**
   * GET request to folders endpoint on the API
   *
   * @returns {observable<any>} returns subscribable observable with response
   */
  fetchDashboards(): Observable<any[]> {
    return this.ApiService.get(this.DASHBOARDS_URL).pipe(
      map(res => {
        return res.body;
      }));
  }

  /**
   * returns a single dashboard, based on ID
   *
   * @returns {observable<any>} returns subscribable observable with response
   */
  getDashboard(id: any): Observable<any>  {
    return this.ApiService.get(`${this.DASHBOARDS_URL}/${id}`).pipe(
      map(res => {
        return res.body
      }));
  }

  getWidgets(dashboard: any) {
    const widgets = [];

    if (dashboard.layout && dashboard.layout.columns) {
      dashboard.layout.columns.forEach(col => {
        if (col.rows) {
          col.rows.forEach(row => {
            if (row.columns) {
              row.columns.forEach(widget => {
                widgets.push(Object.assign({}, widget));
              })
            }
          })
        }
      })
    }

    return widgets;
  }

  /**
   * PUT request to folders endpoint on the API
   *
   * @param {Array<Object>} dashboards
   * @returns {observable<any>} returns subscribable observable with response
   */
  updateDashboardOrder(dashboards: Array<Object>) {
    return this.ApiService
      .put(this.DASHBOARDS_URL, dashboards).pipe(
      map(res => res.body));
  }

  updateDisplayAttributes(id, displayAttributes) {
    return this.ApiService
      .put(`/dashboards-display-attributes/${id}`, displayAttributes).pipe(
      map(res => res.body));
  }

  listDashboards(): Observable<any[]> {
    return this.ApiService.get('/dashboards-list').pipe(
      map(res => {
        return res.body;
      }));
  }

  createDashboard(dashboard: any): Observable<any>  {
    return this.ApiService
      .post(this.DASHBOARDS_URL, dashboard).pipe(
      map(res => {
        return res.body;
      }));
  }

  updateDashboard(id, data): Observable<any>  {
    return this.ApiService
      .put(`${this.DASHBOARDS_URL}/${id}`, data).pipe(
      map(res => {
        return res.body;
      }));
  }

  bulkDeleteDashboards(data): Observable<any> {
    return this.ApiService
      .delete(`${this.BULK_FOLDERS_URL}`, data).pipe(
      map(res => {
        return res.body;
      }));
  }

  getCustomRender(id: any, filterObj): Promise<any>  {
    return this.ApiService.post(`/dashboards-custom-render/${id}`, filterObj).pipe(
      map(res => {
        return res.body;
      })).toPromise();
  }

  validateRedner(dashboard: any): Observable<any>  {
    return this.ApiService.post('/dashboards-custom-validate', dashboard).pipe(
      map(res => {
        return res.body;
      }));
  }

}
