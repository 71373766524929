<div class="user-widget">
  <!-- Tablet up -->
  <div class="dropdown hidden-xs">
    <button type="button" class="btn btn-default btn-small dropdown-toggle user-widget-button" data-toggle="dropdown"
      [style.background-color]="navigationBarBg"
    >
      <span class="user-icon" [style.color]="navigationBarTextColor" aria-hidden="true"></span>
      <span class="username"
        [style.font-family]="navigationBarFont"
        [style.color]="navigationBarTextColor"
      >
        {{username}}
      </span>
      <span class="caret" [style.color]="navigationBarTextColor"></span>
    </button>

    <ul class="dropdown-menu dropdown-arrow right user-widget-menu" aria-labelledby="userWidgetMenu">
      <li role="separator" class="hidden-sm hidden-md hidden-lg">
        <a>
          <span class="glyphicon glyphicon-user user-icon" aria-hidden="true"></span>
          {{username}}
        </a>
      </li>
      <li role="separator" class="divider hidden-sm hidden-md hidden-lg"></li>
      <li>
        <a (click)="showModal()">User Profile</a>
      </li>
      <li role="separator" class="divider"></li>
      <li>
        <a href="https://docs.google.com/forms/d/e/1FAIpQLSf9OJHTNjYEHfwq7kiSLtdn-HxNAImKsST8kYEg6zyuM3OgDQ/viewform?usp=sf_link" target="_blank">Help</a>
      </li>
      <li role="separator" class="divider"></li>
      <li>
        <a href="#" routerLink="/logout">Logout</a>
      </li>
    </ul>
  </div>

  <!-- Mobile -->
  <!-- if there are client links -->
  <ul *ngIf="hasClientLinks" class="dropdown-menu dropdown-arrow right user-widget-menu hidden-sm hidden-md hidden-lg mobile-user-widget-has-links"
    aria-labelledby="userWidgetMenu">
    <li role="separator" class="hidden-sm hidden-md hidden-lg">
      <a>
        <span class="user-icon" aria-hidden="true"></span>{{username}}
      </a>
    </li>
    <li role="separator" class="divider hidden-sm hidden-md hidden-lg"></li>
    <li>
      <a (click)="showModal()">User Profile</a>
    </li>
  </ul>
  <!-- if there are no client links -->
  <div *ngIf="!hasClientLinks" class="dropdown hidden-sm hidden-md hidden-lg">
    <button type="button" class="btn btn-default btn-small dropdown-toggle" data-toggle="dropdown">
      <span class="user-icon" aria-hidden="true"></span>
      <span class="username">{{username}}</span>
      <span class="caret"></span>
    </button>

    <ul class="dropdown-menu dropdown-arrow right user-widget-menu" aria-labelledby="userWidgetMenu">
      <li role="separator" class="hidden-md hidden-lg">
        <a>
          <span class="user-icon" aria-hidden="true"></span>{{username}}
        </a>
      </li>
      <li role="separator" class="divider hidden-sm hidden-md hidden-lg"></li>
      <li>
        <a (click)="showModal()">User Profile</a>
      </li>
      <li role="separator" class="divider"></li>
      <li>
        <a href="#" routerLink="/logout">Logout</a>
      </li>
    </ul>
  </div>
</div>
