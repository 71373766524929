<div class="login-container">
    <div *ngIf="errorMsg" class="alert alert-danger">
        {{ errorMsg }}
        <br />
        <p>
            Email login issues to <a href="mailto:kyle.woodard@ansira.com">kyle.woodard@ansira.com</a>.
        </p>
        <br />
        <a
            class="help-desk-link"
            href="/login-new?show=yes"
            >Click to go back to login</a>
    </div>
    <div class="footer" *ngIf="errorMsg">
        © 2021 Ansira, All Rights Reserved.
    </div>
</div>