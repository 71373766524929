import * as moment from 'moment-mini';

export class MyDownload {
    // checkbox toggle state
    toggle?: boolean;

    userDisplay?: string;
    clientName?: string;
    dashboardName?: string;
    createdDisplay?: string;
    updatedDisplay?: string;
    fullName?: string;

    constructor(
      public id: number,
      public user: any,
      public client: any,
      public dashboard: any,
      public initPayload: any,
      public jobData: any,
      public complete: number,
      public status: string,
      public downloadLink: string,
      public read: boolean,
      public createdAt: string,
      public updatedAt: string
    ) {
      
      if (user) {
        this.userDisplay = user.email;
        this.fullName = this.getFullName(user);
      }

      if (client) {
        this.clientName = client.name;
      }

      if (dashboard) {
        this.dashboardName = dashboard.name;
      }

      if (this.createdAt) {
        this.createdDisplay = moment.utc(this.createdAt).local().format('MM/DD/YYYY, HH:mm:ss');
      }

      if (this.updatedAt) {
        this.updatedDisplay = moment.utc(this.updatedAt).local().format('MM/DD/YYYY, HH:mm:ss');
      }
      
    }

    getFullName(obj) {
      let fullName = obj.firstName;
  
      if (obj.lastName !== undefined && obj.lastName !== null && obj.lastName !== "") {
        fullName += ' ' + obj.lastName;
      }
  
      return fullName;
    }

}
