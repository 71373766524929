/**
 * Dashboard Folder component
 *
 * @description :: a reusable component that has an input property of folder - displays folder data with related dashboards
 */

import {
  Component,
  Input
} from "@angular/core";
import { EmitterService } from "../../../services/emitter.service";
import { DashboardsService } from "../../../services/dashboards.service";
import { StorageService } from "../../../services/storage.service";
import { GoogleAnalyticsService } from "../../../services/google-analytics.service";

@Component({
  selector: "insighter-dashboard-folder",
  templateUrl: "./dashboard-folder.component.html",
  styleUrls: ["./dashboard-folder.component.scss"]
})
export class DashboardFolderComponent {
  public isCollapsed: boolean = false;
  public loading: boolean = true;
  public options: any;
  public userRole: string;

  private _folder: any;

  get folder(): any {
    return this._folder;
  }

  @Input()
  set folder(f: any) {
    this._folder = f;

    if (f.dashboards) {
      this.orderDashboards();
      this.loaded();
    } else {
      this.loaded();
    }
  }


  @Input() parentCollapsed: boolean = false;
  @Input() isChild: boolean = false;

  constructor(
    private DashboardsService: DashboardsService,
    private StorageService: StorageService,
    private GoogleAnalyticsService: GoogleAnalyticsService
  ) {
    // determine user's role
    this.userRole = JSON.parse(this.StorageService.getItem("currentUser")).role;

    this.options = {
      group: 'test',
      onUpdate: (event: any) => {
        //console.log('folder update')
        this.reorderDashboards();
      },
      onMove: (event: any, originalEvent: any) => {        

        if (
          (event.from.className.indexOf('dashboards') !== -1 && event.to.className.indexOf('folder-container') !== -1) ||
          (event.from.className.indexOf('folder-container') !== -1 && event.to.className.indexOf('dashboards') !== -1) ||
          (event.from.className.indexOf('folder-container') !== -1 && event.to.className.indexOf('parent-sidebar') !== -1) ||
          (event.from.className.indexOf('empty') !== -1)
          ) {
          //console.log('preventing move')
          return false;
        }

        //console.warn('folder moved', event);

        return event.willInsertAfter ? 1 : -1;
      },
      onAdd: (event: any) => {
        //console.log('folder added', event);

        //console.log(this.folder);

        this.reorderDashboards();
      },
      onRemove: (event: any) => {
        //console.log('folder removed', event);
      },
    };
  }

  /**
   * folder collapse/expand functionality
   *
   * @param   {any} event
   * @returns {void}
   */
  toggleFolder(event): void {
    const name = this.isCollapsed ? "dashboard_folder_expand" : "dashboard_folder_collapse";

    this.GoogleAnalyticsService.send('event', name,  {
      event_category: "action",
      event_label: `pageId-0_widgetId-0`
    });

    event.preventDefault();
    this.isCollapsed = !this.isCollapsed;
  }

  /**
   * sets the loading property to false, hides loader
   *
   * @returns {void}
   */
  loaded(): void {
    this.loading = false;
  }

  /**
   * assigns an order number to each folder
   * if the current order is 0
   */
  orderDashboards() {
    if (this.folder.dashboards) {
      this.folder.dashboards.map(dashboard => {
        if (dashboard.order === 0) {
          dashboard.order = this.folder.dashboards.indexOf(dashboard) + 1;
        }
      });
    }
    
  }
  /**
   * reorders folders based on drag
   * and drop user interaction
   */
  reorderDashboards() {
    this.folder.dashboards.map(dashboard => {
      //make sure the dashboard is in this folder
      dashboard.folder = this.folder.id;

      dashboard.order = this.folder.dashboards.indexOf(dashboard) + 1;
    });

    this.DashboardsService.updateDashboardOrder(
      this.folder.dashboards
    ).subscribe(response => console.log("from api", response));
  }
}
