/**
 * Folders Service
 *
 * @description :: used to fetch folders from the api
 */


import {map} from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { Observable } from "rxjs";

@Injectable()
export class FoldersService {
  // api folders endpoint
  private FOLDERS_URL = "/folders";
  private BULK_FOLDERS_URL = "/bulk/folders";

  constructor(private ApiService: ApiService) {}

  /**
   * GET request to folders endpoint on the API
   *
   * @param   {string} sort
   * @returns {observable<any>} returns subscribable observable with response
   */
  fetchFolders(sort: string = "order asc"): Observable<any> {
    return this.ApiService.get(`${this.FOLDERS_URL}/?sort=${sort}`).pipe(map(res => {
      return res.body;
    }));
  }

  /**
   * PUT request to folders endpoint on the API
   *
   * @param {Array<Object>} folders
   * @returns {observable<any>} returns subscribable observable with response
   */
  updateFolderOrder(folders) {
    return this.ApiService.put(this.FOLDERS_URL, folders).pipe(map(res =>
      res.body
    ));
  }

  getFolder(id: any): Observable<any>  {
    
    return this.ApiService
      .get(`${this.FOLDERS_URL}/${id}`).pipe(
      map(res => {
        return res.body;
      }));
  }

  createFolder(folder: any): Observable<any>  {
    
    return this.ApiService
      .post(this.FOLDERS_URL, folder).pipe(
      map(res => {
        return res.body;
      }));
  }

  updateFolderName(data): Observable<any>  {
    return this.ApiService
      .put(`/folders-name/${data.id}`, data).pipe(
      map(res => {
        return res.body;
      }));
  }

  bulkDeleteFolders(data): Observable<any> {
    return this.ApiService
      .delete(`${this.BULK_FOLDERS_URL}`, data).pipe(
      map(res => {
        return res.body;
      }));
  }

}
