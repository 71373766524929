import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { AppService } from '../services/app.service';

@Component({
  selector: 'insighter-external-auth',
  template: ''
})
export class LoginExternalComponent {
  constructor(
    private ActivatedRoute: ActivatedRoute,
    private AuthService: AuthService,
    private Router: Router,
    private AppService: AppService
  ) {
    this.ActivatedRoute.params.subscribe(params => {
      // Navigate to dashboards or back to login if auth failed
      AuthService
        .authenticateApplication(params.token)
        .then(() => {
          this.Router.navigate(params.dashboardId ? [`dashboards/${params.dashboardId}`] : ['/dashboards'])
        })
        .catch(() => this.Router.navigate(['/logout'], { queryParams: { expired: true, isEmbedded: AppService.appIsEmbedded } }));
    });
  }
}
