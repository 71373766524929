/**
 * Confirmation Modal Component
 *
 * @description :: Wraps ng2-table component to provide additional functionality & cleaner markup
 *                 Adds features such as checkboxes & selectAll, sorting per column, & additional
 *                 events.
 *
 * @example ::  usage is simple, add this component as a ViewChild in the parent component & implement
 *              two methods - one method to handle setting the message & displaying the modal & the second
 *              method to handle the users choice of cancel or save. Example:
 *
 *              @ViewChild(ConfirmationComponent) public confirmationModal: ConfirmationComponent;
 *
 *              public show() {
 *                this.confirmationModal.message = 'Are you sure you wish to perform this action?';
 *                this.confirmationModal.showModal();
 *              }
 *
 *              public confirm(e): boolean {
 *                console.log('confirmed', e);
 *                return false;
 *              }
 *
 *              Template usage is also straightforward, ensure you have the component declared in the markup
 *              with a (confirm) event & then invoke show() with a click or toggle.
 *
 *              <a (click)="show()">Confirmation</a>
 *              <insighter-confirmation-modal (confirm)="confirm($event)"></insighter-confirmation-modal>
 */


import { Component, OnInit, Input, Output, ViewChild, EventEmitter } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  selector: 'insighter-confirmation-modal',
  templateUrl: './confirmation.component.html'
})
export class ConfirmationComponent implements OnInit {

  @ViewChild('autoShownModal') public autoShownModal: ModalDirective;     // view child of ng2-bootstrap modal

  @Input() public message: string = '';                                   // input message to display in modal
  @Input() public title: string = '';

  @Output() public confirm: EventEmitter<boolean> = new EventEmitter();   // event to emit to indicate users choice

  public isVisible: boolean = false;                                      // visibility indicator

  constructor() { }

  ngOnInit() { }

  /**
   * Shows the modal by toggling `isVisible` to true; Optionally
   *  accepts the title & message of the modal to set as a shortcut
   * to setting the class properties separately.
   *
   * @param   {string} title
   * @param   {string} message
   * @returns {void}
   */
  public showModal(title?: string, message?: string): void {
    // shortcut to set the title & message through this method if provided
    if (title && message) {
      this.title = title;
      this.message = message;
    }

    this.isVisible = true;
  }

  /**
   *
   * @returns {void}
   */
  public hideModal(): void {
    this.autoShownModal.hide();
  }

  /**
   *
   * @returns {void}
   */
  public onHidden(): void {
    this.isVisible = false;
  }

  /**
   *
   * @param   {boolean} didSave
   * @returns {boolean}
   */
  public onConfirm(didSave: boolean): boolean {
    // pass the event up to parent to indicate user confirmed action
    this.confirm.emit(didSave);

    // hide the modal
    this.isVisible = false;

    return false;
  }
}
