/**
 * Auth Service
 *
 * @description :: authenticates user to api based on login credentials
 */


import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable ,  BehaviorSubject } from 'rxjs';
import { environment } from '../../environments/environment';
import { EmitterService } from './emitter.service';
import { StorageService } from './storage.service';
import { GoogleAnalyticsService } from './google-analytics.service';
import { EventLogService } from './event-log.service';
import { HttpHeaders } from '@angular/common/http';
import { OktaAuth } from '@okta/okta-auth-js';

const sampleConfig = environment.okta;

declare let $: any;
declare let FS: any;

const MEDIA_AUTO_PRIVILEGES = [
  'media_automation_all',
  'media_automation_bulk_processing',
  'media_automation_jobs_log',
  'media_automation_playbooks',
  'media_automation_gateway'
]

const DATA_WAREHOUSE_PRIVILEGES = [
  'data_warehouse_all',
  'data_warehouse_crud'
]

const CLIENT_ADMIN_PRIVILEGES = [
  'client_admin_all',
  'client_admin_permissions',
  'client_admin_partitions',
  'client_admin_budgets',
  'client_admin_media_automation'
]

const ADMIN_PRIVILEGES = [
  'super_admin',
  'admin'
]


@Injectable()
export class AuthService {

  private AUTH_URL = '/authenticate';
  private AUTH_APP_URL = '/authenticate/application';
  private PASSWORD_RESET_URL = '/users/reset-password';
  private AUTH_PDF_URL = '/authenticate/pdf';
  private SISENSE_LOGOUT_URL = environment.sisense.logoutUrl
  private AUTH_OKTA_URL = '/authenticate/oktaLogin';

  public isAuthenticated: boolean;
  public authenticationState: BehaviorSubject<boolean>;
  public token: any;

  constructor(private ApiService: ApiService,
              private StorageService: StorageService,
              private GoogleAnalyticsService: GoogleAnalyticsService,
              private EventLogService: EventLogService
              ) {
    this.isAuthenticated = !!this.StorageService.getItem('currentUser');
    this.authenticationState = new BehaviorSubject<boolean>(this.isAuthenticated);
  }

  fsIdentify(data) {
    if ((<any>window).FS) {
      FS.identify(data.id, {
        displayName: data.firstName + " " + data.lastName,
        email: data.email
      });
    }
    
  }

  /**
   * Sends user credentials to api for user authentication
   *
   * @param {string} email - user email
   * @param {string} password - user password
   * @returns {Promise<boolean>} returns promise from the http call
   */
  authenticateUser(email, password): Observable<any> {
    let options = { withCredentials: true };

    return this.ApiService
      .post(this.AUTH_URL, {email: email, password: password}, options).pipe(
      map(
        response => {
          let data = response.body;
          console.log(data);

          // login is successful if jwt is in the response
          if (!data.hasOwnProperty('token')) {
            // return false to indicate failed login
            return false;
          }

          // broadcast the user's id to listeners including the user service
          EmitterService.get('auth.user.id').emit(data.id);

          this.GoogleAnalyticsService.send('event', 'login', {
            'event_category': 'action',
            'event_label': `pageId-0_widgetId-0`,
            'UserId': data.email,
          });

          // store username and jwt token in local storage to keep user logged in
          this.StorageService.set(
            'currentUser', {
              firstName: data.firstName,
              lastName: data.lastName,
              id: data.id,
              user: data.email,
              role: data.role,
              roles: data.roles,
              privileges: data.privileges,
              token: data.token,
              isActive: data.isActive,
              metaData: data.metaData,
              pageRestrictions: data.pageRestrictions
          });

          this.EventLogService.logEvent({
            'event': 'LOGIN'
          }).subscribe(() => {}, e => {console.log(e)})

          this.fsIdentify(data);

          // Update the current authentication state
          this.setAuthState(true);

          return true;
        },
        error => {
          this.GoogleAnalyticsService.send('event', 'login_failed', {
            'event_category': 'error',
            'event_label': `pageId-0_widgetId-0 ${error}`,
            'UserId': 0
          });

          return false;
        }
      ));
  }

  /**
   * GET request to users endpoint on the API. Takes
   *  pagination params `perPage` & `pageNumber` and
   * sets reasonable defaults on them.
   *
   * @todo awaiting API implementation
   *
   * @param   {number} userId      id of currently logged in user
   * @returns {observable<any[]>}  returns subscribed observable with
   *                                 an array of data in response
   */
  verifyPrivileges(userId): Observable<any[]> {
    return this.ApiService
    .get(`users/${userId}/privileges`).pipe(
    map(res => {
      // store the total count of users
      return res.body;
    }));
  }


  /**
   * Logs the current user out; Logs out of sisense service
   *  and destroys the local storage entry for the current
   * user
   *
   * Retrieves user details from API given a valid web token
   *
   * @param {string} token - JWT token
   * @returns {Promise<boolean>} returns promise from the http call
   */
  authenticateApplication(token): Promise<boolean> {
    let options = { withCredentials: true };

    return this.ApiService.post(`${this.AUTH_APP_URL}/${token}`, {}, options)
      .toPromise()
      .then(response => {
        let data = response.body;

        // login is successful if jwt is in the response
        if (!data.hasOwnProperty('token')) {
          // return false to indicate failed login
          return false;
        }

        // broadcast the username to any listening components
        EmitterService.get('auth.user.id').emit(data.id);

        this.GoogleAnalyticsService.send('event', 'sso_login', {
          'event_category': 'action',
          'event_label': `pageId-0_widgetId-0`,
          'UserId': data.email,
        });

        this.fsIdentify(data);

        // store username and jwt token in local storage to keep user logged in
        this.StorageService.set(
          'currentUser', {
            firstName: data.firstName,
            lastName: data.lastName,
            id: data.id,
            user: data.email,
            role: data.role,
            roles: data.roles,
            privileges: data.privileges,
            token: data.token,
            isActive: data.isActive,
            pageRestrictions: data.pageRestrictions
          }
        );

        this.EventLogService.logEvent({
          'event': 'SSO LOGIN'
        }).subscribe(() => {}, e => {console.log(e)})

        // Update the current authentication state
        this.setAuthState(true);

        return true;
      });
  }

  /**
   * Submits a password reset request to the API
   *
   * @param {string} email - user email
   * @returns {Promise<boolean>} returns promise from the http call
   */
  resetPassword(email): Promise<any> {
    let options = { withCredentials: true };
    return this.ApiService.post(this.PASSWORD_RESET_URL, { email }, options)
      .toPromise()
      .then(response => response.body);
  }

  /**
   * Validates a password reset submission
   *
   * @param {string} password - New password
   * @param {string} token - Validation token
   *
   * @returns {Promise<boolean>} returns promise from the http call
   */
  validateResetPassword(password, token): Promise<any> {
    let options = { withCredentials: true };
    return this.ApiService.post(`${this.PASSWORD_RESET_URL}/${token}`, { password }, options)
      .toPromise()
      .then(response => response.body);
  }

  /**
   * Logs out the current user
   *
   * @returns {Object<Promise>}
   *
   */
  logout(isOkta) {
    if (isOkta) {
      const authClient = new OktaAuth({
        issuer: sampleConfig.issuer,
        clientId: sampleConfig.clientId,
        redirectUri: sampleConfig.redirectUri,
        postLogoutRedirectUri: `${window.location.origin}/login-new?show=yes`
      });
  
      authClient.signOut()
        .catch( e => {
          console.log(e);
        })
    }

    // clear all data from browser storage on logout
    this.StorageService.removeAll();

    // request to sisense logout link to log user out of the sisense application
    // set headers for logout request
    //let options = { withCredentials: true };

    // Update the current authentication state
    this.setAuthState(false);

    // remove Sisense application cookies
    // @todo add method to sisense service that logs out instead of making direct
    // @todo HTTP call from the auth service to perform logout of sisense.
    // return this.Http.get(this.SISENSE_LOGOUT_URL, options)
    //   .toPromise()
    //   .catch(error => console.error(error));

    //remove SSO coookie
    //document.cookie = "sisenseAccount=;expires=Thu, 01 Jan 1970 00:00:00 GMT; domain=.ansira.io";
    
    //remove Sisense SOO cookies
    //$('#sisenseLogoutIframe').attr('src', this.SISENSE_LOGOUT_URL);
  }

  /**
  * Checks local storage `currentUser` object for `isAdmin`
  * property to determine the state of certain admin
  * components like the sidebar..
  *
  * @returns {boolean} true if user's role contains word 'admin'
  */
  isAdmin(): boolean {
    let userRole = this.StorageService.get('currentUser').role;

    if (userRole) {
      return userRole.toLowerCase().includes('admin') || false;
    }

    return false;
  }

  /**
   * Checks local storage 'currentUser' object and returns
   *  the role string for the currently logged in user's role
   *
   * @returns {string} should return 'admin', 'superAdmin', or 'viewer'
   */
  getRole(): string {
    return this.StorageService.get('currentUser').role;
  }

  isSuperAdmin(): boolean {
    return this.StorageService.get('currentUser').role === 'superAdmin';
  }

  /**
   * Updates the current authentication state and broadcasts the
   * new value to all subscribed components
   *
   * @param {string} state - New authentication state
   * @returns
   */
  setAuthState(state: boolean) {
    this.authenticationState.next(this.isAuthenticated = state);
  }

  hasPrivilege(privilege): boolean {
    const privileges = this.StorageService.get('currentUser').privileges;

    if (privileges && privileges.length) {
      return privileges.indexOf(privilege) !== -1;
    }

    return false;
  }

  hasMediaAutomationPrivileges(): boolean {
    const roles = this.StorageService.get('currentUser').roles;

    if (roles) {
      const foundRoles = roles.filter(f => {
        return f.name === 'Media Automation Administrator' && f.isActive === true
      })

      if (foundRoles && foundRoles.length) {
        return true;
      }

      const privileges = this.StorageService.get('currentUser').privileges;
      if (privileges && privileges.length) {
        return privileges.some(r => MEDIA_AUTO_PRIVILEGES.includes(r));
      } 
      
    }

    return false;
  }

  getMediaAutoPrivileges(): Array<string> {
    const privileges = this.StorageService.get('currentUser').privileges;
    if (privileges && privileges.length) {
      return privileges.filter(r => MEDIA_AUTO_PRIVILEGES.includes(r));
    } 

    return []
  }

  hasDataWarehousePrivileges(): boolean {
    const roles = this.StorageService.get('currentUser').roles;

    if (roles) {
      const foundRoles = roles.filter(f => {
        return f.name === 'Data Warehouse Administrator' && f.isActive === true
      })

      if (foundRoles && foundRoles.length) {
        return true;
      }

      const privileges = this.StorageService.get('currentUser').privileges;
      if (privileges && privileges.length) {
        return privileges.some(r => DATA_WAREHOUSE_PRIVILEGES.includes(r));
      } 
    }

    return false;
  }

  getDataWarehousePrivileges(): Array<string> {
    const privileges = this.StorageService.get('currentUser').privileges;
    if (privileges && privileges.length) {
      return privileges.filter(r => DATA_WAREHOUSE_PRIVILEGES.includes(r));
    } 

    return []
  }

  hasAdminPrivileges(): boolean {
    const privileges = this.StorageService.get('currentUser').privileges;

    if (privileges && privileges.length) {
      return privileges.some(r => ADMIN_PRIVILEGES.includes(r));
    }
    return false;
  }

  hasClientAdminPrivileges(): boolean {
    const hasAdminPrivileges = this.hasAdminPrivileges();
    if (hasAdminPrivileges) {
      return hasAdminPrivileges;
    }

    const privileges = this.StorageService.get('currentUser').privileges;
    if (privileges && privileges.length) {
      return privileges.some(r => CLIENT_ADMIN_PRIVILEGES.includes(r));
    } 

    return false;
  }

  getClientAdminPrivileges(): Array<string> {
    const privileges = this.StorageService.get('currentUser').privileges;
    if (privileges && privileges.length) {
      return privileges.filter(r => CLIENT_ADMIN_PRIVILEGES.includes(r));
    } 

    return []
  }

  hasAccessToAllLocations(): boolean {
    const hasAdminPrivileges = this.hasAdminPrivileges();
    if (hasAdminPrivileges) {
      return hasAdminPrivileges;
    }

    const privileges = this.StorageService.get('currentUser').privileges;
    if (privileges && privileges.length) {
      return privileges.includes('client_admin_all');
    } 
    
    return false;
  }

  authenticatePDF(token): Promise<boolean> {
    let options = { withCredentials: true };

    return this.ApiService.post(`${this.AUTH_PDF_URL}/${token}`, {}, options)
      .toPromise()
      .then(response => {
        let data = response.body;

        // login is successful if jwt is in the response
        if (!data.hasOwnProperty('token')) {
          // return false to indicate failed login
          return false;
        }

        // broadcast the username to any listening components
        EmitterService.get('auth.user.id').emit(data.id);

        this.fsIdentify(data);

        // store username and jwt token in local storage to keep user logged in
        this.StorageService.set(
          'currentUser', {
            firstName: data.firstName,
            lastName: data.lastName,
            id: data.id,
            user: data.email,
            role: data.role,
            roles: data.roles,
            privileges: data.privileges,
            token: data.token,
            isActive: data.isActive,
            pageRestrictions: data.pageRestrictions
          }
        );

        // Update the current authentication state
        this.setAuthState(true);

        return true;
      });
  }

  getDistributorLoginLink(distributorUserId): Promise<string> {

    return this.ApiService.post('/authenticate/encrypt', {
      payload: distributorUserId
    })
      .toPromise()
      .then(response => {
        let json = response.body;
        let encrypted = json['encrypted'];

        return this.ApiService.post('/authenticate/application', {
          payload: encrypted
        }).toPromise()
      })
      .then(response => {
        let json = response.body;
        let token = json['token'];

        return `https://insighter.ansira.io/application/login/${token}`;
      })
  }

  getNonDistributorLoginLink(email): Promise<string> {

    return this.ApiService.post('/authenticate/encrypt', {
      payload: JSON.stringify({
        email: email
      })
    })
      .toPromise()
      .then(response => {
        console.log(response)
        let json = response.body;
        let encrypted = json['encrypted'];

        return this.ApiService.post('/authenticate/application-nonedistributor', {
          payload: encrypted
        }).toPromise()
      })
      .then(response => {
        console.log(response);
        let json = response.body;
        let token = json['token'];

        return `https://insighter.ansira.io/user/login/${token}`;
      })
  }

  authenticateOktaUser(email, oktaAccessToken): Observable<any> {
    let options = { withCredentials: true };

    let headers = new HttpHeaders();
    headers = headers.set('Authorization', 'Bearer ' + oktaAccessToken);
    options['headers'] = headers;

    return this.ApiService
      .post(this.AUTH_OKTA_URL, {email: email}, options).pipe(
      map(
        response => {
          let data = response.body;
          console.log(data);

          // login is successful if jwt is in the response
          if (!data.hasOwnProperty('token')) {
            // return false to indicate failed login
            return false;
          }

          // broadcast the user's id to listeners including the user service
          EmitterService.get('auth.user.id').emit(data.id);

          this.GoogleAnalyticsService.send('event', 'login', {
            'event_category': 'action',
            'event_label': `pageId-0_widgetId-0`,
            'UserId': data.email,
          });

          // store username and jwt token in local storage to keep user logged in
          this.StorageService.set(
            'currentUser', {
              firstName: data.firstName,
              lastName: data.lastName,
              id: data.id,
              user: data.email,
              role: data.role,
              roles: data.roles,
              privileges: data.privileges,
              token: data.token,
              isActive: data.isActive,
              metaData: data.metaData,
              pageRestrictions: data.pageRestrictions
          });

          this.fsIdentify(data);

          this.EventLogService.logEvent({
            'event': 'OKTA_LOGIN'
          }).subscribe(() => {}, e => {console.log(e)})

          // Update the current authentication state
          this.setAuthState(true);

          return true;
        },
        error => {
          this.GoogleAnalyticsService.send('event', 'login_failed', {
            'event_category': 'error',
            'event_label': `pageId-0_widgetId-0 ${error}`,
            'UserId': 0
          });

          return false;
        }
      ));
  }
}
