import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { EmitterService } from "../../services/emitter.service";
import { ClientsService } from "../../services/client.service";
import { ClientRedirectService } from "../../services/clientredirect.service";

@Component({
  selector: "insighter-client-dropdown",
  templateUrl: "./client-dropdown.component.html",
  styleUrls: ["./client-dropdown.component.scss"]
})
export class ClientDropdownComponent implements OnInit {
  @Input() clients: any[];
  @Output() client: EventEmitter<any> = new EventEmitter();

  @Input() shouldShowWithOnlyOne?: boolean = false;
  @Input() shouldDoSubdomainRedirects?: boolean = false;
  @Input() shouldHideDashboards?: boolean = false;

  public defaultClient: any;

  constructor(
    private ClientsService: ClientsService,
    private ClientRedirectService: ClientRedirectService
  ) {}

  ngOnInit() {
    if (this.shouldHideDashboards) {
      this.clients = this.clients.filter(i => !i.hideDashboards);
    }
    
    // find the client that matches the id from store & set default
    const clientId = this.ClientsService.getClientId();
    this.defaultClient = this.clients.find(client => client.id == clientId);
    // prevent infinite loop - use first client if the client no longer exists
    if (!this.defaultClient && this.clients.length) {
      this.defaultClient = this.clients[0];
      this.ClientsService.updateClientId(this.defaultClient.id);

      if (this.shouldHideDashboards && this.defaultClient) {
        this.selectClient(this.defaultClient);
        return;
      }
    }
    if (!this.defaultClient) {
      this.ClientsService.removeClientId();
      return;
    }
    // redirect to you have no dashboards
    this.emitClientLinks(this.defaultClient);
  }

  /**
   * Updates various services and emits client details given a client object
   *
   * @param {Object} client - Client object
   *
   * @returns {void}
   */
  selectClient(client) {
    // update client id in local store & service
    this.ClientsService.updateClientId(client.id);

    if (this.shouldDoSubdomainRedirects) {
      
      if (this.ClientRedirectService.shouldRedirect(client)) {
        EmitterService.get('spinner.toggle').emit(true);
        this.ClientRedirectService.doRedirect(client);
        return;
      }
    } 

     // emit only client id up to parent component
     this.client.emit(client.id);

     // broadcast client change `id` to the app so that other components can react to change
     this.emitClientLinks(client);
  }

  /**
   * Emit various client details
   *
   * @param {Object} client - Client object
   *
   * @returns {void}
   */
  emitClientLinks(client) {
    EmitterService.get("client.changed").emit(client.id);
    EmitterService.get("client.links.changed").emit(client.links);
    setTimeout(() => {
      EmitterService.get("client.services.changed").emit(client.services);
    });

    setTimeout(() => {
      EmitterService.get("sidebar.hidden").emit(client.clientDisplayAttributes ? client.clientDisplayAttributes.disableLeftNavigationBar : false);
    })
  }
}
