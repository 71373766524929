import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from './api.service';
import { EmitterService } from './emitter.service';
import { BehaviorSubject } from 'rxjs';
import { $log } from './logger.service';
import { Globals } from '../globals';

@Injectable()
export class AppService {

  public appIsEmbedded: boolean;
  public isMobile: BehaviorSubject<boolean>;
  public myDownloadsEnabled: boolean = false;
  public insighterMyDownloadsEnabled: boolean = false;

  constructor(
    private ApiService: ApiService,
    private router: Router
  ) {
    this.checkEmbed();

    // Assign observable which other components may subscribe to
    this.isMobile = new BehaviorSubject<boolean>(this.checkIsMobile());

    // Update the `isMobile` observable on resize
    window.addEventListener('resize', () => this.setIsMobile());

    // subscribe to api error responses (observable) to handle some error codes generically
    this.ApiService.error
      .subscribe(err => {
        switch (err.status) {
          case 401:
            // Delegate to the login component
            break;
          case 403:
            // Intercept 403 responses
            this.router.navigate(['/logout'], { queryParams: { expired: true, isEmbedded: this.appIsEmbedded } });
            break;
          case 404:
            Globals.routeNotFound = window.location.pathname.replace(/\D/g, '');

            this.router.navigate(['/404']);
            break;
          default:
            // Unknown error
            $log.error(err);
            break;
        }
      });
  }

  /**
   * Determine if the application is instantiated on mobile or tablet and above
   *
   */
  setIsMobile() {
    this.isMobile.next(this.checkIsMobile());
  }

  checkIsMobile(): boolean {
    return window.innerWidth < 768;
  }

  /**
   * checks to see if the app is being embedded in an iframe
   * @return void
   */
  checkEmbed() {
    let body = document.getElementById('body');

    if (body) {
      // check to see if the app window is the top level window, if it isn't then it is embedded
      if (window.self !== window.top) {
        this.appIsEmbedded = true;
        body.classList.add('is-embedded');
      } else {
        this.appIsEmbedded = false;
        body.classList.remove('is-embedded');
      }
    }
    
  }

  setMyDownloadsEnabled(value: boolean): void {
    this.myDownloadsEnabled = value;
  }

  getMyDownloadsEnabled(): boolean {
    return this.myDownloadsEnabled;
  }

  setInsighterMyDownloadsEnabled(value: boolean): void {
    this.insighterMyDownloadsEnabled = value;
  }

  getInsighterMyDownloadsEnabled(): boolean {
    return this.insighterMyDownloadsEnabled;
  }

}
