
import {takeWhile} from 'rxjs/operators';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute }   from '@angular/router';
import { AuthService } from '../services/auth.service';
import { StorageService } from '../services/storage.service';

@Component({
  selector: 'insighter-logout',
  templateUrl: 'logout.component.html',
  styleUrls: ['logout.component.scss']
})
export class LogoutComponent implements OnInit, OnDestroy {

  public message: string = '';      // message to present to user to provide reason on forced logout's
  public showLink: boolean = true;  // Determine if login page link should be visible
  private isAlive: boolean = true;  // Manage state of observable subscriptions

  public isOkta: boolean = false;

  constructor(public AuthService: AuthService,
              private StorageService: StorageService,
              private route: ActivatedRoute) { }

  ngOnInit() {
    // use ActivatedRoute to get possible query params from the url
    this.route.queryParams.pipe(
      takeWhile(() => this.isAlive))
      .subscribe(params => {
        let isExpired = (params.expired && params.expired === 'true'),
          isEmbedded = (params.isEmbedded && params.isEmbedded === 'true');

        // The login page link should be suppressed when an embedded view is delivered
        this.showLink = !isEmbedded;

        // set the logout message according to query params
        if (isExpired) {
          if (isEmbedded) {
            this.message = 'Your session has expired, please reload the reporting interface.'
          } else {
            this.message = 'Your session has expired, please re-authenticate at the login screen.';
          }
        } else {
          this.message = 'You have successfully logged out!';
        }
      });

    this.isOkta = !!this.StorageService.getItem('okta-token-storage');

    this.AuthService.logout(this.isOkta);
  }

  ngOnDestroy() {
    // clean up observables
    this.isAlive = false;
  }

  /**
   * Redirect to the login page
   */
  redirect() {

    if (this.isOkta) {
      window.location.assign('/login-new?show=yes');
    } else {
      window.location.assign('/login');
    }
    
    return false;
  }

}
