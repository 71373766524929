<insighter-client-dropdown [hidden]="!clients[0]?.id" [clients]="clients" (client)="buildNavigation($event)" shouldDoSubdomainRedirects="true" shouldHideDashboards="true"></insighter-client-dropdown>

<div class="folder-container parent-sidebar" [class.no-clients-dropdown]="clients?.length <= 1"  *ngIf="userRole === 'superAdmin'" [sortablejs]="folders" [sortablejsOptions]="options">
  <div *ngFor="let folder of folders">
    <insighter-dashboard-folder [folder]="folder"></insighter-dashboard-folder>
  </div>
</div>

<div class="folder-container parent-sidebar" [class.no-clients-dropdown]="clients?.length <= 1" *ngIf="userRole !== 'superAdmin'">
  <div *ngFor="let folder of folders">
    <!-- render the insighter-dashboard-folder component, passing in a folder -->
    <insighter-dashboard-folder [folder]="folder"></insighter-dashboard-folder>
  </div>
</div>
