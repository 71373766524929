/**
 * Pluralize Pipe
 *
 * @description :: This is a simple pluralize component unlike all other ng2 plural type
 *                 pipes that take into account the number to determine pluralization..
 *                 e.g. 0 = none, 1 = none, 2 = plural et al... This pipe simply checks
 *                 if the end of the string has an 's' and if not then add's one to make
 *                 it plural. Its intended use is for client metadata names like "stores"
 *                 or "region" - with the former being fine in a sidebar or title such as
 *                 "View Stores" but the latter would look like "View Region" when it should
 *                 be plural. There will be edge cases if this is used for many purposes
 *                 outside of the original intent.
 *
 * @example :: in your template simply use the pipe like so: {{ title | pluralize }}
 */

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'pluralize'})
export class PluralizePipe implements PipeTransform {

  transform(value: string, args?: any): string {
    if (!value || value.charAt(value.length - 1) === 's') {
      return value;
    }

    if (value.charAt(value.length - 1) !== 's') {
      // return pluralized string
      value = `${value}s`;
    }

    return value;
  }
}
