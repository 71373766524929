/**
 * @description :: template helper for sanitization of markup. It also
 *  works well for view-model type scenarios when markup could be
 * attached directly to a data object (as a property of said object)
 *  and you still want to render safe, functional html
 *
 * @example :: {{ someUnsafeVariable | safeHtml }}
 */
import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({name: 'safeHtml'})
export class SafeHtmlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) { }

  transform(style) {
    return this.sanitizer.bypassSecurityTrustHtml(style);
  }
}
