import { Injectable } from '@angular/core';

import { UsersService } from './users.service';
import { ClientsService } from './client.service';

@Injectable()
export class ClientResolverService {

    constructor(private UsersService: UsersService,
        private ClientsService: ClientsService) {}

    fetchUserClients(): Promise<any>  {
        return new Promise((resolve, reject) => {
            this.UsersService
              .fetchUserClients()
              .subscribe(
                clients => {
                  // in case only one client wrap in array
                  clients = clients instanceof Array ? clients : [clients];
      
                  // using the client service & store given client in localstorage
                  this.ClientsService.setClientId(clients);
      
                  // resolve the promise with the clients data
                  resolve(clients);
                },
                err => {
                  reject(err);
                }
              )
          });

    }

}
