
import {takeWhile} from 'rxjs/operators';
import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router }   from '@angular/router';
import { AuthService } from '../services/auth.service';


@Component({
  selector: 'insighter-forgot-password-validate',
  templateUrl: './forgot-password-validate.component.html',
  styleUrls: ['./forgot-password-validate.component.scss']
})
export class ForgotPasswordValidateComponent implements OnDestroy {
  public password: string;
  public confirmedPassword: string;
  public formSuccess: string;
  public formError: string;
  private token: string;

  // Utilized to manage observable subscriptions
  private isAlive: boolean = true;

  constructor(
    private AuthService: AuthService,
    private ActivatedRoute: ActivatedRoute,
    private Router: Router
  ) {
    this.ActivatedRoute.params.pipe(
      takeWhile(() => this.isAlive))
      .subscribe(params => this.token = params.token);
  }

  ngOnDestroy() {
    // cleanup subscriptions
    this.isAlive = false;
  }

  /**
   * Reset user's password within the API
   *
   * @param {string} password - New password
   * @param {string} confirmedPassword - Confirmed password
   *
   */
  resetPassword(password, confirmedPassword) {
    this.formSuccess = null;
    this.formError = null;

    if (password !== confirmedPassword) {
      return this.formError = 'Passwords do not match';
    }

    return this.AuthService.validateResetPassword(password, this.token)
      .then(user => {
        this.AuthService.authenticateUser(user.email, password)
          .subscribe(() => {
            // Process successful login
            this.formSuccess = 'Password successfully reset.  Logging in...';
            this.Router.navigate(['/dashboards']);
          }, () => {
            // Handle errors from the authentication process
            this.formError = 'Authentication failed.  Please return to the login page and try to login with your new credentials';
          })
      })
      .catch(() => {
        // Handle errors related to updating the usere's new password.  Mostly likely token related.
        this.formError = 'Password reset token has expired.  Please resubmit a password reset request.';
      });
  }

}
