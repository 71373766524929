/**
 * Okta based login using 
 * 
 * OktaAuthService:  https://github.com/okta/okta-angular
 * OktaSignIn Widget:  https://github.com/okta/okta-signin-widget
 * 
 * Calls Our Backend that is secured by: https://github.com/okta/okta-oidc-js/tree/master/packages/jwt-verifier
 * 
 * See Angular example:  https://github.com/okta/samples-js-angular/tree/master/custom-login
 * See Node.js example:  https://github.com/okta/samples-nodejs-express-4/tree/master/resource-server
 * 
 * This does
 * OAuth 2.0 Authorization Code Flow with Proof Key for Code Exchange (PKCE)
 * 
 * https://github.com/okta/samples-js-angular/blob/master/custom-login/src/app/login/login.component.ts
 */
import { Component, OnInit, OnDestroy } from '@angular/core';
import * as OktaSignIn from '@okta/okta-signin-widget';
import {environment} from '../../environments/environment';
import { AuthService } from "../services/auth.service";
import { EmitterService } from "../services/emitter.service";


const DEFAULT_ORIGINAL_URI = window.location.origin;

const sampleConfig = environment.okta;


function isExternallyRedirected(field) {
  let url = window.location.href;
  if (url.indexOf('?' + field + '=') !== -1) {
    return true;
  } else if (url.indexOf('&' + field + '=') !== -1) {
    return true;
  }
    
  return false
}

@Component({
  selector: 'insighter-login-okta',
  templateUrl: './login-okta.component.html',
  styleUrls: ['./login-okta.component.scss']
})
// tslint:disable-next-line:component-class-suffix
export class LoginOtkaComponentIDPNoCookies implements OnInit, OnDestroy {
  public errorMsg: string = null;
  public showUI: boolean = false;
  signIn: any;

  //Configuration for Okta Sign In Widget
  config = {
    baseUrl: sampleConfig.baseUrl,
    clientId: sampleConfig.clientId,
    redirectUri: sampleConfig.redirectUri,
    logo: '/assets/img/insighter.png',
    i18n: {
      en: {
        'primaryauth.title': 'Sign in to start your session',
      },
    },
    authParams: {
      issuer: sampleConfig.issuer,
      authorizeUrl: `${sampleConfig.issuer}/v1/authorize`,
      clientId: sampleConfig.clientId,
      pkce: true
    },
    features: {
      idpDiscovery: true
    },

    //required otherwise it takes me to the Okta landing page
    idpDiscovery: {
      requestContext: sampleConfig.requestContext
    },
  }

  constructor(
    private AuthService: AuthService,
    ) {      
      // const IS_IE = navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > -1;

      // if (IS_IE) {
      //   console.log('IS IE11 - attempting to use Implicit Flow');
      //   //USe Implicit Flow in IE11
      //   //this.config.authParams['display'] = 'page';
      //   this.config.authParams.pkce = false;
      //   //this.config.authParams['responseType'] = 'token';
      // }
    // enable blocking loading indicator only for login
    EmitterService.get("spinner.enableLoadingIndicator").emit(true);
  }

  ngOnDestroy() {
    // disable loading indicator and use only progress bar for all other pages
    EmitterService.get("spinner.enableLoadingIndicator").emit(false);
  }

  ngOnInit() {
    this.createWidget();

    console.log('Checking for existing tokens');
    //check for existing tokens
    this.signIn.authClient.tokenManager.getTokens()
    .then(({ accessToken, idToken }) => {
      if (accessToken) {
        const accessTokenVal = accessToken.value;
        const email = accessToken.claims.sub.toLowerCase();

        this.handleInsighterLogin(email, accessTokenVal);
      } else {
        console.log('no existing tokens found')
        this.checkLoginStatus();
      }
    }, e => {
      console.log('no existing tokens found - error')
      console.log(e);
      this.checkLoginStatus();
    });

  }

  createWidget() {
    this.signIn = new OktaSignIn(this.config);
    // this.signIn.on('afterRender', function (context) {

    //   if (context.controller === 'primary-auth') {
    //     //show help
    //     let helpLinks = document.querySelector(".js-help")
    //     if (helpLinks) {
    //       helpLinks['style'].display = 'inline';
    //     }
    //   }
    // });
  }

  checkLoginStatus() {

    if (isExternallyRedirected('show')) {
      //we already checked and it failed
      this.showLoginForm();
    } else if (isExternallyRedirected('iss')) {
      console.log('trying to auto login - iss');
      //We have been redirected by Okta and need to get our tokens on the Callback page
      this.signIn.authClient.token.getWithRedirect({
          responseType: ['token', 'id_token'],
          scopes: ['openid', 'email']
      })
      .catch(err => {
          this.errorMsg = 'Failed to do Okta redirect to get tokens... ' + err.toString();
          // handle OAuthError or AuthSdkError (AuthSdkError will be thrown if app is in OAuthCallback state)
          console.log(err)
          this.showLoginForm();
      });

    } else {
      console.log('trying to auto login - getWithRedirect');
      //try and get acess tokens for an existing session.  If not we will be redirect back here with 'show'
       this.signIn.authClient.token.getWithRedirect({
          responseType: ['token', 'id_token'],
          scopes: ['openid', 'email'],
          prompt: 'none'
      })
      .catch(err => {
          this.errorMsg = 'Failed to do Okta redirect to get tokens... ' + err.toString();
          // handle OAuthError or AuthSdkError (AuthSdkError will be thrown if app is in OAuthCallback state)
          console.log(err)
          this.showLoginForm();
      });
    }

  }


  showLoginForm() {
    this.showUI = true;

    this.signIn.showSignInAndRedirect({
      el: '#sign-in-widget',
    })
    .catch(err => {
      console.error(err);
      // Typically due to misconfiguration
      //throw err;
      this.errorMsg = 'Error with Okta login ' + err.toString();
    });

  }
  
  handleInsighterLogin(email, accessToken) {

    //We have been Authenticated in Okta, try and use the token and email to Auth into Insighter
    this.AuthService.authenticateOktaUser(email, accessToken).subscribe(data => {
      if (!data) {
        this.errorMsg = "Invalid e-mail and/or password.";
        return;
      }

      console.log('Authenticated into Insighter');
      // Remove the widget
      this.signIn.remove();

      // In this flow the redirect to Okta occurs in a hidden iframe
      //this.oktaAuth.handleLoginRedirect(tokens);
      window.location.replace(`${DEFAULT_ORIGINAL_URI}/dashboards`)
    }, error => {
      console.error(error);
      this.handleInsighterError(error);
    })

  }

  handleInsighterError(error) {
    let errMsg = "";

    // construct error message for user in the UI
    switch (error.status) {
      case 401:
        errMsg = "Incorrect username and/or password.   Not the correct username for Insighter.";
        break;
      case 502:
        errMsg = "The server is currently unreachable! ";
        break;
      case 0: // for some reason a 502 occasionally gets set as 0
        errMsg = "The server is currently unreachable! ";
        break;
      default:
        // Unknown error
        errMsg = "Unknown error!";
        break;
    }

    // construct complete error msg
    this.errorMsg = `Okta authentication succeeded, but there was an Insighter error:  ${error.statusText} ${errMsg}`;

    //reset the widget form because it is probably spinning
    this.signIn.remove();
    this.createWidget();
    this.showLoginForm();
  }

}
