import { map } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { StorageService } from "./storage.service";
import { Observable } from "rxjs";
import { MyDownload } from "../models/mydownload";
import { HttpClient, HttpHeaders } from "@angular/common/http";


@Injectable()
export class MyDownloadsService {
  // api folders endpoint
  private URL = "/my-downloads";
  private POWER_BI_URL = "/powerbi";

  private myDownloads: Array<MyDownload> = [];
  //public accesToken = null;
  public error = '';
  public pollingEnalbed = false;

  public intervals = [];

  public refreshPromise: Promise<Array<MyDownload>>;

  private accessTokens: any = {}

  constructor(
    private ApiService: ApiService, 
    private HttpClient: HttpClient,
    private StorageService: StorageService
    ) {}


  fetchMyDownloads(
    userId?: number
  ): Observable<MyDownload[]> {
    let url = `${this.URL}`;
    
    if (userId) {
      url += `/${userId}`;
    }

    return this.ApiService.get(url).pipe(
      map((res) => {
        return this.castToModel(res.body);
      })
    );
  }

  getMyDownload(
    id?: number
  ): Observable<MyDownload> {
    let url = `${this.URL}-one/${id}`;

    return this.ApiService.get(url, {
      silent: true
    }).pipe(
      map((res) => {
        return this.castToModel(res.body);
      })
    );
  }

  create(data: any): Observable<MyDownload> {
    
    return this.ApiService
      .post(this.URL, data).pipe(
      map(res => {
        const myDownload = this.castToModel(res.body);

        this.pollMyDownloads();

        return myDownload;
      }))
  }

  update(id, data: any): Observable<MyDownload> {
    
    return this.ApiService
      .put(`${this.URL}/${id}`, data).pipe(
      map(res => {
        return this.castToModel(res.body);
      }))
  }

  castToModel(data: any) {
    if (Array.isArray(data)) {
      data = data.map((obj) => {
        return new MyDownload(
          obj.id,
          obj.user,
          obj.client,
          obj.dashboard,
          obj.initPayload,
          obj.jobData,
          obj.complete,
          obj.status, 
          obj.downloadLink,
          obj.read,
          obj.createdAt,
          obj.updatedAt
        );
      });
    } else {
      data = new MyDownload(
        data.id,
        data.user,
        data.client,
        data.dashboard,
        data.initPayload,
        data.jobData,
        data.complete,
        data.status,
        data.downloadLink,
        data.read,
        data.createdAt,
        data.updatedAt
      );
    }

    return data;
  }

  getAdToken(obj: any) {
 
    return this.ApiService.post(
      `${this.POWER_BI_URL}/getAdToken`,
      obj
    ).pipe(
      map((res) => {
        return res.body;
      })
    );
  }

  getExportStatusInGroup(
    groupId: string,
    reportId: string,
    exportId: string,
    tenant: string
  ) {

    let accessToken = this.accessTokens[tenant];

    let tokenPromise = Promise.resolve();

    if (!accessToken) {
      tokenPromise = this.getAccessToken(tenant);
      
    }

    return tokenPromise.then(() => {
      accessToken = this.accessTokens[tenant];

      const url = `https://api.powerbi.com/v1.0/myorg/groups/${groupId}/reports/${reportId}/exports/${exportId}`;

      let headers = new HttpHeaders();
      headers = headers.set("Authorization", "Bearer " + accessToken);

      return this.HttpClient.get(url, {
        headers: headers,
      }).pipe(
        map((res) => {
          return res;
        })
      ).toPromise();
    })
    
  }

  getFileOfExportToFileInGroup(
    groupId: string,
    reportId: string,
    exportId: string,
    tenant: string,
    reportName: string
  ) {
    let accessToken = this.accessTokens[tenant];

    let tokenPromise = Promise.resolve();

    if (!accessToken) {
      tokenPromise = this.getAccessToken(tenant);
      
    }

    return tokenPromise.then(() => {
      accessToken = this.accessTokens[tenant];
      
      const url = `https://api.powerbi.com/v1.0/myorg/groups/${groupId}/reports/${reportId}/exports/${exportId}/file`;

      let headers = new HttpHeaders();
      headers = headers.set("Accept", "application/pdf");
      headers = headers.set("Authorization", "Bearer " + accessToken);

      return this.HttpClient.get(url, {
        headers: headers,
        responseType: "arraybuffer",
      }).pipe(
        map((res) => {
          const blob = new Blob([res], { type: "application/pdf" });

          if (window.navigator.msSaveBlob) {
            window.navigator.msSaveOrOpenBlob(blob,  reportName + ".pdf");
          } else {
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = reportName + ".pdf";
            link.click();
          }
          
        })
      ).toPromise();
    });
  }

  createDownloadClickLink(url, reportName) {
    let headers = new HttpHeaders();
    headers = headers.set("Accept", "application/pdf");

    return this.HttpClient.get(url, {
      headers: headers,
      responseType: "arraybuffer",
    }).pipe(
      map((res) => {
        const blob = new Blob([res], { type: "application/pdf" });

        if (window.navigator.msSaveBlob) {
          window.navigator.msSaveOrOpenBlob(blob,  reportName + ".pdf");
        } else {
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = reportName + ".pdf";
          link.click();
        }
        
      })
    );

  }

  getPollingEnabled() {
    return this.pollingEnalbed;
  }

  pollMyDownloads() {
    this.pollingEnalbed = true;

    this.clearPolling();

    this.refreshMyDownloads().then(() => {
      this.pollInProgress();
    })
  }

  getAccessToken(tenant: string) {
    return this.getAdToken({
      tenant
    }).toPromise()
      .then(tokenResult => {
        const token = tokenResult.access_token;
        this.accessTokens[tenant] = token;
      }, () => {this.error = 'Error generating token'; });
  }

  public pollInProgress() {
    const allItems = this.myDownloads;
    const inProgressItems = allItems.filter(item => {
      return item.status === 'In Progress'
    });

    if (inProgressItems.length) {
      //this.getAccessToken().then(() => {
        inProgressItems.forEach(myDownload => {
          if (myDownload.initPayload) {
            this.pollMyDownload(myDownload);
          } else {
            this.pollMyDownloadInsighter(myDownload);
          }
          
        })
      //})
    }
  }

  public pollMyDownload(myDownload) {
    const initPayload = myDownload.initPayload;
    const jobData = myDownload.jobData;
    const pollInterval = 10000;

    const interval = setInterval(() => {
      this.getExportStatusInGroup(initPayload.groupId, initPayload.reportId, jobData.id, initPayload.tenant)
      .then(data => {        
        let complete = data['percentComplete'];
        console.log('polling for ' + data['id'] + ': ' + complete + "%");

        myDownload.complete = complete;

        if (complete === 100) {
          clearInterval(interval);
          console.log('job is done and link should be shown');
          //update UI
          myDownload.status = 'Complete';
          myDownload.downloadLink = 'Click to Download';

          //update server - remove from polling
          this.update(myDownload.id, {
            complete: myDownload.complete,
            status: myDownload.status,
            downloadLink: myDownload.downloadLink
          }).subscribe(result => {
            console.log(result);
          }, 
          () => {
            this.error = 'Error updating report';
            clearInterval(interval); 
          })
        }
      }, 
        () => {
          this.error = 'Error getting status for report';
          clearInterval(interval); 
        })

    }, pollInterval)

    this.intervals.push(interval);
  }

  public pollMyDownloadInsighter(myDownload) {
    const pollInterval = 20000;

    const interval = setInterval(() => {
      this.getMyDownload(myDownload.id)
      .subscribe(data => {        
        let complete = data.complete;
        console.log('polling for ' + data['id'] + ': ' + complete + "%");

        myDownload.complete = complete;

        if (complete === 100) {
          clearInterval(interval);
          console.log('job is done and link should be shown');
          //update UI
          myDownload.status = 'Complete';
          myDownload.downloadLink = 'Click to Download';
        }
      }, 
        () => {
          this.error = 'Error getting status for report';
          clearInterval(interval); 
        })

    }, pollInterval)

    this.intervals.push(interval);
  }

  public clearPolling() {
    this.intervals.forEach(interval => {
      clearInterval(interval);
    })

    this.intervals = [];
  }

  public refreshMyDownloads() {
    const localStorageUser = JSON.parse(this.StorageService.getItem('currentUser'));
    const role = localStorageUser.role;
    const userId = localStorageUser.id;

    let criteria = userId;
    if (role === 'superAdmin') {
      criteria = null;
    }

    this.refreshPromise = this.fetchMyDownloads(criteria).toPromise().then((myDownloads) => {
      this.myDownloads = myDownloads;

      return this.myDownloads;
    })

    return this.refreshPromise;
  }

  public markAsRead() {
    this.getReadyForDownload().forEach(item => {
      item.read = true;

      this.update(item.id, {
        read: true
      }).subscribe(() => {})
    })
  }

  public getReadyForDownload() {
    return this.myDownloads.filter(item => {
      return item.status === 'Complete' && item.read === false
    });
  }

  public downloadFile(groupId, reportId, id, name, tenant) {
    return this.getFileOfExportToFileInGroup(groupId, reportId, id, tenant, name);
  }

  public getMyDownloads() {
    return this.refreshPromise;
  }

  public retryReport(id) {
    return this.ApiService
      .post(`${this.URL}/retry/${id}`, {}).pipe(
      map(res => {
        const myDownload = this.castToModel(res.body);

        this.pollMyDownloads();

        return myDownload;
      }))
  }
}
