import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "insighter-custom-dashboards-sidebar-content",
  templateUrl: "./custom-dashboards-sidebar-content.component.html",
  styles: [
    `
      /* ensure first child in sidebar nav has no top border */
      .admin-nav .navigation > li:first-child {
        border-top: 0;
      }
    `
  ]
})
export class CustomDashbaordsSidebarContentComponent implements OnInit {

  @Input() clients: any;


  constructor() {}

  ngOnInit() {
    let filteredClients = this.filterClients(this.clients);
    console.log( filteredClients);
    this.clients = filteredClients;
  }

  filterClients(clients) {
    return clients.filter(client => {
      let serviceNames = client.services.map(i => i.name);
      return serviceNames.indexOf("Custom Dashboards") !== -1;
    });
  }

  buildNavigation(clientId) {

  }

}
