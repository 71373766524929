
import {takeWhile, filter} from 'rxjs/operators';
import { Component, OnInit, OnDestroy } from "@angular/core";
import {
  ActivatedRoute,
  Router,
  NavigationStart,
  NavigationEnd,
  Event
} from "@angular/router";
import { environment } from "../environments/environment";
import { EmitterService } from "./services/emitter.service";
import { ScriptService } from "./services/script.service";
import { StorageService } from "./services/storage.service";
import { ClientResolverService } from './services/client-resolver.service';
import { $log } from "./services/logger.service";
import { modifyCss } from "./modify-css";

import { GoogleAnalyticsService } from "./services/google-analytics.service";
import { MyDownloadsService } from "./services/mydownloads.service";
import { AuthService } from "./services/auth.service";

// Okta TextEncoder polyfill for PKCE for older browsers
// import { TextEncoder } from 'text-encoding';
// if (typeof (window as any).TextEncoder === 'undefined') {
//   (window as any).TextEncoder = TextEncoder;
// }


@Component({
  selector: "insighter-app",
  template: `<insighter-header [clients]="clients"></insighter-header>
             <insighter-sidebar *ngIf="clients" [clients]="clients" (toggle)="collapseSidebar($event)"></insighter-sidebar>
             <router-outlet></router-outlet>`
})
export class AppComponent implements OnInit, OnDestroy {
  public clients: Array<any>;
  private isAlive: boolean = true; // Utilized to manage observables (garbage collection)

  /**
   * Inject required services; Load any scripts listed
   * in the environment.scripts array and predicated on
   * successful authentication, injects the sisense library.
   * Additionally, it subscribes to route events so that
   * we can hide the header & the sidebar components on
   * pages that must not show them & finally listen to
   * route.data events to get the clients data from a resolver;
   *
   *
   * @param route
   * @param ScriptService
   * @param StorageService
   * @param Router
   */
  constructor(
    private route: ActivatedRoute,
    private ScriptService: ScriptService,
    private StorageService: StorageService,
    private ClientResolverService: ClientResolverService,
    private Router: Router,
    private GoogleAnalyticsService: GoogleAnalyticsService,
    private MyDownloadsService: MyDownloadsService,
    private AuthService: AuthService
  ) {
    
    // subscribe to router events and send a new
    // pageview for GA on route change
    this.Router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {

        this.GoogleAnalyticsService.send('event', 'page_view', {});
      }
    });

    // Inject any scripts defined within the environment config into the HEAD of the page
    this.ScriptService.load(...environment.scripts)
      .then(data => $log.debug("script loaded ", data))
      .catch(err => $log.error(err));
  }

  ngOnInit() {
    modifyCss();
    // subscribe to activated router to get data from clients resolver
    this.route.data.pipe(
      filter(data => data && data.clients))
      .subscribe((data: { clients: Array<any> }) => {
        this.clients = data.clients;
        console.log('app cliients', this.clients)
        this.setupClientPolling();
      });

    EmitterService
      .get('refresh-clients').pipe(
      takeWhile(() => this.isAlive))
      .subscribe(state => {
        this.ClientResolverService.fetchUserClients().then(clients => {
          this.clients = clients;
          EmitterService.get("clients-refreshed").emit(clients);
          this.setupClientPolling();
        })
      });
  }

  hasAPowerBiMyDownloadsClient() {
    let shouldPoll = false;

    for (let i = 0; i < this.clients.length; i++) {
      let client = this.clients[i];

      if (client.services) {
        const hasIt = client.services.find(i => i.name === 'Power BI - My Downloads');
        if (hasIt) {
          shouldPoll = true;
          break;
        }
      }
    }
    return shouldPoll;
  }

  setupClientPolling() {
    let shouldPoll = this.AuthService.isSuperAdmin() || this.hasAPowerBiMyDownloadsClient()

    console.log('should poll?', shouldPoll);

    if (shouldPoll && !this.MyDownloadsService.getPollingEnabled()) {
      this.MyDownloadsService.pollMyDownloads();
    }
  }

  ngOnDestroy() {
    // cleanup - un-subscribe from any observables using takeWhile
    this.isAlive = false;
  }

  /**
   * catches toggle event from sidebar and broadcasts to
   *  sibling components that need it to expand their width
   *
   * @param {boolean} sidebarCollapsed
   */
  collapseSidebar(sidebarCollapsed: boolean) {
    EmitterService.get("sidebar.collapsed").emit(sidebarCollapsed);
  }
}
