import { Component } from "@angular/core";
import { Globals } from "../globals";
import { GoogleAnalyticsService } from "../services/google-analytics.service";

@Component({
  selector: "insighter-page-not-found",
  templateUrl: "./page-not-found.component.html",
  styleUrls: ["./page-not-found.component.scss"]
})
export class PageNotFoundComponent {
  constructor(private GoogleAnalyticsService: GoogleAnalyticsService) {
    this.GoogleAnalyticsService.send("event", "404", {
      event_category: "error",
      event_label: `pageId-${Globals.routeNotFound || 0}_widgetId-0`
    });
  }
}
