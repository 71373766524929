<insighter-client-dropdown
  [clients]="clients"
  (client)="buildNavigation($event)"
></insighter-client-dropdown>

<nav class="admin-nav" style="margin-top: 0;">
    <ul class="navigation">
      
      <li class="nav-section" *ngIf="shouldShowPlaybooks()">
        <a class="bold outer title">
          <i class="fa fa-tasks fa-lg" aria-hidden="true"></i>
          <span>Playbooks</span>
        </a>
        <ul class="navigation sub lt">
          <li>
            <a class="inner" routerLink="/ma/media-client/playbooks/create" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
              <span>Create Playbook</span>
            </a>
          </li>
          <li>
            <a class="inner" href="#" routerLink="/ma/media-client/playbooks" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
              <span>Manage Playbooks</span>
            </a>
          </li>
          <li>
            <a class="inner" href="#" routerLink="/ma/media-client/videos" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
              <span>Video Uploads</span>
            </a>
          </li>
        </ul>
      </li>
      <li class="nav-section" *ngIf="shouldShowGateway()">
        <a class="bold outer title">
          <i class="fa fa-tasks fa-lg" aria-hidden="true"></i>
          <span>Gateway</span>
        </a>
        <ul class="navigation sub lt">
          <li>
            <a class="inner" routerLink="/ma/media-client/gateway/create" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
              <span>Create Gateway Request</span>
            </a>
          </li>
          <li>
            <a class="inner" routerLink="/ma/media-client/pending-batch-jobs" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
              <span>Pending Batch Jobs</span>
            </a>
          </li>
          <li>
            <a class="inner" href="#" routerLink="/ma/media-client/gateway/all-requests" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
              <span>All Requests</span>
            </a>
          </li>
          <li>
            <a class="inner" href="#" routerLink="/ma/media-client/gateway/queue" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
              <span>Failed Requests Queue</span>
            </a>
          </li>
          <li>
            <a class="inner" href="#" routerLink="/ma/media-client/gateway/successful-requests" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
              <span>Successful Requests</span>
            </a>
          </li>
          <li>
            <a class="inner" href="#" routerLink="/ma/media-client/gateway/exception-requests" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
              <span>Exception Requests</span>
            </a>
          </li>
        </ul>
      </li>
    </ul>
  </nav>
  