<div class="reset-box col-sm-6 col-sm-offset-3 col-md-4 col-md-offset-4">
  <div class="reset-logo">
    <small><b>Insighter</b> Data Visualization</small>
  </div>
  <div class="reset-box-body">
    <form #resetForm="ngForm" (ngSubmit)="resetPassword(password, confirmedPassword)">
      <p class="reset-box-msg">
        Please enter a new password.
      </p>
      <div class="form-group">
        <input type="password" id="password" class="form-control"
               placeholder="New Password" name="passwordField" [(ngModel)]="password"
               required minlength="5"
               #passworField="ngModel" >
      </div>
      <div class="form-group">
        <input type="password" id="confirmedPassword" class="form-control"
               placeholder="Repeat Password" name="confirmedPasswordField" [(ngModel)]="confirmedPassword"
               required minlength="5"
               #passworField="ngModel" >
      </div>

      <div *ngIf="resetForm.submitted">
        <div [hidden]="!formError" class="alert alert-danger">
          {{formError}}
        </div>
        <div [hidden]="!formSuccess" class="alert alert-success">
          {{formSuccess}}
        </div>
      </div>

      <div class="form-group">
        <button type="submit" class="btn btn-primary btn-block btn-flat" [disabled]="!resetForm.form.valid">Reset Password</button>
      </div>

      <a routerLink="/login" style="display: block; text-align: left;" class="form-toggle"><i class="fa fa-arrow-circle-left"></i> back</a>
    </form>
  </div>
</div>
