<insighter-client-dropdown [clients]="clients" (client)="selectClient($event)"></insighter-client-dropdown>

<nav class="admin-nav" style="margin-top: 0;">
  <ul class="navigation">
    <li class="nav-section" *ngIf="hasAllPrivileges || hasPermissionsPrivileges">
      <a class="bold outer title">
        <i class="fa fa-users fa-lg" aria-hidden="true"></i>
        <span>Permissions</span>
      </a>
      <ul class="navigation sub lt">
        <li>
          <a href="#" class="inner" routerLink="/settings/users" routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}">
            <span>All Users</span>
          </a>
        </li>
        <li>
          <a href="#" class="inner" routerLink="/settings/user/new" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
            <span>Create New User</span>
          </a>
        </li>
        <li *ngIf="isDistributorClient">
          <a href="#" class="inner" routerLink="/settings/user-levels" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
            <span>User Levels</span>
          </a>
        </li>
        <li>
          <a href="#" class="inner" routerLink="/settings/dash" routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}">
            <span>All Dashboards</span>
          </a>
        </li>
      </ul>
    </li>
    <li class="nav-section" *ngIf="hasSuperAdminPrivileges && services.indexOf('Power BI') !== -1">
      <a class="bold outer title">
        <i class="fa fa-users fa-lg" aria-hidden="true"></i>
        <span>Power BI</span>
      </a>
      <ul class="navigation sub lt">
        <li>
          <a href="#" class="inner" routerLink="/settings/powerbiroles" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
            <span>Roles</span>
          </a>
        </li>
        <li>
          <a href="#" class="inner" routerLink="/settings/userpowerbiroles" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
            <span>User Roles</span>
          </a>
        </li>
      </ul>
    </li>
    <!-- begin insighter dynamic section for each iteration of meta-definition array -->
    <ng-container *ngIf="showLocations && (hasAllPrivileges || hasPartitionsPrivileges)">
      <div *ngFor="let section of metaDefinitions">
        <insighter-dynamic-section [section]="section"></insighter-dynamic-section>
      </div>
    </ng-container>
    <li class="nav-section" *ngIf="hasAllPrivileges || hasBudgetsPrivileges">
      <a class="bold outer title">
        <i class="fa fa-calendar fa-lg" aria-hidden="true"></i>
        <span>Budget Management</span>
      </a>
      <ul class="navigation sub lt">
        <li>
          <a href="#" class="inner" routerLink="/settings/marketing-calendar-promotion" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
            <span>Marketing Calendar</span>
          </a>
        </li>
        <li>
          <a href="#" class="inner" routerLink="/settings/marketing-calendar-budgets" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
            <span>Budgets</span>
          </a>
        </li>
      </ul>
    </li>
    <li class="nav-section" *ngIf="(hasMediaAutomationPrivileges || hasAdminMediaPrivileges) && services.indexOf('Media Automation') !== -1">
      <a class="bold outer title">
        <i class="fa fa-tasks fa-lg" aria-hidden="true"></i>
        <span>Media Automation</span>
      </a>
      <ul class="navigation sub lt">
        <li>
          <a href="#" class="inner" routerLink="/settings/media-auto/properties" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
            <span>Properties</span>
          </a>
        </li>
        <li>
          <a href="#" class="inner" routerLink="/settings/media-auto/assets" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
            <span>Assets</span>
          </a>
        </li>
        <li>
          <a href="#" class="inner" routerLink="/settings/media-auto/ad-copy" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
            <span>Ad Copy</span>
          </a>
        </li>
        <li>
          <a href="#" class="inner" routerLink="/settings/media-auto/naming-conventions" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
            <span>Naming Conventions</span>
          </a>
        </li>
        <li>
          <a href="#" class="inner" routerLink="/settings/media-auto/lead-form-bulk-create" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
            <span>Lead Forms Bulk Create</span>
          </a>
        </li>
      </ul>
    </li>
  </ul>
</nav>
