
import {takeWhile} from 'rxjs/operators';
/**
 * User Profile Component
 *
 * @description displays a modal with a form that allows the user to change their password
 *
 */
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { EmitterService } from '../../services/emitter.service';
import { ClientsService } from '../../services/client.service';
import { UsersService } from '../../services/users.service';
import { StorageService } from '../../services/storage.service';
import { $log } from '../../services/logger.service';
import { GoogleAnalyticsService } from '../../services/google-analytics.service';


@Component({
  selector: 'insighter-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit, OnDestroy {

  @ViewChild('userProfileModal') public userProfileModal;

  public formSubmitted: boolean = false;
  public password: string;
  public confirmedPassword: boolean;
  public canChangePassword: boolean;
  public userProfile;

  private clientId: number;
  private userId: number;

  // Utilized to manage observable subscriptions
  private isAlive: boolean = true;

  constructor(private ClientsService: ClientsService,
              private UsersService: UsersService,
              private StorageService: StorageService,
              private GoogleAnalyticsService: GoogleAnalyticsService) {

    let localStorageUser = this.StorageService.getItem('currentUser');

    // get the user ID of the currently logged in user
    if (localStorageUser) {
      this.userId = JSON.parse(localStorageUser).id
    }

    // Update the client id with what is currently in local storage.
    this.clientId = parseInt(this.StorageService.getItem('selectedClient'), 10);

    // build the user profile for the modal
    this.buildUserProfile(this.clientId);

    EmitterService.get('client.changed').pipe(
      takeWhile(() => this.isAlive))
      .subscribe(clientId => {
        this.clientId = clientId;

        // build the user profile for the modal
        this.buildUserProfile(this.clientId);
      });
  }

  ngOnInit() {
    // subscribe to the modal.show event & when received show the profile modal
    EmitterService.get('modal.show').pipe(
      takeWhile(() => this.isAlive))
      .subscribe(value => {
        // show the profile modal
        this.userProfileModal.show();
      });
  }

  ngOnDestroy() {
    this.isAlive = false;
  }

  toggleChangePassword(e): void {
    e.preventDefault();

    this.canChangePassword = !this.canChangePassword;
  }

  /**
   * when the form is submitted, this method takes in the password, confirmed password and form as params
   *  checks to make sure the passwords match and then makes a PUT request to the users endpoint to changePassword
   * the user's password.
   *
   * @param {String} password - the user's new password
   * @param {String} confirmedPassword - the user confirms this password
   */
  changePassword(password, confirmedPassword): void {
    let response = document.getElementById('passwordResetResponse');
    let responseSuccess = '<div class="bg-success response-content" style="padding: 5px 15px;"><i class="fa fa-check" style="margin-right: 5px;"></i><span>Your password has been reset!</span></div>';
    let responseError = '<div class="bg-danger response-content" style="padding: 5px 15px;"><i class="fa fa-close" style="margin-right: 5px;"></i><span>There is an issue, please try again.</span></div>';
    let responseMismatch = '<div class="bg-danger response-content" style="padding: 5px 15px;"><i class="fa fa-close" style="margin-right: 5px;"></i><span>The passwords do not match, please try again.</span></div>';
    let responseEmpty = '<div class="bg-danger response-content" style="padding: 5px 15px;"><i class="fa fa-close" style="margin-right: 5px;"></i><span>Please enter a new password.</span></div>';

    // check to make sure use has entered a password
    if (password && confirmedPassword) {
      // do the provided passwords match?
      if (password === confirmedPassword) {
        
        this.GoogleAnalyticsService.send('event', 'change_password', {
          'event_category': 'action',
          'event_label': `pageId-0_widgetId-0`
        });

        // put request to the users endpoint
        this.UsersService
          .updatePassword(this.userId, {password: confirmedPassword})
          .subscribe(
            () => {
              // show the success message
              response.innerHTML = responseSuccess;
              // and hide the form
              setTimeout(() => {
                this.formSubmitted = !this.formSubmitted;
              })
            },
            error => {
              // show the error message
              $log.error(error);
              response.innerHTML = responseError;
            });
      } else {
        // show the misMatch error message
        response.innerHTML = responseMismatch;
      }
    } else {
      response.innerHTML = responseEmpty;
    }
  }

  /**
   * @description builds the user's profile to display in the profile modal
   *
   * @param {Object} client - Object representing the currently selected client
   */
  buildUserProfile(client): void {
    this.userProfile = {};
    let clientProps;

    // fetch client
    this.ClientsService.fetchClient(client)
      .subscribe(
        res => {
          // Ensure the client profile definition exists and has `properties` property
          if (res.profileDefinition && res.profileDefinition.hasOwnProperty('properties')) {
            // find the properties to show
            clientProps = res.profileDefinition.properties;
          }

          // fetch the user
          this.UsersService.fetchUser(this.userId)
            .subscribe(
              user => {
                // build the user's profile
                this.userProfile = {
                  firstName: user.firstName,
                  lastName: user.lastName,
                  email: user.email,
                  otherProps: []
                };

                // If client specific profile properties are defined
                // Ensure the client profile properties and user profile property exist

                if (clientProps && user.profile) {
                  // loop over props to show and save the value
                  // of the corresponding prop on the user object
                  clientProps = clientProps.map(prop => {
                    // Verify that the user profile has the profile attribute.
                    if (user.profile.hasOwnProperty(prop.label)) {
                      prop.value = user.profile[prop.label];

                      // push the prop to the userProfile.otherProps array
                      this.userProfile.otherProps.push(prop);
                    }
                  });
                }
            })
          }
        )
    }
}
