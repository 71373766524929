/**
 * Table helper service for sorting and pagination operations across components that use tables.
 */
import { Injectable, isDevMode } from '@angular/core';


@Injectable()
export class TableService {

  constructor() { }

  /**
   * Helper that takes the sort data from sort click, the table
   *  configuration, and the table data (source of truth)
   * and will update the table config and sort the given data -
   *  returning the sorted data.
   *
   * @note: Columns & table config are updated by reference
   *
   * @param   {SortEvent} sortEvent - SortEvent object encapsulates the
   *              name of the current column & the standard event info
   * @param   {Array<Object>} columns
   * @param   {any}           tableConfig
   * @param   {Array<any>}    tableDataStore
   * @returns {Array<any>}
   */
  public sort(sortEvent: any, columns: Array<Object>, tableConfig: any, tableDataStore: Array<any>): Array<any> {
    let direction = this.sortConfig(sortEvent, columns, tableConfig);

    // sort array of objects with specified column & direction
    return tableDataStore.sort((a, b) => {
      return (a[sortEvent.columnName] < b[sortEvent.columnName]) ?
        -1 * direction : (a[sortEvent.columnName] > b[sortEvent.columnName]) ? direction : 0;
    });
  }

  public sortConfig(sortEvent: any, columns: Array<Object>, tableConfig: any) {
    // convert direction asc/desc to 1 or -1
    let direction = tableConfig.sortByDirection === 'asc' ? 1 : -1;

    // check if column was clicked more than once & update direction
    if (tableConfig.sortByColumn === sortEvent.columnName) {
      tableConfig.sortByDirection =
        tableConfig.sortByDirection === 'asc' ? 'desc' : 'asc';
    } else {
      tableConfig.sortByColumn = sortEvent.columnName;
    }

    // find matching column & update the column sort icon to reflect direction change
    columns.map((column: { name: string, sort: string }) => {
      if (column.name === sortEvent.columnName) {
        column.sort = tableConfig.sortByDirection;
      }
      return column;
    });

    return direction;
  }

  /**
   * Paginates the entire set of data into current page by
   *  filtering on the complete table data store and returning
   * only the results within the paginated range of values.
   *
   * @note refactored to return a promise because of a bug in angular
   *       dev mode that requires setTimeout as a workaround to
   *       errors when values change too fast in between
   *       checks. https://github.com/angular/angular/issues/6005
   *       This method now performs a check using `isDevMode()` and
   *       wraps the logic in setTimeout() in a promise so that we
   *       can return the data from setTimeout.
   *
   * @returns {Promise<any>}
   */
  public paginate(itemsPerPage: number, currentPage: number, tableData: Array<any>): Promise<Array<any>> {
    let maxIndex = itemsPerPage * currentPage,
        minIndex = maxIndex - itemsPerPage;

    return new Promise(resolve => {
      if (isDevMode()) {
        setTimeout(() => {
          resolve(tableData.filter((row, index) =>
            index >= minIndex && index < maxIndex
          ));
        }, 20);
      } else {
        resolve(tableData.filter((row, index) =>
          index >= minIndex && index < maxIndex
        ));
      }
    });
  }
}
