<div class="filter-container">
  <div class="no-print">
    <div id="date-picker">
      <div class="form-group">
        <div class="input-group">
          <div id="daterange">
            <i class="fa fa-calendar"></i>&nbsp;
            <span id="date-text"
              [style.font-family]="font"
              [style.font-size]="fontSize"
            ></span> <i class="caret"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
