<li class="nav-section">
  <a class="bold outer title">
    <i class="fa fa-map-marker fa-lg" aria-hidden="true"></i>
    <span>{{section.name}}</span>
  </a>
  <ul class="navigation sub lt">
    <li>
      <a href="#" class="inner" routerLink="/settings/metadata"
         routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
        <span>View {{section.name | pluralize}}</span>
      </a>
    </li>
    <li>
      <a href="#" class="inner" routerLink="/settings/metadata/new"
         routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
        <span>Create New {{section.name | singular}}</span>
      </a>
    </li>
  </ul>
</li>
