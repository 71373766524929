/**
 * Http Service
 *
 * @description :: Handle all HTTP request to the API, along with applying the correct Authentication header value
 */


import {finalize} from 'rxjs/operators';

import {Injectable, EventEmitter} from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';

export enum Action { QueryStart, QueryStop }

@Injectable()
export class ApiService {
  public error: EventEmitter<any> = new EventEmitter<any>();

  private process: EventEmitter<any> = new EventEmitter<any>();
  private apiUrl: string = environment.api.url;

  constructor(private httpClient: HttpClient) { }

  /**
   * Adds an authentication header if the user session contains a token in local storage.
   */
  public buildAuthHeader(): string {
    let user = localStorage.getItem('currentUser');

    if (!user) {
      return null;
    }

    let token = JSON.parse(localStorage.getItem('currentUser')).token;
    return `Bearer ${token}`;
  }

  /**
   * Performs GET request
   *
   * @param {string} uri - URI to make request to
   * @param {RequestOptionsArgs} options - Optional request options object
   */
  public get(uri: string, options?: any): Observable<any> {
    return this.request('GET', uri, null, options);
  }

  /**
   * Performs POST request
   *
   * @param {string} uri - URI to make request to
   * @param {Object} body - Object to be passed as the request body
   * @param {RequestOptionsArgs} options - Optional request options object
   */
  public post(uri: string, body, options?: any): Observable<any> {
    return this.request('POST', uri, body, options);
  }

  /**
   * Performs PUT request
   *
   * @param {string} uri - URI to make request to
   * @param {Object} body - Object to be passed as the request body
   * @param {RequestOptionsArgs} options - Optional request options object
   */
  public put(uri: string, body, options?: any): Observable<any> {
    return this.request('PUT', uri, body, options);
  }

  /**
   * Performs DELETE request
   *
   * @param {string} uri - URI to make request to
   * @param {RequestOptionsArgs} options - Optional request options object
   */
  public delete(uri: string, body, options?: any): Observable<any> {
    return this.request('DELETE', uri, body, options);
  }

  /**
   * Performs PATCH request
   *
   * @param {string} uri - URI to make request to
   * @param {Object} body - Object to be passed as the request body
   * @param {RequestOptionsArgs} options - Optional request options object
   */
  public patch(uri: string, body, options?: any): Observable<any> {
    return this.request('PATCH', uri, body, options);
  }

  /**
   * Performs HEAD request
   *
   * @param {string} uri - URI to make request to
   * @param {RequestOptionsArgs} options - Optional request options object
   */
  public head(uri: string, options?: any): Observable<any> {
    return this.request('HEAD', uri, null, options);
  }

  /**
   * Performs HTTP request
   *
   * @param {RequestMethod} method - HTTP request type to be performed
   * @param {string} uri - URI to make request to
   * @param {Object} body - Object to be passed as the request body
   * @param {Object} options - Optional request options object
   */
  private request(method: string, uri: string, body?, options?: any): Observable<any> {
    const optionsObj = Object.assign({
      observe: 'response'
    }, options);

    if (localStorage.getItem('currentUser') && !optionsObj.headers) {
      let headers = new HttpHeaders();
      if (optionsObj.silent) {
        headers = new HttpHeaders({'ignoreProgressBar': ''})
      }
      optionsObj['headers'] = headers.set('Authorization', this.buildAuthHeader());
    }

    if (body) {
      optionsObj['body'] = body;
    }

    const url = `${this.apiUrl}${uri}`;

    return Observable.create(observer => {
      this.process.next(Action.QueryStart);
      this.httpClient.request(
        method,
        url,
        optionsObj
      ).pipe(
        finalize(() => {
          this.process.next(Action.QueryStop);
        }))
        .subscribe(
          res => {
            observer.next(res);
            observer.complete();
          },
          err => {
            this.error.next(err);  // important: passes any errors to global observable handled in app.service
            observer.error(err);
          });
    });
  }
}
