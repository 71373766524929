/**
 * Staging environment configuration values
 * environment.stage.ts
 *
 * Default values are inherited from the environment.base.ts file,
 * which may be overridden here.
 */


import { config as baseConfig } from './environment.base';
import { merge } from './utilities/merge';

let envConfig = {
  production: false,
  api: {
    url: 'https://api.insighter.stage.ansira.io'
  },
  mediaAutomationApi: 'https://td39lrijwd.execute-api.us-west-2.amazonaws.com/stage',
  pdfLoginUrl: 'https://insighter.stage.ansira.io',
  okta: {
    clientId: '0oaem129mCvQiVRUp1d6',
    baseUrl: 'https://stage.login.ansira.com',
    issuer: 'https://stage.login.ansira.com/oauth2/default',
    redirectUri: 'https://insighter.stage.ansira.io/login-new/callback',
    requestContext: 'https://stage.login.ansira.com/home/oidc_client/0oaiyw4f7GYaFmrqq1d6/aln177a159h7Zf52X0g8'
  }
};

export const environment = merge(baseConfig, envConfig);
