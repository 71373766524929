/**
 * @description :: Renders only static markup for the admin sidebar but the
 *      component is needed to keep the sidebar.html clean (there are 3 sidebars that
 *      live inside of one markup so we just inject the content). All worthwhile
 *      content in this component will be found in the html template. Since there
 *      is no styling the scss file has been removed & there is no reason for unit test
 */

import { Component } from '@angular/core';
import { TaskService } from '../../services/task.service';
import { EmitterService } from '../../services/emitter.service';
import {delay} from 'rxjs/operators';


@Component({
  selector: 'insighter-admin-sidebar-content',
  templateUrl: './admin-sidebar-content.component.html',
  styles: [`
    /* ensure first child in sidebar nav has no top border */
    .admin-nav .navigation > li:first-child {
      border-top: 0;
    }
  `]
})
export class AdminSidebarContentComponent {

  constructor(
    private TaskService: TaskService
  ) { }

  /**
   * Execute flush cache task on the API
   *
   * @returns {boolean}
   */
  public flushCache(): boolean {
    // Quick!  Look busy!
    this.toggleSpinner(true);

    // Request for the API to flush the Redis cache
    this.TaskService.flushCache()
      .pipe(delay(2000))
      .subscribe(() => this.toggleSpinner(false));

    return false;  // prevent href click propagation
  }

  /**
   * Execute flush cache task on the API
   *
   * @returns {boolean}
   */
  public syncSisense(): boolean {
    // Quick!  Look busy!
    this.toggleSpinner(true);

    // Request for the API to sync records from the Sisense API
    this.TaskService.syncSisense()
      .pipe(delay(2000))
      .subscribe(() => this.toggleSpinner(false));

    return false;  // prevent href click propagation
  }

  public syncDistributorClients(): boolean {
    // Quick!  Look busy!
    this.toggleSpinner(true);

    // Request for the API to sync records from the Sisense API
    this.TaskService.syncDistributorClients()
      .pipe(delay(2000))
      .subscribe(() => this.toggleSpinner(false));

    return false;  // prevent href click propagation
  }

  public syncRedshiftLocations(): boolean {
    // Quick!  Look busy!
    this.toggleSpinner(true);

    // Request for the API to sync records from the Sisense API
    this.TaskService.syncRedshiftLocations()
      .pipe(delay(2000))
      .subscribe(() => this.toggleSpinner(false));

    return false;  // prevent href click propagation
  }

  public checkScheduledJobs(): boolean {
    // Quick!  Look busy!
    this.toggleSpinner(true);

    // Request for the API to sync records from the Sisense API
    this.TaskService.checkScheduledJobs()
      .pipe(delay(2000))
      .subscribe(() => this.toggleSpinner(false));

    return false;  // prevent href click propagation
  }

  /**
   * Enables or disables the loading indicator's
   *  visibility and toggle state to show a blocking
   * UI operation when attempting one of the sisense
   *  task actions.
   *
   * @param   {boolean} enabled
   * @returns {void}
   */
  private toggleSpinner(enabled: boolean): void {
    // enable blocking loading indicator only for login
    EmitterService.get('spinner.enableLoadingIndicator').emit(enabled);

    // Request for the API to sync records from the Sisense API
    EmitterService.get('spinner.toggle').emit(enabled);
  }
}
