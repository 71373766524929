/**
 * Clients Service
 *
 * @description :: used to fetch and manage clients from the api
 */


import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { $log } from './logger.service';
import { Client } from '../models/client';
import { UserLevel } from '../models/user-level';



@Injectable()
export class ClientsService {
  // api folders endpoint
  private CLIENTS_URL = '/clients';
  private BULK_CLIENTS_URL = '/bulk/clients';
  private clients: Array<any>;

  // caches the total pagination count from header X-Pagination-Count
  private totalCount: number;

  constructor(private ApiService: ApiService) {}

  /**
   * Used to set the current client id from initial clients
   *  data fetch in clients.resolver route helper. It also
   * returns the client id value already given in local storage
   *  or by using first on the array from the given data.
   *
   * @param   {Array<any>|<any>} clients
   * @returns {any}
   */
  setClientId(clients: Array<any>|any): any {
    // cast to array; most likely will be array but certain cases
    // may not so by casting to an array we can treat them the same
    this.clients = clients instanceof Array ? clients : [clients];

    let selectedClient = localStorage.getItem('selectedClient');

    // if nothing in local store then 1st run - store first client in array as default
    if (!selectedClient) {
      this.updateClientId(this.clients[0].id);
    } else {
      // client was previously set & app is reloaded so use existing client
      this.clients.find(client => {
        return client.id === localStorage.getItem('selectedClient');
      });
    }

    return this.clients[0].id;
  }

  /**
   * Used only to update local storage; Can be called from components
   *  or within the service. Currently the only time should be called
   * from a component is the client-dropdown shared component when
   *  a new client is selected from the dropdown list.
   *
   * @param   {number} clientId
   * @returns {void}
   */
  updateClientId(clientId): void {
    localStorage.setItem('selectedClient', clientId);
  }

  removeClientId(): void {
    localStorage.removeItem('selectedClient');
  }

  /**
   * Getter for the currently selected/active client's
   *  unique id -- uses either the first client present
   * in the array of clients or the pre-existing value
   *  from local storage if a client has been selected
   * before.
   *
   * @returns {number} returns the client id
   */
  getClientId(): number {
    if (localStorage.getItem('selectedClient')) {
      return Number(localStorage.getItem('selectedClient'));
    } else {
      if (this.clients && this.clients.length > 0) {
        return this.clients[0].id;
      }
    }

    $log.error(`defaulting to value of 1 for client ID (no local store or clients data)`);
    return 1;
  }

  /**
   * GET request to clients endpoint on the API. Takes
   *  pagination params `perPage` & `pageNumber` and
   * sets reasonable defaults on them.
   *
   * @param   {number} perPage     (optional) number of results to show per page
   * @param   {number} pageNumber  (optional) request a specific page number
   * @returns {observable<Client[]>}  returns observable with array of data in response
   */
  fetchClients(perPage?: number, pageNumber?: number): Observable<Client[]> {
    let url = `${this.CLIENTS_URL}/`;

    if (perPage && pageNumber) {
      url = `${this.CLIENTS_URL}/?page=${pageNumber}&perPage=${perPage}`
    }

    return this.ApiService
      .get(url).pipe(
      map(res => {
        // store the total count of clients
        this.totalCount = Number(res.headers.get('x-pagination-count'));

        return this.castToModel(res.body);
      }));
  }

  /**
   * GET request to clients detail endpoint to retrieve info
   *  for one specific client.
   *
   * @param   {any} clientId - the id or primary key of the client requesting for
   * @returns {observable<Client>} returns observable with response
   */
  fetchClient(clientId): Observable<Client> {
    return this.ApiService
      .get(`${this.CLIENTS_URL}/${clientId}`).pipe(
      map(res => {
        return this.castToModel(res.body);
      }));
  }

  /**
   * PUT request to clients detail endpoint to update the
   *   properties for the given client.
   *
   * @param   {number} clientId - client id of the client to change
   * @param   {Object} model   - represents the client data { id: 1, isActive: false }
   * @returns {observable<Client>} returns observable with response
   */
  updateClient(clientId, model): Observable<Client> {
    // copy the data into separate object to prevent props
    // from disappearing on the model & the frontend UI
    let data = JSON.parse(JSON.stringify(model));

    if (data.id) {
      delete data.folderName;
      delete data.folderType;
      delete data.toggle;
      delete data.types;
      delete data.folders;
    }

    return this.ApiService
      .put(`${this.CLIENTS_URL}/${clientId}`, data).pipe(
      map(res => {
        return this.castToModel(res.body);
      }));
  }

  /**
   * POST request to root clients endpoint to create a
   *  new client with the given data. Note, this takes
   * care to strip out the `id` field & any other
   *  fields that should not exist in the request data
   * for creating a new client.
   *
   * @param   {Client} model - data to persist
   * @returns {observable<Client>} observable with response
   */
  createClient(model: Client): Observable<Client> {
    // from disappearing on the model & the frontend UI
    let data = JSON.parse(JSON.stringify(model));

    // look for certain fields to strip
    if (data.id) {
      delete data.id;
      delete data.folderType;
      delete data.folderName;
      delete data.toggle;
      delete data.types;
      delete data.folders;
    }

    return this.ApiService
      .post(this.CLIENTS_URL, data).pipe(
      map(res => {
        return this.castToModel(res.body);
      }))
  }

  /**
   * PUT request to bulk/clients endpoint to change multiple
   *  clients at once using bulk actions.
   *
   * @param   {Object} data - array of objects
   * @returns {observable<any>} returns observable with response
   */
  bulkUpdateClients(data): Observable<any> {
    return this.ApiService
      .put(`${this.BULK_CLIENTS_URL}`, data).pipe(
      map(res => {
        return res.body;
      }));
  }


  /**
   * DELETE request to bulk/clients endpoint to delete
   *  multiple clients at once
   *
   * @param   {Object} data
   * @returns {observable<any>} returns observable with response
   */
  bulkDeleteClients(data): Observable<any> {
    return this.ApiService
      .delete(`${this.BULK_CLIENTS_URL}`, data).pipe(
      map(res => {
        return res.body;
      }));
  }

  /**
   * GET request to fetch the folders for a specific client
   *
   * @param  {number} clientId - client id of the client to fetch data
   * @param  {string} sort - sort parameters
   * @return {Observable<any>}  returns observable with response
   */
  fetchClientFolders(clientId, sort: string = 'order asc'): Observable<any> {
    return this.ApiService
      .get(`${this.CLIENTS_URL}/${clientId}/folders?sort=${sort}`).pipe(
      map(res => {
        return res.body;
      }));
  }

  /**
   * GET request to fetch the dashboards for a specific client
   *
   * @param  {number} clientId - client id of the client to fetch data
   * @param  {string} sort - sort parameters
   * @return {Observable<any>}  returns observable with response
   */
  fetchClientDashboards(clientId, sort: string = 'order asc'): Observable<any> {
    return this.ApiService
      .get(`${this.CLIENTS_URL}/${clientId}/dashboards?sort=${sort}`).pipe(
      map(res => {
        return res.body;
      }));
  }

  /**
   * GET request to fetch the metadata for a specific
   *  client to be used for filtering in dashboards
   *
   * @param   {number} clientId     client id of the client to fetch data
   * @param   {number} perPage     number of results to show per page
   * @param   {number} pageNumber  request a specific page number
   * @return  {Observable<any>}  returns observable with response
   */
  fetchClientMetadata(clientId, perPage: number = 10, pageNumber: number = 1): Observable<any> {
    return this.ApiService
      .get(`${this.CLIENTS_URL}/${clientId}/meta-data/?page=${pageNumber}&perPage=${perPage}`).pipe(
      map(res => {
        return res.body;
      }));
  }

  /**
   * takes csv as form data and uploads to specified client
   *
   * @param   {number}   clientId
   * @param   {FormData} data
   * @returns {Observable<any>}
   */
  uploadMetadataCSV(clientId: number, data: FormData): Observable<any> {
    return this.ApiService
      .post(`${this.CLIENTS_URL}/${clientId}/meta-data/upload`, data).pipe(
      map(res => {
        return res.body;
      }));
  }

  /**
   * downloads a csv file from the api as text & converts
   *  to a blob object, creates a link & generates a click
   * to work properly in ajax powered application.
   *
   * @param   {number}   clientId
   * @returns {Observable<any>}
   */
  downloadMetadataCSV(clientId: number): Observable<any> {
    return this.ApiService
      .get(`${this.CLIENTS_URL}/${clientId}/meta-data/export`, {responseType: 'text'}).pipe(
      map(res => {
        const blob = new Blob([res.body], { type: 'text/csv' });

        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'metadata.csv';
        link.click();
      }));
  }

  /**
   * downloads a csv file from the api as text & converts
   *  to a blob object, creates a link & generates a click
   * to work properly in ajax powered application.
   *
   * @param   {number}   clientId
   * @returns {Observable<any>}
   */
  downloadUsersCSV(clientId: number): Observable<any> {
    return this.ApiService
      .get(`${this.CLIENTS_URL}/${clientId}/users-data/export`, {responseType: 'text'}).pipe(
      map(res => {
        const blob = new Blob([res.body], { type: 'text/csv' });

        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'users.csv';
        link.click();
      }));
  }

  downloadPowerBIUsersCSV(clientId: number): Observable<any> {
    return this.ApiService
      .get(`${this.CLIENTS_URL}/${clientId}/powerbiroles-data/export`, {responseType: 'text'}).pipe(
      map(res => {
        const blob = new Blob([res.body], { type: 'text/csv' });

        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'powerbi_users.csv';
        link.click();
      }));
  }

  /**
   * takes csv as form data and uploads to specified client
   *
   * @param   {number}   clientId
   * @param   {FormData} data
   * @returns {Observable<any>}
   */
  uploadUsersCSV(clientId: number, data: FormData): Observable<any> {
    return this.ApiService
      .post(`${this.CLIENTS_URL}/${clientId}/users/upload`, data).pipe(
      map(res => {
        return res.body;
      }));
  }

  uploadPowerBIUsers(clientId: number, data: FormData): Observable<any> {
    return this.ApiService
      .post(`${this.CLIENTS_URL}/${clientId}/powerbiroles/upload`, data).pipe(
      map(res => {
        return res.body;
      }));
  }

  /**
   * GET request to users endpoint on the API. Similar to
   * fetchUsers(), but only returns users for a particular client
   *
   * @note this method exists in both users service & client service
   *
   * @param   {number} clientId     client id
   * @param   {number} perPage      number of results to show per page
   * @param   {number} pageNumber   request a specific page number
   * @returns {observable<any[]>}   returns subscribed observable with
   *                                 an array of data in response
   */
  fetchUsersByClient(clientId: number, perPage: number = 10, pageNumber: number = 1): Observable<any[]> {
    return this.ApiService
    .get(`${this.CLIENTS_URL}/${clientId}/users/?page=${pageNumber}&perPage=${perPage}`).pipe(
    map(res => {
      // store the total count of users
      this.totalCount = Number(res.headers.get('x-pagination-count'));

      return res.body;
    }));
  }


  /**
   * DELETE request to bulk/clients endpoint to delete
   *  multiple metadata rows at once for given client
   *
   * @todo Should we also pass the client id or will the
   * @todo endpoint know based on the metadata id's?
   *
   * @param data
   * @returns {Observable<any>}
   */
  bulkDeleteClientMetaData(data): Observable<any> {
    return this.ApiService
      .delete(`${this.BULK_CLIENTS_URL}/meta-data`, data).pipe(
      map(res => {
        return res.body;
      }));
  }

  /**
   * GET request to fetch the meta definition data (required
   *  to properly construct sisense dashboard filters for
   * the data returned from the metadata endpoint)
   *
   * Use this endpoint to properly parse data from meta-data
   *  endpoint e.g. find `isDataKey: true` & use that same
   * object's `label` field to know which field names to use
   *  within the Metadata.data for applying to filters.
   *
   * @param  {number} clientId  client id of the client to fetch data
   * @return {Observable<any>}  returns observable with response
   */
  fetchClientMetaDefinitions(clientId): Observable<any> {
    return this.ApiService
      .get(`${this.CLIENTS_URL}/${clientId}/meta-definitions`).pipe(
      map(res => {
        return res.body;
      }));
  }

  //TODO: delete unused server code
  // fetchClientServices(clientId): Observable<any> {
  //   return this.ApiService
  //     .get(`${this.CLIENTS_URL}/${clientId}/services`)
  //     .map(res => {
  //       return res.body;
  //     });
  // }

  /**
   * PUT request to update the meta definition data for given client
   *
   * @param  {number} clientId  client id of the client to fetch data
   * @param  {number} metaId    the id of the meta-definitions to update
   * @param  {Object} data      the data to send to server for update
   * @return {Observable<any>}  returns observable with response
   */
  updateClientMetaDefinitions(clientId, metaId, data): Observable<any> {
    return this.ApiService
      .put(`${this.CLIENTS_URL}/${clientId}/meta-definitions/${metaId}`, data).pipe(
      map(res => {
        return res.body;
      }));
  }

  /**
   * POST request to create the meta definition data for given client
   *
   * @param  {number} clientId  client id of the client to fetch data
   * @param  {Object} model      the data to send to server for update
   * @return {Observable<any>}  returns observable with response
   */
  createClientMetaDefinitions(clientId, model): Observable<any> {
    // shallow copy ony the basic model props
    let data = JSON.parse(JSON.stringify(model));

    // look for id field to strip on POST
    if (data) {
      delete data.id;
    }

    return this.ApiService
      .post(`${this.CLIENTS_URL}/${clientId}/meta-definitions`, data).pipe(
      map(res => {
        return res.body;
      }));
  }

  uploadClientLogo(clientLogo: any, clientId: number, type: string): Promise<any> {
    let data = new FormData();
    data.append('file', clientLogo.file);

    return this.ApiService
      .post(`${this.CLIENTS_URL}/${clientId}/image/upload/${type}`, data)
      .toPromise()
      .then(response => response.body)
  }

  /**
   * Getter function used to retrieve total count of clients. Count
   *  data comes from the X-Pagination-Count header
   *
   * @returns {number} returns total count
   */
  getTotalCount(): number {
    return this.totalCount;
  }

  /**
   * Takes the returned data and passes it to the proper model
   *  object instantiating the data as model instances.
   *
   * @param   {Array<Client>|Client} data
   * @returns {Array<Client>|Client}
   */
  castToModel(data: any) {
    if (Array.isArray(data)) {
      data = data.map(obj => {
        // if no profile properties then set array with one empty attribute
        if (!obj.profileDefinition) {
          obj.profileDefinition = {
            properties: []
          }
        }

        return new Client(
          obj.id, obj.name, obj.folder, obj.folders || [], obj.defaultAccount, obj.profileDefinition,
          obj.links, obj.logo, [], obj.type, obj.s3Folder, obj.distributorClientId, obj.services, obj.displayAttributes,
          obj.subdomainSlug, obj.hideDashboards
        )
      });
    } else {
      // if no profile properties then set array with one empty attribute
      if (!data.profileDefinition) {
        data.profileDefinition = {
          properties: []
        }
      }

      data = new Client(
        data.id, data.name, data.folder, data.folders || [], data.defaultAccount, data.profileDefinition,
        data.links, data.logo, [], data.type, data.s3Folder, data.distributorClientId, data.services, data.displayAttributes,
        data.subdomainSlug, data.hideDashboards
      )
    }

    return data;
  }

  getDistributorClients(): Observable<any> {
    return this.ApiService
      .get(`/distributor-clients`).pipe(
      map(res => {
        return res.body;
      }));
  }

  setupNewDistributorClient(client, localEmail): Observable<any> {
    client.localEmail = localEmail;
    return this.ApiService
      .post(`/distributor-clients/setup`, client).pipe(
      map(res => {
        return res.body;
      }));
  }

  getUserLevels(clientId): Observable<any> {
    return this.ApiService
      .get(`/client-level-dashboard-levels/${clientId}/user-levels`).pipe(
      map(res => {
        return this.castToUserLevel(res.body);
      }));
  }

  castToUserLevel(data: Array<UserLevel>|UserLevel) {
    if (Array.isArray(data)) {
      data = data.map(obj => {

        return new UserLevel(
          obj.levels_clientid,
          obj.childorgdefname,
          obj.clientname
        )
      });
    } else {
      
      data = new UserLevel(
        data.levels_clientid,
        data.childorgdefname,
        data.clientname
      )
    }

    return data;
  }

  updateClientLevelDashboards(data): Observable<any> {
    return this.ApiService
      .post(`/client-level-dashboard`, data).pipe(
      map(res => {
        return res.body;
      }));
  }

  getClientLevelDashboards(clientId, level): Observable<any> {
    return this.ApiService
      .get(`/client-level-dashboard/${clientId}/${level}`).pipe(
      map(res => {
        return res.body;
      }));
  }

  getUserLevelDashboardsForUser(clientId, distributorUserId): Observable<any> {
    return this.ApiService
      .get(`/user-level-dashboard-levels/${clientId}/${distributorUserId}`).pipe(
      map(res => {
        return res.body;
      }));
  }

  getUserLevelsForDashboard(clientId, dashboardId): Observable<any> {
    return this.ApiService
      .get(`/user-level-dashboard-supported/${clientId}/${dashboardId}`).pipe(
        map(res => {
          return res.body;
        }));
  }

}
