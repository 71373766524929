import { Component } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { AuthService } from "../services/auth.service";
import { AppService } from "../services/app.service";

@Component({
  selector: "insighter-user-auth",
  template: "",
})
export class LoginUserComponent {
  constructor(
    private ActivatedRoute: ActivatedRoute,
    private AuthService: AuthService,
    private Router: Router,
    private AppService: AppService
  ) {
    this.ActivatedRoute.params.subscribe((params) => {
      // Navigate to dashboards or back to login if auth failed
      AuthService.authenticatePDF(params.token)
        .then(() => {
          this.Router.navigate([`/dashboards`]);
        })
        .catch(() =>
          this.Router.navigate(["/logout"], {
            queryParams: {
              expired: true,
              isEmbedded: AppService.appIsEmbedded,
            },
          })
        );
    });
  }
}
