import { Component } from '@angular/core';
import { AppService } from './services/app.service';

@Component({
  selector: 'insighter-outlet',
  template: `<router-outlet></router-outlet>`
})
export class OutletComponent {

  /**
   * AppService must be injected to ensure it is
   * instantiated as soon as the application bootstraps
   *
   * @param AppService
   */
  constructor(private AppService: AppService) { }
}
