/**
 * PickerOptions
 *
 * @description :: Definition of the object utilized for defining the date range picker options
 */

export class PickerOptions {
  private alwaysShowCalendars: boolean;
  private autoApply: boolean;
  private startDate: Date;
  private endDate: Date;
  private minDate: Date;
  private maxDate: Date;

  constructor(startDate?: Date, endDate?: Date, minDate?: Date, maxDate?: Date) {
    this.alwaysShowCalendars = true;
    this.autoApply = false;
    // set start date to 3 weeks prior (1814400000 in ms)
    this.startDate = new Date(Date.now() - 1814400000);
    this.endDate = new Date();
    this.minDate = minDate;
    this.maxDate = new Date();
  }

  /**
   * Get the selected start date value for the date picker
   *
   * @return {Object<Date>} Date date
   */
  getStartDate(): Date {
    return this.startDate;
  }

  /**
   * Set the selected start date for the date picker
   *
   * @param {Object<Date>} date - Date date
   */
  setStartDate(date: Date): void {
    this.startDate = date;
  }

  /**
   * Get the selected end date value for the date picker
   *
   * @return {Object<Date>} Date date
   */
  getEndDate(): Date {
    return this.endDate;
  }

  /**
   * Set the selectable end date value for the date picker
   *
   * @param {Object<Date>} date - Date date
   */
  setEndDate(date: Date): void {
    this.endDate = date;
  }

  /**
   * Get the minimum selectable date value for the date picker
   *
   * @return {Object<Date>} Date date
   */
  getMinDate(): Date {
    return this.minDate;
  }

  /**
   * Set the minimum selectable date value for the date picker
   *
   * @param {Object<Date>} date - Date date
   */
  setMinDate(date: Date): void {
    this.minDate = date;
  }

  /**
   * Get the maximum selectable date value for the date picker
   *
   * @return {Object<Date>} Date date
   */
  getMaxDate(): Date {
    return this.maxDate;
  }

  /**
   * Set the maximum selectable date value for the date picker
   *
   * @param {Object<Date>} date - Date date
   */
  setMaxDate(date: Date): void {
    this.maxDate = date;
  }
}

export default PickerOptions;
