<div class="login-container">
    <div *ngIf="errorMsg" class="alert alert-danger">
        {{ errorMsg }}
        <br />
        <a
            class="help-desk-link"
            target="_blank"
            href="https://docs.google.com/forms/d/e/1FAIpQLSf9OJHTNjYEHfwq7kiSLtdn-HxNAImKsST8kYEg6zyuM3OgDQ/viewform?usp=sf_link"
            >Contact Help Desk</a>
    </div>
    <div id="sign-in-widget"></div>
    <div class="footer" *ngIf="showUI">
        © 2021 Ansira, All Rights Reserved.
    </div>
</div>
