export class EventLog {
  toggle?: boolean;

  constructor(
    public id: number,
    public user: any,
    public email: string,
    public event: string,
    public createdAt: string
  ) {
    // default to unchecked
    this.toggle = false;

    if (this.createdAt) {
        this.createdAt = new Date(this.createdAt).toLocaleString();
    }

  }
}
