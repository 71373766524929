/**
 * DashboardsSidebarContentComponent Component
 *
 * @description :: builds and renders the dashboard navigation for the sidebar of the dashboards module
 */

import { Component, OnInit, Input } from "@angular/core";
import { Router } from "@angular/router";
import { ClientsService } from "../../services/client.service";
import { FoldersService } from "../../services/folders.service";
import { StorageService } from "../../services/storage.service";
import { ClientRedirectService } from "../../services/clientredirect.service";
import { EmitterService } from "../../services/emitter.service";
import { $log } from "../../services/logger.service";

@Component({
  selector: "insighter-dashboards-sidebar-content",
  templateUrl: "./dashboards-sidebar-content.component.html",
  styleUrls: ["./dashboards-sidebar-content.component.scss"]
})
export class DashboardsSidebarContentComponent implements OnInit {
  @Input() clients: any;
  public dashboards: any;
  public folders: any;
  public sortedFolders: any;
  public options: any;
  public userRole: string;

  constructor(
    private ClientsService: ClientsService,
    private Router: Router,
    private FolderService: FoldersService,
    private StorageService: StorageService,
    private ClientRedirectService: ClientRedirectService
  ) {
    this.options = {
      group: 'test',
      onUpdate: (event: any) => {
        //console.log('sidebar onUpdate')
        this.reorderFolders();
      },
      onMove: (event: any) => {
        //console.log('sidebar moved', event);

        if (
          (event.from.className.indexOf('dashboards') !== -1 && event.to.className.indexOf('folder-container') !== -1) ||
          (event.from.className.indexOf('folder-container') !== -1 && event.to.className.indexOf('dashboards') !== -1) ||
          (event.from.className.indexOf('parent-sidebar') !== -1 && event.to.className.indexOf('folder-container') !== -1 && event.to.className.indexOf('parent-sidebar') === -1)
          ) {
          //console.log('preventing move')
          return false;
        }

        return event.willInsertAfter ? 1 : -1;
      },
      onAdd: (event: any) => {
        //console.log('sidebar added', event);
      },
      onRemove: (event: any) => {
        //console.log('sidebar removed', event);
      },
    };
  }

  ngOnInit() {
    // determine user's role
    this.userRole = JSON.parse(this.StorageService.getItem("currentUser")).role;

    const clientId = this.ClientsService.getClientId();

    const currentClient = this.getCurrentClient(clientId);
    if (currentClient) {
      if (this.ClientRedirectService.shouldRedirect(currentClient)) {
        EmitterService.get('spinner.toggle').emit(true);
        this.ClientRedirectService.doRedirect(currentClient);
        return;
      }
    }
    
    // build navigation on initial run using the first client in the clients array
    this.buildNavigation(clientId);
  }

  getCurrentClient(clientId) {
    return this.clients.find(client => {
      return clientId === client.id
    })
  }

  /**
   * build Navigation(client)
   *
   * @description :: takes a client param and builds the navigation based on the client
   * @param client - client id
   */
  buildNavigation(client) {
    // // get dashboard folders for selected client
    this.ClientsService.fetchClientFolders(client).subscribe(folders => {
      const tempFolders = folders.filter(folder => folder.parentFolder);

      // get dashboards for selected client
      this.ClientsService.fetchClientDashboards(client).subscribe(
        dashboards => {
          this.dashboards = dashboards;

          // map over folders and push corresponding dashboards to folder.dashboards
          this.folders = tempFolders.map(folder => {
            folder.dashboards = this.dashboards.filter(
              dashboard => dashboard.folder === folder.id
            );
            return folder;
          });

          this.orderFolders();
          this.showFirstDashboard();
          this.checkForSubFolders();
        }
      );
    });
  }

  checkForValidDashboardId(url: string, folders): boolean {
     //Use the id in the url properly
     const splitUrl = url.split('/');
     let isValidId = false;

     if (splitUrl.length === 3) {
       //is this id valid?
       try {
         const urlId = parseInt(splitUrl[2], 10)
         outer: for (let i = 0; i < folders.length; i++) {
           let folder = folders[i];

           if (folder.dashboards) {
             for (let j = 0; j < folder.dashboards.length; j++) {
               let dashboard = folder.dashboards[j];
               if (dashboard.id === urlId) {
                 console.log('Should use id, rather than the first one');
                 isValidId = true;
                 break outer;
               }
             }
           }
         }
       } catch (e) {
         console.log(e)
       }
    }
    return isValidId;
  }

  /**
   * showFirstDashboard()
   *
   * @description :: navigate to the first dashboard in the sidebar navigation
   */
  showFirstDashboard() {
    let dashboard: any;

    // this catches errors for clients that have no dashboards
    if (this.folders[0] && this.folders[0].hasOwnProperty("dashboards")) {
      //Use the id in the url properly
      const url = this.Router.url;
      let isValidId = this.checkForValidDashboardId(url, this.folders);

      if (!isValidId) {

        //find first dashboard
        let firstDashboardId = null;

        outer: for (let i = 0; i < this.folders.length; i++) {
          let folder = this.folders[i];

          if (folder.dashboards) {
            for (let j = 0; j < folder.dashboards.length; j++) {
              let dashboard = folder.dashboards[j];
              firstDashboardId = dashboard.id;
              break outer;
            }
          }
          
        }

        //TOOD - children?

        if (firstDashboardId) {
          // this.Router.navigate(['dashboards']);
          this.Router.navigate([`dashboards/${firstDashboardId}`]);
        }
        
      }
    } else {
      this.Router.navigate(["dashboards/error/contact-error"], { queryParams: { id: 'no-dashboards'} } );
    }
  }

  /**
   * assigns an order number to each folder
   * if the current order is 0
   */
  orderFolders() {
    let needsUpdate: Boolean;

    new Promise((resolve, reject) => {
      this.folders.map(folder => {
        if (folder.order === 0) {
          folder.order = this.folders.indexOf(folder) + 1;
          needsUpdate = true;
        }
      });
      resolve();
    }).then(() => {
      if (needsUpdate) {
        // this.FolderService.updateFolderOrder(this.folders)
        //   .subscribe(response => console.log('from api', response));
      } else {
        $log.log("No update needed");
      }
    });
  }

  /**
   * reorders folders based on drag
   * and drop user interaction
   */
  reorderFolders() {
    if (this.userRole === "superAdmin") {
      this.folders.map(folder => {
        folder.order = this.folders.indexOf(folder) + 1;
      });

      this.FolderService.updateFolderOrder(this.folders).subscribe(response =>
        console.log("from api", response)
      );
    }
  }

  checkForSubFolders() {

    for (let i = this.folders.length - 1; i >= 0 ; i--) {
      let current = this.folders[i];

      //check all folders and see if a parent folder exists
      if (current.parentFolder) {
        let parent = this.folders.find(f => f.id === current.parentFolder);

        if (parent) {

          if (!parent.children) {
            parent.children = [];
          }
          
          parent.children.push(current);
          this.folders.splice(i, 1);
        }
      }
    }
  }
}
