/**
 * Script Service
 *
 * @description :: Provides method for injecting local/external scripts into the HEAD of the page
 */

import { Injectable } from '@angular/core';
import { ScriptStore } from '../stores/script.store';

declare let document: any;

@Injectable()
export class ScriptService {

  private scripts: any = {};

  constructor() {
    ScriptStore.forEach((script: any) => {
      // Add load state and src location property to each defined script to inject
      this.scripts[script.name] = {
        loaded: false,
        src: script.src
      };
    });
  }

  /**
   * Iterate over any number of given Object<Script> parameters and attempt to inject them in the HEAD
   *
   * @param {...Object} scripts - user email
   *
   * @returns {Object<Promise>} returns promise
   */
  load(...scripts: string[]) {
    let promises: any[] = [];
    scripts.forEach((script) => promises.push(this.loadScript(script)));
    return Promise.all(promises);
  }

  /**
   * Given a name, attempt to load the defined script from the Script Store
   *
   * @param {string} name - Name of script to load
   *
   * @returns {Object<Promise>} returns promise
   */
  loadScript(name: string) {
    return new Promise((resolve, reject) => {
      // Resolve if the script has already loaded
      let scriptDef = this.scripts[name];

      if (scriptDef.loaded) {
        return resolve({script: name, loaded: true, status: 'Already Loaded'});
      }

      // Create script DOM tag
      let scriptEl = document.createElement('script');
      scriptEl.type = 'text/javascript';
      scriptEl.src = scriptDef.src;

      // On Internet Explorer, leverage the `onreadystatechange` event
      if (scriptEl.readyState) {  // IE
        scriptEl.onreadystatechange = () => {
          if (scriptEl.readyState === 'loaded' || scriptEl.readyState === 'complete') {
            scriptEl.onreadystatechange = null;
            scriptDef.loaded = true;
            resolve({script: name, loaded: true, status: 'Loaded'});
          }
        };
      } else {
        scriptEl.onload = () => {
          scriptDef.loaded = true;
          resolve({script: name, loaded: true, status: 'Loaded'});
        };
      }
      scriptEl.onerror = (error: any) => reject({script: name, loaded: false, status: 'Error'});

      // Append the new script element to the head
      document.getElementsByTagName('head')[0].appendChild(scriptEl);
    });
  }

}
