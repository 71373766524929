<insighter-client-dropdown
  [hidden]="!clients[0]?.id"
  [clients]="clients"
  (client)="buildNavigation($event)"
  [shouldShowWithOnlyOne]="true"
></insighter-client-dropdown>

<nav class="admin-nav" style="margin-top: 0;">
  <ul class="navigation">
    <li class="nav-section">
      <a class="bold outer title">
        <i class="fa fa-tasks fa-lg" aria-hidden="true"></i>
        <span>Folder System</span>
      </a>
      <ul class="navigation sub lt">
        <li>
          <a
            class="inner"
            href="#"
            routerLink="/custom-dash/folder-system"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
          >
            <span>Custom Folders</span>
          </a>
        </li>
      </ul>
    </li>
    <li class="nav-section">
      <a class="bold outer title">
        <i class="fa fa-tasks fa-lg" aria-hidden="true"></i>
        <span>Dashboards</span>
      </a>
      <ul class="navigation sub lt">
        <li>
          <a
            class="inner"
            href="#"
            routerLink="/custom-dash/custom-dash"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
          >
            <span>Custom Dashboards</span>
          </a>
        </li>
      </ul>
    </li>
  </ul>
</nav>
