<header class="main-header" *ngIf="!isEmbedded" data-html2canvas-ignore>
  <div class="container-fluid top-bar" 
    [attr.style]="('background-color:' + (dynamicHeaderColor ? dynamicHeaderColor + ' !important' : 'white'))  | safeStyle"
    [style.border-bottom-color]="navigationBarBg"
  >
    <a href="" class="mobile-sidebar-toggle pull-left hidden-sm hidden-md hidden-lg" (click)="toggleSidebar()">
      <span class="glyphicon glyphicon-menu-hamburger"></span>
    </a>
    <div class="logo">
      <img class="insighter-logo" 
        [src]="dynamicHeaderLeft ? dynamicHeaderLeft: '/assets/img/insighter.png'"
        [style.width]="dynamicHeaderLeftWidth ?  dynamicHeaderLeftWidth: '87px'"
        [style.height]="dynamicHeaderLeftHeight ?  dynamicHeaderLeftHeight: '45px'"
      />
    </div>
    <!-- Tablet Up dropdown menus / show  -->
    <div *ngIf="!isMobile" class="right-side hidden-xs">
      <!-- begin resources menu -->
      <div *ngIf="clientLinks" class="resources-dropdown">
        <div class="dropdown">
          <button type="button" class="btn btn-default btn-small dropdown-toggle"
            [style.background-color]="dynamicHeaderColor ? dynamicHeaderColor: 'white'"
            

            data-toggle="dropdown">
            <span class="glyphicon glyphicon-info-sign user-icon" aria-hidden="true" [style.color]="dynamicHeaderColor === 'black' ? 'white' : null"></span>
            <span class="username" [style.color]="dynamicHeaderColor === 'black' ? 'white' : null">Resources</span>
            <span class="caret" [style.color]="dynamicHeaderColor === 'black' ? 'white' : null"></span>
          </button>

          <ul class="dropdown-menu dropdown-arrow right resources-menu" aria-labelledby="resourcesMenu">
            <li role="separator" class="hidden-sm hidden-md hidden-lg">
              <a>
                <span class="glyphicon glyphicon-info-sign user-icon" aria-hidden="true"></span>Resources
              </a>
            </li>
            <li>
              <div *ngFor="let link of clientLinks; let i = index" class="links-container">
                <li *ngIf="i < 1" role="separator" class="divider hidden-sm hidden-md hidden-lg"></li>
                <li *ngIf="i > 0" role="separator" class="divider link-divider"></li>
                <li>
                  <a href="{{link.url}}" target="_blank">{{link.title}}</a>
                </li>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <!-- end resources menu -->

      <!-- begin user settings menu -->
      <!-- <insighter-user-widget></insighter-user-widget> -->
      <!-- end user settings menu -->
      <div class="insighter-logo-top-right-container"
        [style.display]="dynamicHeaderRight ? 'inline-block': 'none'"
      >
        <img class="insighter-logo-top-right"
          [src]="dynamicHeaderRight ? dynamicHeaderRight: '/assets/img/insighter.png'"
          [style.width]="dynamicHeaderRightWidth ?  dynamicHeaderRightWidth: '87px'"
          [style.height]="dynamicHeaderRightHeight ?  dynamicHeaderRightHeight: '45px'"
        />
      </div>
    </div>

    <!-- Mobile dropdown menus -->
    <div *ngIf="isMobile" class="right-side hidden-sm hidden-md hidden-lg mobile-dropdowns-container">
      <!-- if there are client links -->
      <div *ngIf="clientLinks" class="resources-dropdown">
        <div class="dropdown">
          <button type="button" class="btn btn-default btn-small dropdown-toggle" data-toggle="dropdown">
            <span class="glyphicon glyphicon-option-vertical user-icon options-icon" aria-hidden="true"></span>
          </button>

          <ul class="dropdown-menu dropdown-arrow right resources-menu" aria-labelledby="resourcesMenu">
            <li role="separator" class="hidden-sm hidden-md hidden-lg">
              <a>
                <span class="glyphicon glyphicon-info-sign user-icon" aria-hidden="true"></span>Resources
              </a>
            </li>
            <li>
              <div *ngFor="let link of clientLinks; let i = index" class="links-container">
                <li *ngIf="i < 1" role="separator" class="divider hidden-sm hidden-md hidden-lg"></li>
                <li *ngIf="i > 0" role="separator" class="divider link-divider"></li>
                <li>
                  <a href="{{link.url}}" target="_blank">{{link.title}}</a>
                </li>
              </div>
            </li>
            <insighter-user-widget [hasClientLinks]="true"></insighter-user-widget>
          </ul>
        </div>
      </div>
      <!-- if there are no client links -->
      <insighter-user-widget *ngIf="!clientLinks" [hasClientLinks]="false"></insighter-user-widget>
    </div>
  </div>

  <nav class="navbar navbar-static-top container-fluid"
      [attr.style]="('background-color:' + (navigationBarBg ? navigationBarBg + ' !important' : '#f7f7f7'))  | safeStyle"
      [style.font-family]="navigationBarFont"
      [style.font-size]="navigationBarFontSize"
    >
    <!-- always show dashboard regardless of role -->
    <a [style.color]="navigationBarTextColor" *ngIf="isAdmin || shouldShowMyDownloads()" href="#" routerLink="/dashboards" class="pull-left nav-link" routerLinkActive="active">Dashboards</a>
    <a [style.color]="navigationBarTextColor" *ngIf="shouldShowMyDownloads()" href="#" routerLink="/my-downloads" class="pull-left nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
      My Downloads <span *ngIf="hasReadyForDownload()" class="badge" style="background-color: #28a745;">New</span>
    </a>
    <a [style.color]="navigationBarTextColor" *ngIf="role === 'superAdmin'" href="#" routerLink="/my-downloads/admin" class="pull-left nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
      My Downloads - Admin <span *ngIf="hasReadyForDownload()" class="badge" style="background-color: #28a745;">New</span>
    </a>

    <a [style.color]="navigationBarTextColor" *ngIf="shouldShowClientAdmin()" href="#" [routerLink]="clientAdminLink" class="pull-left nav-link" routerLinkActive="active">{{clientAdminDisplay}}</a>
    <!-- <a *ngIf="role === 'superAdmin'" href="#" routerLink="/automation/logs" class="pull-left nav-link" routerLinkActive="active">Automation Logs</a> -->
    <a [style.color]="navigationBarTextColor" *ngIf="shouldShowMediaAutomation() && !isMobile" href="#" [routerLink]="mediaAutoLink" class="pull-left nav-link" routerLinkActive="active">{{mediaAutoLinkDisplay}}</a>

    <a [style.color]="navigationBarTextColor" *ngIf="shouldShowDataWarehouse()" href="#" [routerLink]="dataWarehouseLink" class="pull-left nav-link" routerLinkActive="active">{{dataWarehouseLabel}}</a>

    <a [style.color]="navigationBarTextColor" *ngIf="shouldShowCustomDashboards()" href="#" routerLink="/custom-dash/folder-system" class="pull-left nav-link" routerLinkActive="active">{{customDashboardsLabel}}</a>

    <a [style.color]="navigationBarTextColor" *ngIf="shouldShowClientAdmin() && isMobile" href="#" routerLink="/settings" class="pull-left nav-link" routerLinkActive="active">Cl Admin</a>

    <div class="pull-right" *ngIf="!isMobile">
      <insighter-user-widget
        [navigationBarBg]="navigationBarBg"
        [navigationBarFont]="navigationBarFont"
        [navigationBarFontSize]="navigationBarFontSize"
        [navigationBarTextColor]="navigationBarTextColor"
      ></insighter-user-widget>
    </div>

    <!-- toggle switch for settings panel or Admin panel -->
    <div class="pull-right" *ngIf="isAdminRoute() && !isMobile">
      <insighter-toggle-switch [label]="clientAdminViewLabel" checkedRoute="/settings" (switchToggle)="toggleAdminView($event)"></insighter-toggle-switch>
    </div>

    <div class="pull-right" *ngIf="isMediaAutomationRoute() && !isMobile">
      <insighter-toggle-switch [label]="clientAdminViewLabel" checkedRoute="/ma/media-client" (switchToggle)="toggleMediaAutomationAdminView($event)"></insighter-toggle-switch>
    </div>

    <div class="pull-right" *ngIf="shouldShowEditDashboardMode() && !isMobile">
      <insighter-toggle-switch label="Edit Mode" checkedRoute="" [checked]="false" (switchToggle)="toggleEditMode($event)"></insighter-toggle-switch>
    </div>
    
  </nav>
  <div id="pdf-only-navbar" class="navbar navbar-static-top container-fluid" *ngIf="navigationBarBg"
    [attr.style]="('background-color:' + (navigationBarBg ? navigationBarBg + ' !important' : '#f7f7f7'))  | safeStyle"
    [style.font-family]="navigationBarFont"
    [style.font-size]="navigationBarFontSize"
  ></div>
</header>
<!--Hidden header to push everything down-->
<div class="main-header-push" *ngIf="!isEmbedded" data-html2canvas-ignore>
    <div class="top-bar">
      <div [style.height]="dynamicHeaderLeftHeight ?  dynamicHeaderLeftHeight: '45px'"></div>    
      <div *ngIf="!isMobile" class="right-side hidden-xs">
          <div
          [style.height]="dynamicHeaderRightHeight ?  dynamicHeaderRightHeight: '45px'"
        ></div>  
      </div>
    </div>
    
    <div class="extra-height-push"></div>
</div>
<!-- begin user profile component -->
<insighter-user-profile #userProfile></insighter-user-profile>
<!-- end user profile component -->
