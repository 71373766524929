/**
 * Metadata Service
 *
 * @description :: used to fetch and manage metadata from the api
 */


import {map} from 'rxjs/operators';
import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import * as moment from "moment-mini";

@Injectable()
export class PermissionLogService {
  // api folders endpoint
  private URL = "/data-warehouse/permission-log";

  constructor(private ApiService: ApiService) {}

  logPermissionChange(user, type, changeName, value) {
    let now = moment().utcOffset("-06:00");
    const currentUser = JSON.parse(localStorage.getItem("currentUser"));

    const permissionLog = {
      username_acting: currentUser.user,
      username_affected: user.email,
      change_type: type,
      change_name: changeName,
      change_value: value,
      date: now.format("MM/DD/YYYY"),
      timestamp: now.format("HH:mm:ss")
    };

    return this.ApiService.post(this.URL, permissionLog).pipe(map(res => {
      return res.body;
    }));
  }
}
