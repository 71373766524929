<nav class="admin-nav">
  <ul class="navigation">
    <li class="nav-section" *ngIf="hasAllPrivileges">
      <a class="bold outer title">
        <i class="fa fa-tasks fa-lg" aria-hidden="true"></i>
        <span>Client Accounts</span>
      </a>
      <ul class="navigation sub lt">
        <li>
          <a class="inner" routerLink="/data-warehouse/client-status" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
            <span>Client Status</span>
          </a>
        </li>
        <li>
          <a class="inner" href="#" routerLink="/data-warehouse/accounts-mapping" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
            <span>Accounts Mapping</span>
          </a>
        </li>
      </ul>
    </li>
    <li class="nav-section" *ngIf="hasAllPrivileges">
      <a class="bold outer title">
        <i class="fa fa-tasks fa-lg" aria-hidden="true"></i>
        <span>File Loading</span>
      </a>
      <ul class="navigation sub lt">
        <li>
          <a class="inner" href="#" routerLink="/data-warehouse/access-staging-tables" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
            <span>Access Staging Tables</span>
          </a>
        </li>
        <li>
          <a class="inner" href="#" routerLink="/data-warehouse/sftp" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
            <span>Sftp Management</span>
          </a>
        </li>
        <li>
          <a class="inner" href="#" routerLink="/data-warehouse/file-trigger-list" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
            <span>File Trigger List</span>
          </a>
        </li>
      </ul>
    </li>
    <li class="nav-section" *ngIf="hasAllPrivileges">
      <a class="bold outer title">
        <i class="fa fa-tasks fa-lg" aria-hidden="true"></i>
        <span>User Managment</span>
      </a>
      <ul class="navigation sub lt">
        <li>
          <a class="inner" routerLink="/data-warehouse/user-list" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
            <span>User List</span>
          </a>
        </li>
      </ul>
      <ul class="navigation sub lt">
        <li>
          <a class="inner" routerLink="/data-warehouse/user-schema-mapping" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
            <span>Schema Permissions</span>
          </a>
        </li>
      </ul>
    </li>
    <li class="nav-section" *ngIf="hasAllPrivileges">
      <a class="bold outer title">
        <i class="fa fa-tasks fa-lg" aria-hidden="true"></i>
        <span>Facebook</span>
      </a>
      <ul class="navigation sub lt">
        <li>
          <a class="inner" routerLink="/data-warehouse/facebook-source-list" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
            <span>Accounts</span>
          </a>
        </li>
        <li>
          <a class="inner" routerLink="/data-warehouse/facebook-report-definition" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
            <span>Report Definitions</span>
          </a>
        </li>
        <li>
          <a class="inner" routerLink="/data-warehouse/facebook-organic-pages" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
            <span>Organic Pages</span>
          </a>
        </li>
      </ul>
    </li>
    <li class="nav-section" *ngIf="hasAllPrivileges">
      <a class="bold outer title">
        <i class="fa fa-tasks fa-lg" aria-hidden="true"></i>
        <span>Google</span>
      </a>
      <ul class="navigation sub lt">
        <li>
          <a class="inner" routerLink="/data-warehouse/google-source-list" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
            <span>Accounts</span>
          </a>
        </li>
        <li>
          <a class="inner" routerLink="/data-warehouse/google-report-definition" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
            <span>Report Definitions</span>
          </a>
        </li>
      </ul>
    </li>
    <li class="nav-section" *ngIf="hasAllPrivileges || hasCrudPrivileges">
      <a class="bold outer title">
        <i class="fa fa-tasks fa-lg" aria-hidden="true"></i>
        <span>Tasks</span>
      </a>
      <ul class="navigation sub lt">
        <li *ngIf="hasAllPrivileges">
          <a class="inner" routerLink="/data-warehouse/tasks/single-load" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
            <span>Single Load Account</span>
          </a>
        </li>
        <li *ngIf="hasAllPrivileges">
          <a class="inner" routerLink="/data-warehouse/tasks/build-client-schema" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
            <span>Build Client Schema</span>
          </a>
        </li>
        <li *ngIf="hasAllPrivileges || hasCrudPrivileges">
          <a class="inner" routerLink="/data-warehouse/tasks/crud" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
            <span>CRUD</span>
          </a>
        </li>
        <li *ngIf="hasAllPrivileges">
          <a class="inner" routerLink="/data-warehouse/tasks/facebook-jobs" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
            <span>Facebook Jobs</span>
          </a>
        </li>
      </ul>
    </li>
  </ul>
</nav>
