/**
 * DatePickerComponent
 *
 * @description :: component to render date picker on dashboard routes
 */
import { Component, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { PickerOptions } from '../../../models/picker-options';
import { environment } from '../../../../environments/environment';
import { $log } from '../../../services/logger.service';


/**
 * set $ as variable for jQuery, making it available in this component
 */
declare let $: any;

@Component({
  selector: 'insighter-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss']
})
export class DatePickerComponent implements OnChanges {
  // Used to track whether jQuery conflicts have been resolved
  static jqueryConflictResolved = false;

  // date picker dropdown state
  private initialized: boolean = false;

  @Input() font?: string;
  @Input() fontSize?: string;

  @Input() options: PickerOptions;
  @Output() change: EventEmitter<Object> = new EventEmitter();

  constructor() {
    // If jQuery conflicts have not been accounted for yet
    if (!DatePickerComponent.jqueryConflictResolved) {
      // Prevent conflicts from Sisense jQuery library
      $.noConflict();
      DatePickerComponent.jqueryConflictResolved = true;
    }

    this.options = new PickerOptions();
  }

  ngOnChanges() {
    // If the options or selected properties update, reinitialize the date picker
    this.applyOptions();
  }

  /**
   * This is required to show the selected date range in the
   * component's DOM element now that we're no longer using
   * an input element. This is based off the actual date picker
   * docs for predefined-ranges in:
   * http://www.daterangepicker.com/#examples
   *
   * @param   {String} start - String representing selected start date of the date picker
   * @param   {String} end   - String representing selected end date of the date picker
   *
   * @returns {void}
   */
  public setDisplayDates(start, end): void {
    let jquery = $ || window['jQuery'];

    if (start && end) {
      jquery('#date-text').html(`${start} - ${end}`);
    }
  }

  /**
   * Initialize the date range picker with the options
   * and update date selection callback; Called during
   * the change lifecycle hook.
   *
   * @returns {void}
   */
  private applyOptions(): void {
    this.initialized = true;
    let jquery = $ || window['jQuery'];

    try {
      jquery('#daterange').daterangepicker(this.options, (start, end) => {
        // Define string format of the date for use within the template and update the
        // currently displayed values.  NOTE: This callback supplies Moment date objects
        let formattedStart = start.format(environment.dateFilter.dateFormat);
        let formattedEnd = end.format(environment.dateFilter.dateFormat);

        this.setDisplayDates(formattedStart, formattedEnd);

        // Update the date picker options with the newly selected dates
        this.options.setStartDate(start);
        this.options.setEndDate(end);

        // Emit native Date objects for the selected dates
        this.change.emit({ start: start._d, end: end._d });
      });
    } catch (err) {
      $log.error(err);
    }
  }
}
